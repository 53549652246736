import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { VscThreeBars } from "react-icons/vsc";
import {
  ismobile,
  isSmallMobile,
  istab,
  isDesktopOrLaptop,
} from "../utility/const";
import {
  AppBar,
  Toolbar,
  Avatar,
  Box,
  IconButton,
  Typography,
  Stack,
  Tooltip,
} from "@mui/material";
import { CiBellOn } from "react-icons/ci";
import { URL } from "../utility/urls";
import { CUSTOMCOLORS } from "../styles";
import { NotifyModal } from "./NotifyModal";
import { useNavigate } from "react-router-dom";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import FollowupModal from "./followupModal";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import ExportDataModal from "./exportData";

export const TopNav = React.memo(({ onClick, path }) => {
  const nav = useNavigate();
  const { user_data } = useSelector((state) => state?.user);

  const { refreshVar } = useSelector((state) => state?.appointment);
  const { profile_id } = useSelector((state) => state.login);

  const [notifyData, setNotifyData] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);
  const getNoityData = async () => {
    const data = await fetch(URL.getNotifyData(profile_id), {
      method: "GET",
    });
    const jsonData = await data.json();
    const docs_data = jsonData?.data?.filter(
      (item) => item?.sending_to === "user"
    );
    setNotifyData(docs_data);
  };
  useEffect(() => {
    getNoityData();
  }, [refreshVar, profile_id]);
  const filter_seen = notifyData?.filter((item) => item?.seen === false).length;

  const handleOnClose = () => {
    setOpen(false);
  };
  console.log(user_data[0]?.profile_pic);

  return (
    <AppBar
      position="fixed"
      sx={{
        width:
          istab || ismobile || isSmallMobile ? "100%" : `calc(100% - ${14}vw)`,
        height: istab ? "8vh" : "10vh",
        background: CUSTOMCOLORS.fadeblue,
        justifyContent: "center",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: CUSTOMCOLORS.fadeblue,
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: isDesktopOrLaptop || istab ? 2 : 0,
          }}
        >
          {(istab || ismobile || isSmallMobile) && (
            <IconButton onClick={onClick} edge="start" size="large">
              <VscThreeBars color={CUSTOMCOLORS.black} />
            </IconButton>
          )}
          <Stack
            alignItems={isDesktopOrLaptop || istab ? "center" : ""}
            gap={isDesktopOrLaptop || istab ? 2 : 0}
            display={"flex"}
            flexDirection={isDesktopOrLaptop || istab ? "row" : "column"}
          >
            <Typography
              variant={isDesktopOrLaptop || istab ? "subtitle1" : "subtitle2"}
              lineHeight={1}
            >
              Welcome,
            </Typography>
            <Typography
              variant={isDesktopOrLaptop || istab ? "h6" : "subtitle1"}
            >
              {user_data[0]?.salutation} {user_data[0]?.name}
            </Typography>
          </Stack>
        </Stack>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: 1.5,
          }}
        >
          {path && (
            <Tooltip title="Export Data" placement="top">
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  setExportOpen(true);
                }}
                sx={{ color: "common.black", padding: 0 }}
              >
                <ContentPasteGoIcon fontSize={"medium"} />
              </IconButton>
            </Tooltip>
          )}
          {exportOpen && (
            <ExportDataModal
              open={exportOpen}
              onClose={() => setExportOpen(false)}
            />
          )}
          <Tooltip title="Follow up">
            <IconButton
              sx={{ color: "common.black", padding: 0 }}
              onClick={(e) => {
                e.preventDefault();
                setModalOpen(true);
              }}
            >
              <DateRangeOutlinedIcon size={32} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Notifications">
            <IconButton
              sx={{ position: "relative", padding: 0 }}
              onClick={() => setOpen(true)}
            >
              <CiBellOn color={CUSTOMCOLORS.black} size={32} />
              {filter_seen > 0 && (
                <Box
                  sx={{
                    width: "1.2rem",
                    height: "1.2rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bgcolor: "error.main",
                    borderRadius: "50%",
                  }}
                >
                  <Typography variant="caption" color="white">
                    {filter_seen > 9 ? "10+" : filter_seen}
                  </Typography>
                </Box>
              )}
            </IconButton>
          </Tooltip>
          <NotifyModal open={open} onClose={handleOnClose} data={notifyData} />
          <Avatar
            src={
              user_data[0]?.profile_pic
            }
            alt=""
            sx={{
              width: isDesktopOrLaptop || istab ? 48 : 42,
              height: isDesktopOrLaptop || istab ? 48 : 42,
              borderRadius: "50%",
              objectFit: "cover",
              border: 1,
              // borderColor: CUSTOMCOLORS.border,
            }}
            onClick={() => nav("/account")}
          />
        </Box>
      </Toolbar>
      {modalOpen && (
        <FollowupModal open={modalOpen} onClose={() => setModalOpen(false)} />
      )}
    </AppBar>
  );
});
