import React, { useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { isDesktopOrLaptop, istab } from "../../utility/const";

export const TextFieldWithAdornment = ({
  label,
  adorntext,
  value,
  disable,
  onChange,
  type,
}) => {
  return (
    <TextField
      size="medium"
      label={label}
      sx={{ width: isDesktopOrLaptop || istab ? "20ch" : "12ch" }}
      type={type}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{adorntext}</InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: true,
        style: {
          fontFamily: "Open sans",
        },
      }}
      value={value}
      onChange={onChange}
      disabled={disable}
    />
  );
};
