export const CUSTOMCOLORS = {
  background: "#EDF6FF",
  white: "#FFf",
  DeIcon: "#FF6767",
  primary: "#4ba5fa",
  pending: "#FFF3DB",
  orange: "#FFA800",
  green: "#DEFDD9",
  success: "#2CBB15",
  fadeblue: "#EAF3FC",
  black: "#000",
  selection: "#3683CC",
  error: "#FF6767",
  chip: "#ECFFE9",
  disableSlot: "#f1f1f1",
  border: "#A4D2FF",
};

export const ModalStyle = {};
