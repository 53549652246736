import React, { useEffect, useState } from "react";
import { ConsultationCard } from "../components/consultationCard";
import { ChiefComplaint } from "../components/ChiefComplaint";
import { ReportFindings } from "../components/ReportFindings";
import { Fees } from "../components/Fees";
import { useSelector, useDispatch } from "react-redux";
import { HButton } from "../components/hbutton";
import { URL } from "../utility/urls";
import { useNavigate } from "react-router-dom";
import { Patient_ConsultationCard } from "../components/Patient_consultationcard";
import { CONSTANTS, isDesktopOrLaptop, istab } from "../utility/const";
import { updataeComplaints_Vitals } from "../redux/features/dataUpdates";
import { Vitals } from "../components/Vitals";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { Text } from "../components/Text";
import { MdEdit } from "react-icons/md";
import { CUSTOMCOLORS } from "../styles";
import dayjs from "dayjs";
import TextFieldWithEdit from "../components/base/EditField";
import { VitalValuSets, vitalSchema } from "../utility/valueSets/vitals";
import { showToast } from "../utility/toast";
import { NewConsent } from "../components/Modals/Consents";

export const Consultation = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { patient_data } = useSelector((state) => state?.patient);
  const { appointment_data } = useSelector((state) => state?.appointment);
  const { token } = useSelector((state) => state.login);
  let { complaint_update, vitals_update } = useSelector(
    (state) => state.updates
  );

  const dr_ref = appointment_data?.appointment?.participant[0]?.actor;
  const pt_ref = appointment_data?.appointment?.participant[1]?.actor;
  const app_id = appointment_data?._id;
  const org_ref = appointment_data?.appointment?.supportingInformation[0];
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState("");
  const [loading, setLoading] = useState(false);
  const [complaints, setComplaints] = useState([]);
  const [vitals, setVitals] = useState([]);
  const HandleOpenModal = (e) => {
    setOpen(true);
  };

  const HandleCloseModal = (e) => {
    setOpen(false);
  };
  const reasons = appointment_data?.appointment?.reasonCode?.map(
    (item) => item?.coding
  );
  useEffect(() => {
    const PostComplaints = async (val) => {
      const response = await fetch(URL.createComplaints, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chiefComplaint: {
            resourceType: "Conditon",
            code: {
              coding: val,
              text: val[0]?.display,
            },
            subject: pt_ref,
            encounter: {
              appointment: {
                reference: app_id,
              },
            },
            recorder: dr_ref,
          },
          type: "ChiefComplaint",
          organization: org_ref,
        }),
      });
      const jsondata = await response.json();
      if (jsondata.status === "success") {
        dispatch(
          updataeComplaints_Vitals({
            vitals_update: "",
            complaint_update: (complaint_update += "s"),
            reports_update: "",
          })
        );
      }
    };
    for (let i = 0; i < reasons?.length; i++) {
      PostComplaints(reasons[i]);
    }
  }, []);
  const fetchCompalint = async () => {
    const response = await fetch(URL.getcomplaints(appointment_data?._id), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    jsonData?.data?.length > 0
      ? setComplaints(jsonData?.data)
      : setComplaints([]);
  };
  useEffect(() => {
    fetchCompalint();
  }, [complaint_update]);
  const complaint_reasons = complaints?.map(
    (item) => item?.chiefComplaint?.code?.text
  );
  const fetchVitals = async () => {
    const response = await fetch(URL.getVitalsByAppId(app_id), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    jsonData?.data ? setVitals(jsonData?.data) : setVitals([]);
  };
  useEffect(() => {
    fetchVitals();
  }, [vitals_update]);
  console.log(patient_data);
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <Stack spacing={1}>
      <Patient_ConsultationCard
        data={patient_data}
        app_data={appointment_data}
      />
      <Stack>
        <Typography variant="h6">Consultation</Typography>
        {/* <Button variant="outlined" onClick={() => setModalOpen(true)}>
          Raise Consent
        </Button> */}
      </Stack>
      {CONSTANTS?.consultation?.map((item) => (
        <ConsultationCard
          key={item}
          label={item.label}
          onClick={() => {
            HandleOpenModal();
            setLabel(item?.label);
          }}
          onEditClick={() => {
            setOpen(false);
          }}
          details={
            item?.label === "Chief complaint" ? (
              complaint_reasons?.join(", ")
            ) : item?.label === "Vitals" ? (
              <>
                {vitals?.length > 0 && (
                  <VitalsComponent
                    incoming_data={vitals}
                    app_data={{ dr_ref, org_ref, pt_ref, app_id }}
                  />
                )}
              </>
            ) : (
              ""
            )
          }
        />
      ))}
      {open && label === "Chief complaint" && (
        <ChiefComplaint
          open={open}
          close={HandleCloseModal}
          app_data={{ dr_ref, org_ref, pt_ref, app_id }}
          consultation={complaints}
        />
      )}
      {open && label === "Vitals" && (
        <Vitals
          open={open}
          onClose={HandleCloseModal}
          app_data={{ dr_ref, org_ref, pt_ref, app_id }}
        />
      )}
      {open && label === "Upload Reports" && (
        <ReportFindings
          open={open}
          close={HandleCloseModal}
          app_data={{ dr_ref, org_ref, pt_ref, app_id }}
        />
      )}
      {open && label === "Fees" && (
        <Fees
          fees={appointment_data?.consultation_fees}
          open={open}
          close={HandleCloseModal}
          app_data={{ dr_ref, org_ref, pt_ref, app_id }}
        />
      )}
      <div className="flex justify-end py-4">
        <HButton
          label={"Save"}
          loading={loading}
          onClick={() => nav("/dashboard")}
        />
      </div>
      {modalOpen && (
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Box sx={modalStyle}>
            <NewConsent
              handleFunction={() => setModalOpen(false)}
              doc={dr_ref}
            />
          </Box>
        </Modal>
      )}
    </Stack>
  );
};

const VitalsComponent = ({ incoming_data, app_data }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.login);
  let { complaint_update } = useSelector((state) => state.updates);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isDesktopOrLaptop || istab ? "auto" : "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    height: "100%",
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    gap: 1,
    overflowY: "auto",
  };
  const EditModal = ({ open, onClose, data, option, index }) => {
    const [pulse, setPulse] = useState({ id: "", value: "", time: "" });
    const [rrate, setRrate] = useState({ id: "", value: "", time: "" });
    const [spo2, setSpo2] = useState({ id: "", value: "", time: "" });
    const [temp, setTemp] = useState({ id: "", value: "", time: "" });
    const [diastolic, setDiastolic] = useState({
      id: "",
      value: "",
      time: "",
    });
    const [systolic, setsystolic] = useState({ id: "", value: "", time: "" });
    const [height, setHeight] = useState({ id: "", value: "", time: "" });
    const [weight, setWeight] = useState({ id: "", value: "", time: "" });
    const [bmi, setBmi] = useState({ id: "", value: 0, time: "" });
    const [lmp, setLmp] = useState({ id: "", value: "", time: "" });
    const [edd, setEdd] = useState({ id: "", value: "", time: "" });
    const [others, setOthers] = useState({ id: "", value: "", time: "" });

    const updateData = async (body, id) => {
      const response = await fetch(URL.updateVitals(id), {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const jsonData = await response.json();
      if (jsonData?.status === "success") {
        dispatch(
          updataeComplaints_Vitals({
            vitals_update: (complaint_update += "s"),
            complaint_update: "",
            reports_update: "",
          })
        );
        setBasicOpen(false);
        setBpOpen(false);
        setBodyOpen(false);
      } else {
        showToast("error", "please try again");
      }
    };
    useEffect(() => {
      if (option === "basic") {
        const filterrd_data = data?.filter((item, ind) => ind === index);
        filterrd_data?.map((item, ind) => {
          const handleBasicData = (code = "") => {
            const data = item?.observations?.map((ele) => ele);
            const filter_data = data?.filter(
              (val) => val?.vitalobservation?.code?.text === code
            );
            return filter_data[0];
          };
          handleBasicData("Measurement of oxygen saturation at periphery");
          setPulse({
            time: item?._id,
            value:
              handleBasicData("Pulse rate")?.vitalobservation?.valueQuantity
                ?.value,
            id: handleBasicData("Pulse rate")?._id,
          });
          setSpo2({
            time: item?._id,
            value: handleBasicData(
              "Measurement of oxygen saturation at periphery"
            )?.vitalobservation?.valueQuantity?.value,
            id: handleBasicData("Measurement of oxygen saturation at periphery")
              ?._id,
          });
          setTemp({
            time: item?._id,
            value:
              handleBasicData("Body temperature")?.vitalobservation
                ?.valueQuantity?.value,
            id: handleBasicData("Body temperature")?._id,
          });
          setRrate({
            time: item?._id,
            value:
              handleBasicData("Respiratory rate")?.vitalobservation
                ?.valueQuantity?.value,
            id: handleBasicData("Respiratory rate")?._id,
          });
        });
      }
      if (option === "bp") {
        const filterrd_data = data?.filter((item, ind) => ind === index);
        filterrd_data?.map((item, ind) => {
          const handleBpData = (code = "") => {
            const data = item?.observations?.map((ele) => ele);
            const filter_data = data?.filter(
              (val) => val?.vitalobservation?.code?.text === code
            );
            return filter_data[0];
          };

          setDiastolic({
            time: item?._id,
            value: handleBpData("Diastolic blood pressure")?.vitalobservation
              ?.valueQuantity?.value,
            id: handleBpData("Diastolic blood pressure")?._id,
          });
          setsystolic({
            time: item?._id,
            value: handleBpData("Systolic blood pressure")?.vitalobservation
              ?.valueQuantity?.value,
            id: handleBpData("Systolic blood pressure")?._id,
          });
        });
      }
      if (option === "body") {
        const handleBodyData = (code = "") => {
          const hei = data?.map((item) => item?.observations);
          const body_data = hei[0]?.filter(
            (item) => item?.vitalobservation?.code?.text === code
          );
          return body_data[0];
        };

        const others_body_data = data
          ?.map((item) => item?.observations)[0]
          ?.filter((item) => {
            const check = item?.vitalobservation?.code?.text;
            return (
              check !== "Body height" &&
              check !== "Body weight" &&
              check !== "BMI" &&
              check !== "Date of last menstrual period" &&
              check !== "Estimated date of delivery"
            );
          });

        setHeight({
          value:
            handleBodyData("Body height")?.vitalobservation?.valueQuantity
              ?.value,
          id: handleBodyData("Body height")?._id,
          time: "",
        });
        setWeight({
          value:
            handleBodyData("Body weight")?.vitalobservation?.valueQuantity
              ?.value,
          id: handleBodyData("Body weight")?._id,
          time: "",
        });
        setBmi((data) => ({
          ...data,
          value: handleBodyData("BMI")?.vitalobservation?.valueQuantity?.value,
          id: handleBodyData("BMI")?._id,
          time: "",
        }));

        setLmp({
          value: dayjs(
            handleBodyData(
              "Date of last menstrual period"
            )?.vitalobservation?.valueQuantity?.value?.toString()
          ),
          id: handleBodyData("Date of last menstrual period")?._id,
          time: "",
        });
        setEdd({
          value: dayjs(
            handleBodyData(
              "Estimated date of delivery"
            )?.vitalobservation?.valueQuantity?.value?.toString()
          ),
          id: handleBodyData("Estimated date of delivery")?._id,
          time: "",
        });
        setOthers({
          value: others_body_data[0]?.vitalobservation?.valueQuantity?.value,
          id: others_body_data[0]?._id,
          time: "",
        });
      }
    }, [option]);
    useEffect(() => {
      const handleBMI = () => {
        const heigh = (height?.value / 100) ** 2;
        const weigh = parseInt(weight?.value);
        const BMI = (weigh / heigh).toString().slice(0, 5);
        if (isNaN(parseInt(BMI))) {
          setBmi((data) => ({ ...data, value: parseFloat(BMI) }));
        } else {
          setBmi((data) => ({ ...data, value: parseFloat(BMI) }));
        }
      };
      handleBMI();
    }, [weight, height]);

    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {option === "basic" && (
            <Stack display={"flex"} direction={"row"} gap={2} flexWrap={"wrap"}>
              {pulse?.value && (
                <TextFieldWithEdit
                  onClick={() => {
                    updateData(
                      vitalSchema(
                        {},
                        VitalValuSets.pulse,
                        pulse?.value,
                        "beats/min",
                        "/min",
                        "ObservationVitalSigns",
                        pulse?.time
                      ),
                      pulse?.id
                    );
                  }}
                  label="Pulse"
                  unit={"bpm"}
                  value={pulse?.value}
                  onChange={(e) => {
                    setPulse({ ...pulse, value: e.target.value });
                  }}
                  children={<MdEdit />}
                />
              )}
              {spo2?.value && (
                <TextFieldWithEdit
                  onClick={() =>
                    updateData(
                      vitalSchema(
                        app_data,
                        VitalValuSets.spo2,
                        spo2?.value,
                        "%",
                        "%",
                        "ObservationVitalSigns",
                        spo2?.time
                      ),
                      spo2?.id
                    )
                  }
                  label="SPO2"
                  unit={"%"}
                  value={spo2?.value}
                  onChange={(e) => {
                    setSpo2({ ...spo2, value: e.target.value });
                  }}
                  children={<MdEdit />}
                />
              )}
              {temp?.value && (
                <TextFieldWithEdit
                  onClick={() => {
                    updateData(
                      vitalSchema(
                        app_data,
                        VitalValuSets.temp,
                        temp?.value,
                        "degF",
                        "[degF]",
                        "ObservationVitalSigns",
                        temp?.time
                      ),
                      temp?.id
                    );
                  }}
                  label="Temp"
                  unit={"°F"}
                  value={temp?.value}
                  onChange={(e) => {
                    setTemp({ ...temp, value: e.target.value });
                  }}
                  children={<MdEdit />}
                />
              )}
              {rrate?.value && (
                <TextFieldWithEdit
                  onClick={() => {
                    updateData(
                      vitalSchema(
                        app_data,
                        VitalValuSets?.resp,
                        rrate?.value,
                        "beats/min",
                        "/min",
                        "ObservationVitalSigns",
                        rrate?.time
                      ),
                      rrate?.id
                    );
                  }}
                  label="Resp"
                  unit={"brpm"}
                  value={rrate?.value}
                  onChange={(e) => {
                    setRrate({ ...rrate, value: e.target.value });
                  }}
                  children={<MdEdit />}
                />
              )}
            </Stack>
          )}
          {option === "bp" && (
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <TextFieldWithEdit
                onClick={() => {
                  updateData(
                    vitalSchema(
                      app_data,
                      VitalValuSets?.systolic,
                      systolic?.value,
                      "mmhg",
                      "mm[Hg]",
                      "ObservationVitalSigns",
                      systolic?.time
                    ),
                    systolic?.id
                  );
                }}
                label="Systolic"
                unit={"mmhg"}
                value={systolic?.value}
                onChange={(e) => {
                  setsystolic({ ...systolic, value: e.target.value });
                }}
                children={<MdEdit />}
              />
              <TextFieldWithEdit
                onClick={() => {
                  updateData(
                    vitalSchema(
                      app_data,
                      VitalValuSets?.diastolic,
                      diastolic?.value,
                      "mmhg",
                      "mm[Hg]",
                      "ObservationVitalSigns",
                      diastolic?.time
                    ),
                    diastolic?.id
                  );
                }}
                label="Diastolic"
                unit={"mmhg"}
                value={diastolic?.value}
                onChange={(e) => {
                  setDiastolic({ ...diastolic, value: e.target.value });
                }}
                children={<MdEdit />}
              />
            </Stack>
          )}
          {option === "body" && (
            <Stack spacing={2}>
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <TextFieldWithEdit
                  onClick={() => {
                    updateData(
                      vitalSchema(
                        app_data,
                        VitalValuSets?.height,
                        height?.value,
                        "cm",
                        "[us_cm]",
                        "ObservationBodyMeasurement",
                        height?.time
                      ),
                      height?.id
                    );
                    updateData(
                      vitalSchema(
                        app_data,
                        VitalValuSets.Bmi,
                        bmi?.value,
                        "kg/m2",
                        "kg/m2",
                        "ObservationBodyMeasurement",
                        ""
                      ),
                      bmi?.id
                    );
                  }}
                  label="Height"
                  unit={"cm"}
                  value={height?.value}
                  onChange={(e) => {
                    setHeight({ ...height, value: e.target.value });
                  }}
                  children={<MdEdit />}
                />
                <TextFieldWithEdit
                  onClick={() => {
                    updateData(
                      vitalSchema(
                        app_data,
                        VitalValuSets?.weight,
                        weight?.value,
                        "kg",
                        "[us_kg]",
                        "ObservationBodyMeasurement",
                        weight?.time
                      ),
                      weight?.id
                    );
                    updateData(
                      vitalSchema(
                        app_data,
                        VitalValuSets.Bmi,
                        bmi?.value,
                        "kg/m2",
                        "kg/m2",
                        "ObservationBodyMeasurement",
                        ""
                      ),
                      bmi?.id
                    );
                  }}
                  label="Weight"
                  unit={"kg"}
                  value={weight?.value}
                  onChange={(e) => {
                    setWeight({ ...weight, value: e.target.value });
                  }}
                  children={<MdEdit />}
                />
                <Typography>BMI: {bmi?.value}</Typography>
              </Stack>
              <TextFieldWithEdit
                label={others_body_data[0]?.vitalobservation?.code?.text}
                value={others?.value}
                onChange={(e) => {
                  setOthers({ ...others, value: e.target.value });
                }}
                children={<MdEdit />}
                onClick={() => {
                  const code = {
                    code: others_body_data[0]?.vitalobservation?.code,
                  };
                  updateData(
                    vitalSchema(
                      app_data,
                      code,
                      others?.value,
                      "",
                      "",
                      "ObservationVitalSigns",
                      ""
                    ),
                    others?.id
                  );
                }}
              />
            </Stack>
          )}
        </Box>
      </Modal>
    );
  };
  const bp_basic_data = incoming_data?.filter((item) => item?._id !== "");
  const vitals_data = incoming_data?.filter((item) => item?._id === "");
  const handleData = (data = [], condition = "") => {
    const grouped_data = data
      ?.flatMap(
        (item) =>
          item?.observations?.filter((val) =>
            condition === "true"
              ? val.vitalobservation?.code?.text?.includes("blood pressure")
              : !val.vitalobservation?.code?.text?.includes("blood pressure")
          ) || []
      )
      ?.reduce((acc, curr) => {
        const effectiveDateTime = curr.vitalobservation.effectiveDateTime;
        if (!acc[effectiveDateTime]) {
          acc[effectiveDateTime] = {
            _id: effectiveDateTime,
            observations: [],
          };
        }
        acc[effectiveDateTime].observations.push(curr);
        return acc;
      }, {});
    return Object.values(grouped_data);
  };

  const bps_data = handleData(bp_basic_data, "true");
  const basic_data = handleData(bp_basic_data, "false");
  const normal_data = handleData(vitals_data, "");
  const [basicOpen, setBasicOpen] = useState(false);
  const [bpOpen, setBpOpen] = useState(false);
  const [bodyOpen, setBodyOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const handleBodyData = (code = "") => {
    const hei = normal_data?.map((item) => item?.observations);
    const body_data = hei[0]?.filter(
      (item) => item?.vitalobservation?.code?.text === code
    );
    return body_data[0];
  };
  const others_body_data = normal_data
    ?.map((item) => item?.observations)[0]
    ?.filter((item) => {
      const check = item?.vitalobservation?.code?.text;
      return (
        check !== "Body height" &&
        check !== "Body weight" &&
        check !== "BMI" &&
        check !== "Date of last menstrual period" &&
        check !== "Estimated date of delivery"
      );
    });

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      flex={1}
      width={"100%"}
      gap={1}
    >
      {normal_data?.length > 0 && (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            flexDirection={"row"}
            gap={0.5}
          >
            <Typography variant="caption">Height: </Typography>
            <Typography variant="body2" fontWeight={600}>
              {
                handleBodyData("Body height")?.vitalobservation?.valueQuantity
                  ?.value
              }
              cm
            </Typography>
            <Typography variant="caption"> Weight: </Typography>
            <Typography variant="body2" fontWeight={600}>
              {
                handleBodyData("Body weight")?.vitalobservation?.valueQuantity
                  ?.value
              }
              kg
            </Typography>
            <Typography variant="caption">BMI: </Typography>
            <Typography variant="body2" fontWeight={600}>
              {handleBodyData("BMI")?.vitalobservation?.valueQuantity?.value}{" "}
            </Typography>
          </Box>
          <Text>
            <MdEdit
              onClick={(e) => {
                e.preventDefault();
                setBasicOpen(false);
                setBpOpen(false);
                setBodyOpen(true);
              }}
              color={CUSTOMCOLORS.success}
              style={{
                cursor: "pointer",
              }}
            />
          </Text>
        </Stack>
      )}
      <Stack spacing={1}>
        {bps_data?.length > 0 &&
          bps_data?.map((item, ind) => {
            const handleBpData = (code = "") => {
              const data = item?.observations?.map((ele) => ele);
              const filter_data = data?.filter(
                (val) => val?.vitalobservation?.code?.text === code
              );
              return filter_data[0];
            };
            return (
              <Stack
                key={ind}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"row"}
                  gap={0.5}
                >
                  <Typography variant="caption"> BP: </Typography>
                  <Typography variant="body2" fontWeight={600}>
                    {
                      handleBpData("Systolic blood pressure")?.vitalobservation
                        ?.valueQuantity?.value
                    }
                    /
                    {
                      handleBpData("Diastolic blood pressure")?.vitalobservation
                        ?.valueQuantity?.value
                    }{" "}
                  </Typography>
                  <Typography variant="caption">Time:</Typography>
                  <Typography
                    variant="caption"
                    color="primary.dark"
                    fontWeight={600}
                  >
                    {new Date(item?._id).toString()?.split("GMT")[0]}
                  </Typography>
                </Box>
                <Text>
                  <MdEdit
                    onClick={(e) => {
                      e.preventDefault();
                      setBasicOpen(false);
                      setBpOpen(true);
                      setIndex(ind);
                      setBodyOpen(false);
                    }}
                    color={CUSTOMCOLORS.success}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Text>
              </Stack>
            );
          })}
      </Stack>
      <Stack spacing={1}>
        {basic_data?.length > 0 &&
          basic_data?.map((item, ind) => {
            const handleBasicData = (code = "") => {
              const data = item?.observations?.map((ele) => ele);
              const filter_data = data?.filter(
                (val) => val?.vitalobservation?.code?.text === code
              );
              return filter_data[0];
            };

            return (
              <Stack
                key={ind}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={1}
                  flexWrap={"wrap"}
                >
                  {handleBasicData("Pulse rate")?.vitalobservation
                    ?.valueQuantity?.value && (
                    <Typography
                      variant="caption"
                      display={"flex"}
                      flexDirection={"flex"}
                      alignItems={"center"}
                      gap={0.5}
                    >
                      {" "}
                      Pulse:
                      <Typography variant="body2" fontWeight={600}>
                        {" "}
                        {
                          handleBasicData("Pulse rate")?.vitalobservation
                            ?.valueQuantity?.value
                        }{" "}
                      </Typography>
                    </Typography>
                  )}
                  {handleBasicData(
                    "Measurement of oxygen saturation at periphery"
                  )?.vitalobservation?.valueQuantity?.value && (
                    <Typography
                      variant="caption"
                      display={"flex"}
                      flexDirection={"flex"}
                      alignItems={"center"}
                      gap={0.5}
                    >
                      SPO2:
                      <Typography variant="body2" fontWeight={600}>
                        {
                          handleBasicData(
                            "Measurement of oxygen saturation at periphery"
                          )?.vitalobservation?.valueQuantity?.value
                        }
                        %
                      </Typography>
                    </Typography>
                  )}{" "}
                  {handleBasicData("Body temperature")?.vitalobservation
                    ?.valueQuantity?.value && (
                    <Typography
                      variant="caption"
                      display={"flex"}
                      flexDirection={"flex"}
                      alignItems={"center"}
                      gap={0.5}
                    >
                      {" "}
                      Temp:
                      <Typography variant="body2" fontWeight={600}>
                        {" "}
                        {
                          handleBasicData("Body temperature")?.vitalobservation
                            ?.valueQuantity?.value
                        }
                        °F
                      </Typography>
                    </Typography>
                  )}
                  {handleBasicData("Respiratory rate")?.vitalobservation
                    ?.valueQuantity?.value && (
                    <Typography
                      variant="caption"
                      display={"flex"}
                      flexDirection={"flex"}
                      alignItems={"center"}
                      gap={0.5}
                    >
                      {" "}
                      Resp:
                      <Typography variant="body2" fontWeight={600}>
                        {" "}
                        {
                          handleBasicData("Respiratory rate")?.vitalobservation
                            ?.valueQuantity?.value
                        }
                        brpm{" "}
                      </Typography>
                    </Typography>
                  )}
                  <Typography variant="caption">
                    Time:
                    <Typography
                      variant="caption"
                      color={"primary.dark"}
                      fontWeight={600}
                    >
                      {new Date(item?._id).toString()?.split("GMT")[0]}
                    </Typography>
                  </Typography>
                </Stack>
                <Text>
                  <MdEdit
                    onClick={(e) => {
                      e.preventDefault();
                      setBasicOpen(true);
                      setBpOpen(false);
                      setIndex(ind);
                      setBodyOpen(false);
                    }}
                    color={CUSTOMCOLORS.success}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Text>
              </Stack>
            );
          })}
      </Stack>
      {others_body_data?.length > 0 && (
        <Typography
          variant="caption"
          flexDirection={"row"}
          display={"flex"}
          alignItems={"center"}
          gap={0.5}
          flexWrap={"wrap"}
        >
          {others_body_data[0]?.vitalobservation?.code?.text}:{" "}
          <Text weight={600} size={14}>
            {others_body_data[0]?.vitalobservation?.valueQuantity?.value}
          </Text>
        </Typography>
      )}
      <EditModal
        index={index}
        open={
          basicOpen ? basicOpen : bpOpen ? bpOpen : bodyOpen ? bodyOpen : false
        }
        onClose={() =>
          basicOpen
            ? setBasicOpen(false)
            : bpOpen
            ? setBpOpen(false)
            : bodyOpen
            ? setBodyOpen(false)
            : false
        }
        data={
          basicOpen
            ? basic_data
            : bpOpen
            ? bps_data
            : bodyOpen
            ? normal_data
            : []
        }
        option={basicOpen ? "basic" : bpOpen ? "bp" : bodyOpen ? "body" : ""}
      ></EditModal>
    </Box>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: isDesktopOrLaptop ? "auto" : "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: `80%`,
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  gap: 1,
  overflowY: "auto",
};
