import { Typography } from '@mui/material';
import { isDesktopOrLaptop } from '../utility/const';


export const Text = ({ variant, size, weight, family, children, color, style }) => {
    return (
        <Typography
            variant={variant ? variant : 'body2'}
            fontSize={size ? size : isDesktopOrLaptop ? 16 : 12}
            fontWeight={weight ? weight : '400'}
            fontFamily={family ? family : 'Open sans'}
            flexWrap={'wrap'}
            color={color ? color : '#000'}
            sx={style}
        >
            {children}
        </Typography>
    );
};