import React from "react";

export const DatePick = React.memo(
  ({ id, label, value, setValue, required = false, style }) => {
    return (
      <div className="flex flex-col">
        {label && (
          <label htmlFor={id} className="text-xs">
            {label}
          </label>
        )}
        <input
          type="date"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          required={required}
          placeholder="Select Date"
          className={`px-2 py-[16px] w-full font-open_sans text-zinc-500 text-sm bg-white  rounded border border-sky-500 focus:outline-none ${
            style ? style : ""
          }`}
        />
      </div>
    );
  }
);
