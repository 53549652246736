import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';


const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const isFCMSupported = () => {
  return 'serviceWorker' in navigator && 'PushManager' in window;
};

if (isFCMSupported()) {
  var messaging = getMessaging(app);
}
export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_PUBLIC_KEY,
    });
    if (currentToken) {
      return currentToken;
    } else {
      console.log(
        'No registration token available. Request permission to generate one.'
      );
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};
export const requestNotificationPermission = () => {
  if (!('Notification' in window)) {
    alert('This browser does not support system notifications');
    return;
  }
  if (Notification.permission === 'granted') {
    return 'granted';
  } else if (
    Notification.permission !== 'denied' ||
    Notification.permission === 'default'
  ) {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        return 'granted';
      }
    });
  }
};
