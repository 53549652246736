import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import { FileShowChip } from "./fileShowChip";
import { isDesktopOrLaptop } from "../utility/const";

export const FileViewer = ({
  base64String,
  height = "500px",
  label,
  onClick,
  book = false,
}) => {
  const image_check = base64String?.split(":")[1]?.startsWith("image");

  const [open, setOpen] = useState(false);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: `${image_check ? "50%" : "70%"}`,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    height: `${image_check ? (isDesktopOrLaptop ? "90%" : "40%") : "100%"}`,
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    gap: 1,
    overflowY: "auto",
  };
  return (
    <div>
      <div
        onClick={(e) => {
          e.preventDefault();
          setOpen(!open);
        }}
        style={{ cursor: "pointer" }}
      >
        <FileShowChip book={book} label={label} onClick={onClick} />
      </div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(!open);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {image_check ? (
            <img
              src={base64String} alt="picture"
              style={{
                alignSelf: "center",
              }}
            />
          ) : (
            <iframe
              title="file.pdf"
              src={base64String}
              width="100%"
              height="100%"
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default FileViewer;
