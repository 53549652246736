import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";
import { capitalizeWord } from "../utility/const";

export const RadioButton = ({
  label = "",
  value,
  setValue,
  data,
  pr_name,
  required = false,
  severity = false,
}) => {
  return (
    <FormControl>
      <Typography variant="subtitle1">{label}</Typography>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={pr_name ? JSON.stringify(value) : value}
        onChange={(e) => {
          if (pr_name) {
            setValue(JSON.parse(e.target.value));
          } else {
            setValue(e.target.value);
          }
        }}
        sx={{ ml: 1 }}
      >
        {data?.map((item, index) => (
          <FormControlLabel
            key={index}
            sx={{ display: "flex" }}
            value={pr_name ? JSON.stringify(item) : item}
            control={<Radio />}
            label={
              !severity ? (
                <span style={{ fontSize: 14, fontWeight: 400 }}>
                  {pr_name
                    ? item[pr_name] === "WALKIN"
                      ? "New"
                      : capitalizeWord(item[pr_name])
                    : capitalizeWord(item)}
                </span>
              ) : (
                <span style={{ fontSize: 14, fontWeight: 400 }}>
                  {pr_name && item[pr_name] === "Severe"
                    ? "High"
                    : item[pr_name] === "Moderate"
                    ? "Medium"
                    : item[pr_name] === "Mild"
                    ? "Low"
                    : ""}
                </span>
              )
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
