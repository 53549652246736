import { Box, Button, Modal, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import exportFromJSON from "export-from-json";
import { useSelector } from "react-redux";
import { URL } from "../utility/urls";
import { showToast } from "../utility/toast";
import { RadioButton } from "./RadioBtn";
import dayjs from "dayjs";
import { Picker } from "./Picker";
import { capitalizeWord, isDesktopOrLaptop, istab } from "../utility/const";
import { LoadingButton } from "@mui/lab";

const ExportDataModal = ({ open, onClose }) => {
  const { token } = useSelector((state) => state.login);
  const { organization, user_data } = useSelector((state) => state?.user);

  const salut = user_data[0]?.salutation || "";
  const name = user_data[0]?.name;
  const profile_id = user_data[0]?.profile_id;
  const [loading, setLoading] = useState(false);
  const { organization_data } = useSelector((state) => state?.organization);
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [period, setPeriod] = useState("");
  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          URL.getCustomFields(organization?.reference),
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const jsonData = await response.json();
        if (jsonData?.status === "success") {
          const keys = jsonData?.data?.map((item) => item?.name);
          setCustomFields(keys || []);
        }
      } catch (error) {}
    };
    fetchData();
  }, []);

  const filteredData = data?.map((item, index) => {
    const age = dayjs().diff(dayjs(item?.birthDate), "year");
    return {
      "Session date and time": item?.["Session Date & Time"],
      "Patient Name": item?.["name"],
      "Reference ID": item?.reference_ids?.filter(
        (item) => item?.organization_id === organization_data?.profile_id
      )[0]?.reference_id,
      Gender: item?.gender,
      Age: age,
      "Appointment Type":
        item?.["Appointment Type"] === "WALKIN"
          ? "New"
          : capitalizeWord(item?.["Appointment Type"]),
      "Doctor name": item?.["Doctor Name"],
      "Chief Complaint": item?.ChiefComplaints?.map((data) => data)?.join(", "),
      Diagnosis: item?.Diagnosis?.map((a) => a)?.join(", "),
      Followup: item["Followup"],
      Referral: item?.Referral?.map((referral) => referral)?.join(", "),
      ...customFields.reduce((acc, curr) => {
        acc[curr] = item?.meta_data?.[curr] ?? "";
        return acc;
      }, {}),
    };
  });

  const handleClick = (val) => {
    setPeriod(val);
    handleDates(val);
  };
  const handleDates = (period) => {
    const today = dayjs();
    let start;
    let end = today;

    switch (period) {
      case "1 week":
        start = today.subtract(7, "day");
        break;
      case "1 month":
        start = today.subtract(1, "month");
        break;
      case "3 months":
        start = today.subtract(3, "month");
        break;
      case "6 months":
        start = today.subtract(6, "month");
        break;
      case "1 year":
        start = today.subtract(1, "year");
        break;
      default:
        start = today;
    }
    setFromDate(start);
    setEndDate(end);
  };
  useEffect(() => {
    const fetchdata = async () => {
      if (!fromDate && !endDate) {
        return;
      }
      setLoading(true);
      try {
        const response = await fetch(
          URL?.exportdata(
            organization_data?.profile_id,
            fromDate.format("YYYY-MM-DD"),
            endDate.format("YYYY-MM-DD")
          ),
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const jsondata = await response.json();
        setData(jsondata?.data || []);
        setLoading(false);
      } catch (error) {
        console.log(error?.message);
      } finally {
        setLoading(false);
      }
    };
    fetchdata();
  }, [fromDate, endDate]);

  const handleExport = async () => {
    if (filteredData?.length > 0) {
      const fileName = "data";
      const exportType = exportFromJSON.types.csv;
      try {
        exportFromJSON({ data: filteredData, fileName, exportType });
        const response = await fetch(URL?.exportTimeStamps, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            rows: filteredData?.length || "0",
            organization: organization,
            profile: {
              name: `${salut} ${name}`,
              profile_id: profile_id,
            },
          }),
        });
        const jsondata = await response.json();
      } catch (error) {
        console.error("Error during export:", error);
        showToast("error", "Export failed. Please try again.");
      }
    } else {
      showToast("error", "No data found");
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Stack
          display={"flex"}
          flexDirection={isDesktopOrLaptop || istab ? "row" : "column"}
          alignItems={"center"}
          gap={2}
        >
          <Picker value={fromDate} setValue={setFromDate} label="From Date" />
          <Picker value={endDate} setValue={setEndDate} label="To Date" />
        </Stack>
        <RadioButton
          label="Select Period"
          data={["1 week", "1 month", "3 months", "6 months", "1 year"]}
          value={period}
          setValue={handleClick}
        />
        <LoadingButton
          loading={loading}
          onClick={handleExport}
          variant="contained"
          sx={{ alignSelf: "center" }}
        >
          Generate CSV
        </LoadingButton>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: isDesktopOrLaptop ? "40%" : "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  // height: "50%",
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  gap: 1,
  overflowY: "auto",
};
export default ExportDataModal;
