import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { DashBoard } from "./screens/Dashboard";
import { Login } from "./screens/Login";
import { Account } from "./screens/Account";
import { AddPatients } from "./screens/SerachPatient";
import { SlotBook } from "./screens/SlotBook";
import { Appointments } from "./screens/Apoointment";
import { Patients } from "./screens/Patients";
import { Consultation } from "./screens/consultation";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import Abha from "./screens/Abha";
import AbhaCreate from "./screens/AbhaCreate";
import AbhaVerify from "./screens/AbhaVerify";
import AbhaProfile from "./screens/AbhaProfile";
import OrganizationSelect from "./screens/OrgSelect";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4ba5fa",
      light: "#E5F2FF",
      dark: "#3683CC",
    },
    secondary: {
      main: "#F3FDFF",
    },
    info: {
      main: "#FFF9E1",
      dark: "#FFCE63",
    },
    error: {
      main: "#FF0000",
    },
    success: {
      main: "#23A330",
      light: "#EFFFEC",
    },
  },
  unstable_sxConfig: {
    borderColor: {
      themeKey: "palette",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: "Open sans",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#4ba5fa",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#4ba5fa",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#4ba5fa",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Open sans",
        },
        contained: {
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#4ba5fa",
          },
        },
        outlined: {
          color: "#4ba5fa",
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#4ba5fa",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: 2,
          height: "auto",
          border: "0.5px solid #C9C9C9",
          backgroundColor: "#ffffff",
          display: "flex",
          justifyContent: "space-between",
        },
        label: {
          whiteSpace: "normal",
          wordBreak: "break-word",
          fontSize: 12,
          fontWeight: 500,
        },
        deleteIcon: {
          color: "#FF8F8F",
          "&:hover": {
            color: "#FB6868",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#3683CC",
          "& .MuiSvgIcon-root": {
            color: "inherit",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#C0C0C0",
          "& .MuiSvgIcon-root": {
            color: "inherit",
          },
          "&.Mui-checked": {
            color: "#23A330",
          },
        },
      },
    },
  },
  typography: {
    fontFamily: "Open sans",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h6: {
      color: "#000000",
      fontWeight: 600,
    },
    h5: {
      fontWeight: 700,
    },
    subtitle1: {
      fontWeight: 600,
    },
    body2: {
      fontWeight: 400,
    },
  },
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="dashboard" element={<DashBoard />} />
      <Route path="login" element={<Login />} />
      <Route path="account" element={<Account />} />
      <Route path="addPatient" element={<AddPatients />} />
      <Route path="slotbook" element={<SlotBook />} />
      <Route path="appointments" element={<Appointments />} />
      <Route path="patients" element={<Patients />} />
      <Route path="consultation" element={<Consultation />} />
      <Route path="abha" element={<Abha />} />
      <Route path="abhacreate" element={<AbhaCreate />} />
      <Route path="abhaverify" element={<AbhaVerify />} />
      <Route path="abhaprofile" element={<AbhaProfile />} />
      <Route path="orgselect" element={<OrganizationSelect />} />
    </Route>
  )
);
const Notify = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Notify />);

reportWebVitals();
