import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { CUSTOMCOLORS } from '../styles';
import { Text } from './Text';
import { URL } from '../utility/urls';
import { addAppointmentData, addAppointmentId } from '../redux/features/appointmentData';
import { addPatientData } from '../redux/features/patientData';

export const NotificationCard = ({
  item,
  onPress,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [appointment_data, setAppointment_Data] = useState({})
  const update_visibility = async () => {
    const response = await fetch(URL.update_visibility(item?._id), {
      method: 'PATCH',
    });
    const jsonData = await response.json();
    return jsonData?.data;
  };
  const { token } = useSelector((state) => state.login);

  const fetchAppointmentData = async () => {
    const response = await fetch(
      URL.getAppoitmentById(item?.appointment_ref?.reference),
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const jsonData = await response.json()
    console.log('====================================');
    console.log(jsonData?.data);
    console.log('====================================');
    setAppointment_Data(jsonData?.data)
  };
  useEffect(() => {
    fetchAppointmentData();
  }, []);
  return (
    <Box
      border={'1px solid black'}
      borderRadius={0.5}
      p={1}
      sx={{
        backgroundColor: !item?.seen
          ? CUSTOMCOLORS.fadeblue
          : CUSTOMCOLORS.white,
        cursor: 'pointer',
      }}
      onClick={(e) => {
        e.preventDefault();
        update_visibility();
        dispatch(addAppointmentId(appointment_data?.appointment_data?._id))
        dispatch(addAppointmentData(appointment_data?.appointment_data))
        dispatch(addPatientData(appointment_data?.patient_data))
        navigate(`${item?.screen}`);
        onPress();
      }}
    >
      <Stack>
        <Text
          size={14}
          weight={'600'}
        >
          {item?.notification_data}
        </Text>
        <Text
          size={10}
          style={{ alignSelf: 'flex-end' }}
        >
          {item?.sender_name}, {item.created_at}
        </Text>
      </Stack>
    </Box>
  );
};
