import { useDispatch, useSelector } from "react-redux";
import { capitalizeWord } from "../utility/const";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { CUSTOMCOLORS } from "../styles";

export const Patient_ConsultationCard = React.memo(
  ({ data, style, app_data }) => {
    const patient_data = data?.patient;
    const birthYear = data?.patient?.birthDate?.split("-")[0];
    const presentYear = new Date().toISOString()?.split("-")[0];
    const reasons = app_data?.appointment?.reasonCode
      ?.map((item) => item?.text)
      ?.join(", ");
    const { organization } = useSelector((state) => state?.user);
    const ref_id = data?.reference_ids?.filter(
      (item) => item?.organization_id === organization?.reference
    )?.[0]?.["reference_id"];

    return (
      patient_data && (
        <Stack display={"flex"} flexDirection={"row"} py={2}>
          <Stack
            display={"flex"}
            direction={"row"}
            gap={2}
            alignItems={"center"}
          >
            <Avatar
              src={
                patient_data?.photo?.data?.startsWith("data")
                  ? patient_data?.photo?.data
                  : `data:image/jpeg;base64,${patient_data?.photo?.data}`
              }
              sx={{
                width: 64,
                height: 64,
                borderRadius: 32,
                border: 1,
                borderColor: CUSTOMCOLORS.border,
              }}
            />
            <Box>
              <Typography variant="subtitle1" fontWeight={"600"}>
                {`${patient_data?.name?.[0]?.prefix?.join(
                  ","
                )} ${capitalizeWord(patient_data?.name?.[0]?.text)}`}
              </Typography>
              <Typography variant="body2">
                {parseInt(presentYear) - parseInt(birthYear)} |{" "}
                {patient_data?.gender
                  ? capitalizeWord(patient_data?.gender)
                  : ""}{" "}
                | {patient_data?.telecom?.[0]?.value}
              </Typography>

              <Typography
                variant="caption"
                display={"flex"}
                flexDirection={"row"}
                gap={1}
                alignItems={"center"}
              >
                Reason for visit:
                <Typography variant="body2" fontWeight={"600"}>
                  {reasons}
                </Typography>
              </Typography>
              {ref_id && (
                <Typography
                  variant="caption"
                  display={"flex"}
                  flexDirection={"row"}
                  gap={1}
                  alignItems={"center"}
                >
                  Reference ID:
                  <Typography variant="body2" fontWeight={"600"}>
                    {ref_id}
                  </Typography>
                </Typography>
              )}
            </Box>
          </Stack>
        </Stack>
      )
    );
  }
);
