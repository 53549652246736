import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  complaint_update: "",
  vitals_update: "",
  reports_update: "",
};
const updateSlice = createSlice({
  name: "update",
  initialState,
  reducers: {
    updataeComplaints_Vitals: (state, action) => {
      const { complaint_update, vitals_update, reports_update } =
        action.payload;
      state.complaint_update = complaint_update;
      state.vitals_update = vitals_update;
      state.reports_update = reports_update;
    },
  },
});
export const { updataeComplaints_Vitals } = updateSlice.actions;
export const updatesReducer = updateSlice.reducer;
