import { useSelector, useDispatch } from "react-redux";
import { URL } from "../utility/urls";
import { useEffect, useState } from "react";
import { SearchInput } from "../components/SearchBar";
import { PatientCard } from "../components/patientcard";
import { addPatientData } from "../redux/features/patientData";
import { PatientCreate } from "../components/PatientCreate";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { isDesktopOrLaptop, istab } from "../utility/const";
import { Loading } from "../components/Loading";
import { RadioButton } from "../components/RadioBtn";
import { useLocation, useNavigate } from "react-router-dom";

export const AddPatients = () => {
  const location = useLocation()?.state;
  const { token } = useSelector((state) => state.login);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState("");
  const { organization } = useSelector((state) => state?.user);
  const [patData, setPatData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [abhaOpen, setAbhaOpen] = useState(false);
  const [flow, setFlow] = useState(false);
  const handleflow = (newFlow) => {
    setFlow(newFlow);
  };
  const fetchOrgData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        URL.getPatientOrg(organization?.reference, page),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const jsonData = await response.json();
      if (jsonData?.status === "success") {
        setPatData(jsonData?.data?.patients, page);
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const fetchOrgDataBySearch = async (search) => {
    setLoading(true);
    try {
      const response = await fetch(
        URL.getPatientOrgBySearch(organization?.reference, search),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const jsonData = await response.json();
      console.log(jsonData);

      if (jsonData?.status === "success") {
        setPatData(jsonData?.data);
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (search || location) {
      fetchOrgDataBySearch(search || location);
    } else {
      if (flow == true) {
        fetchOrgData();
      }
    }
  }, [search, page, flow]);

  return (
    <Stack>
      {open && (
        <PatientCreate
          open={open}
          onClose={() => setOpen(false)}
          patient_data={undefined}
          setFlow={handleflow}
        />
      )}
      <Stack spacing={2}>
        <Box
          display={"flex"}
          flexDirection={isDesktopOrLaptop || istab ? "row" : "column"}
          gap={2}
          alignItems={isDesktopOrLaptop || istab ? "center" : ""}
          width={"100%"}
        >
          <Stack flex={1}>
            <SearchInput
              placeholder={"Search by name / phone / reference id"}
              value={search}
              setValue={setSearch}
            />
          </Stack>

          <Button
            size={isDesktopOrLaptop || istab ? "large" : "medium"}
            onClick={(e) => {
              e.preventDefault();
              setOpen(true);
              // setAbhaOpen(true);
            }}
            variant="contained"
            color="success"
            sx={{
              "&:hover": {
                backgroundColor: "#23A330",
              },
              alignSelf: "end",
            }}
          >
            Add Patient
          </Button>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          {loading ? (
            <Loading />
          ) : patData?.length > 0 ? (
            <>
              {patData?.map((item) => (
                <PatientCard key={item} data={item} />
              ))}
            </>
          ) : (
            <Typography variant="h6" textAlign={"center"}>
              Search or add a patient...
            </Typography>
          )}
        </Box>
      </Stack>
      <Stack position={"relative"}>
        {page > 1 && (
          <Button
            sx={{
              alignSelf: "flex-start",
            }}
            variant="outlined"
            onClick={(e) => {
              e.preventDefault();
              return setPage((page) => page - 1);
            }}
          >
            prev
          </Button>
        )}
        {patData?.length >= 10 && (
          <Button
            variant="outlined"
            sx={{ position: "absolute", right: 0 }}
            onClick={(e) => {
              e.preventDefault();
              return setPage((page) => page + 1);
            }}
          >
            next
          </Button>
        )}
      </Stack>
      {abhaOpen && (
        <AbhaModal
          open={abhaOpen}
          close={() => setAbhaOpen(false)}
          patopen={setOpen}
        />
      )}
    </Stack>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: isDesktopOrLaptop ? "40%" : "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: isDesktopOrLaptop ? 6 : 4,
  height: "auto",
  borderRadius: 2,
  display: "flex",
  gap: 2,
  overflowY: "auto",
  alignItems: "center",
  justifyContent: "center",
};

export const AbhaModal = ({ open, close, patopen }) => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [value, setValue] = useState("");
  return (
    <Modal open={open} onClose={close}>
      <Box sx={style}>
        <RadioButton
          label="How would you like to proceed?"
          data={["With ABHA", "Without ABHA"]}
          value={value}
          setValue={(val) => {
            setValue(val);
            if (val === "Without ABHA") {
              patopen(true);
              dispatch(addPatientData(undefined));
              close();
            }
            if (val === "With ABHA") {
              nav("/abha");
            }
          }}
          variant={"h6"}
        />
      </Box>
    </Modal>
  );
};
