import React, { useState } from "react";
import { TextField, InputAdornment } from "@mui/material";

const TextFieldWithEdit = ({
  label,
  children,
  value,
  disable,
  onChange,
  onClick,
  unit,
}) => {
  return (
    <TextField
      size="medium"
      label={label}
      sx={{ width: "25ch" }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{unit}</InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <p
              style={{
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                onClick();
              }}
            >
              {children}
            </p>
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={onChange}
      disabled={disable}
    />
  );
};
export default TextFieldWithEdit;
