import { systems } from "../urls";

export const VitalValuSets = {
  height: {
    code: {
      coding: [
        {
          system: systems.snomed,
          code: "1153637007",
          display: "Body height",
        },
      ],
      text: "Body height",
    },
  },
  weight: {
    code: {
      coding: [
        {
          system: systems.snomed,
          code: "27113001",
          display: "Body weight",
        },
      ],
      text: "Body weight",
    },
  },
  Bmi: {
    code: {
      coding: [
        {
          system: systems.snomed,
          code: "446974000",
          display: "BMI (body mass index) centile",
        },
      ],
      text: "BMI",
    },
  },
  systolic: {
    code: {
      coding: [
        {
          system: systems.snomed,
          code: "271649006",
          display: "Systolic blood pressure",
        },
      ],
      text: "Systolic blood pressure",
    },
  },
  diastolic: {
    code: {
      coding: [
        {
          system: systems.snomed,
          code: "271650006",
          display: "Diastolic blood pressure",
        },
      ],
      text: "Diastolic blood pressure",
    },
  },
  lmp: {
    code: {
      coding: [
        {
          system: systems.snomed,
          code: "21840007",
          display: "Date of last menstrual period",
        },
      ],
      text: "Date of last menstrual period",
    },
  },
  edd: {
    code: {
      coding: [
        {
          system: systems.snomed,
          code: "161714006",
          display: "Estimated date of delivery",
        },
      ],
      text: "Estimated date of delivery",
    },
  },
  pulse: {
    code: {
      coding: [
        {
          system: systems.snomed,
          code: "78564009",
          display: "Pulse rate",
        },
      ],
      text: "Pulse rate",
    },
  },
  spo2: {
    code: {
      coding: [
        {
          system: systems.snomed,
          code: "250554003",
          display: "Measurement of oxygen saturation at periphery",
        },
      ],
      text: "Measurement of oxygen saturation at periphery",
    },
  },
  temp: {
    code: {
      coding: [
        {
          system: systems.snomed,
          code: "386725007",
          display: "Body temperature",
        },
      ],
      text: "Body temperature",
    },
  },
  resp: {
    code: {
      coding: [
        {
          system: systems.snomed,
          code: "86290005",
          display: "Respiratory rate",
        },
      ],
      text: "Respiratory rate",
    },
  },
};

export const vitalSchema = (data, code, value, unit, val_code, type, time) => {
  const schema = {
    vitalobservation: {
      resourceType: "Observation",
      status: "final",
      code: code?.code,
      subject: data?.pt_ref,
      performer: [data?.dr_ref, data?.org_ref],
      encounter: {
        appointment: {
          reference: data?.app_id,
        },
      },
      effectiveDateTime: time,
      valueQuantity: {
        value: value,
        unit: unit,
        system: "http://unitsofmeasure.org",
        code: val_code,
      },
      valueString: value?.toString(),
    },
    type: type,
  };
  return schema;
};
