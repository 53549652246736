import { configureStore } from "@reduxjs/toolkit";
import { Loginreducer } from "./features/logindata";
import { UserReducer } from "./features/userData";
import { AppointmentReducer } from "./features/appointmentData";
import { PatientReducer } from "./features/patientData";
import { FcmTokensReducer } from "./features/fcmTokens";
import { complaintReducer } from "./features/consultation/cheifComplaint";
import { updatesReducer } from "./features/dataUpdates";
import { ReportFindingsReducer } from "./features/consultation/reportFindings";
import { FeesReducer } from "./features/consultation/fees";
import { organizationReducer } from "./features/organizationData";
import { WtsReducer } from "./features/wtsKeys";

export const store = configureStore({
  reducer: {
    login: Loginreducer,
    user: UserReducer,
    appointment: AppointmentReducer,
    patient: PatientReducer,
    fcms: FcmTokensReducer,
    updates: updatesReducer,
    complaints: complaintReducer,
    reports: ReportFindingsReducer,
    fees: FeesReducer,
    organization: organizationReducer,
    wtsapp: WtsReducer
  },
});
