import { useSelector } from "react-redux";
import { SearchInput } from "../components/SearchBar";
import { URL } from "../utility/urls";
import { useEffect, useState } from "react";
import { PatientCard } from "../components/patientcard";
import { Button, Stack, Box } from "@mui/material";
import { Loading } from "../components/Loading";
import { isDesktopOrLaptop, istab } from "../utility/const";
export const Patients = () => {
  const { token } = useSelector((state) => state.login);
  const [search, setSearch] = useState("");
  const { organization } = useSelector((state) => state?.user);
  const [patData, setPatData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const fetchOrgData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        URL.getPatientOrg(organization?.reference, page),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const jsonData = await response.json();
      if (jsonData?.status === "success") {
        setPatData(jsonData?.data?.patients, page);
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const fetchOrgDataBySearch = async (search) => {
    setLoading(true);
    try {
      const response = await fetch(
        URL.getPatientOrgBySearch(organization?.reference, search),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const jsonData = await response.json();
      if (jsonData?.status === "success") {
        setPatData(jsonData?.data?.patients);
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (search) {
      console.log(search);

      fetchOrgDataBySearch(search);
    } else {
      fetchOrgData();
    }
  }, [search]);

  return (
    <Stack display={"flex"} flexDirection={"column"} gap={2} width={1}>
      <Box width={isDesktopOrLaptop || istab ? "75%" : "100%"}>
        <SearchInput
          value={search}
          setValue={setSearch}
          placeholder={"Search by name / phone / reference id"}
        />
      </Box>

      {loading ? (
        <Loading />
      ) : (
        <>
          <Stack display={"flex"} flexDirection={"column"} gap={1}>
            {patData?.map((item, index) => (
              <PatientCard key={index} data={item} doc={true} />
            ))}
            <Stack position={"relative"}>
              {page > 1 && (
                <Button
                  sx={{
                    alignSelf: "flex-start",
                  }}
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault();
                    return setPage((page) => page - 1);
                  }}
                >
                  prev
                </Button>
              )}
              {patData?.length >= 10 && (
                <Button
                  variant="outlined"
                  sx={{ position: "absolute", right: 0 }}
                  onClick={(e) => {
                    e.preventDefault();
                    return setPage((page) => page + 1);
                  }}
                >
                  next
                </Button>
              )}
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};
