import React from "react";
import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { memo, useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const PasswordComponent = memo(
  ({ value, onChange, label, placeholder, fullwidth, info }) => {
    const [isVisible, setIsVisible] = useState(false);
    const handleChange = () => {
      setIsVisible(!isVisible);
    };
    return (
      <Stack gap={0.5}>
        <TextField
          value={value}
          fullWidth={fullwidth}
          type={isVisible ? "text" : "password"}
          onChange={(e) => onChange(e.target.value)}
          label={label}
          placeholder={placeholder}
          inputMode="text"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isVisible ? (
                  <IoMdEye
                    onClick={handleChange}
                    style={{ cursor: "pointer", color: "#4ba5fa" }}
                  />
                ) : (
                  <IoMdEyeOff
                    onClick={handleChange}
                    style={{ cursor: "pointer", color: "#4ba5fa" }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
        {info && (
          <Stack
            display={"flex"}
            flexDirection={"row"}
            alignItems={"flex-start"}
            gap={0.2}
            width={1}
          >
            <InfoOutlinedIcon sx={{ color: "primary.dark" }} fontSize="small" />
            <Typography sx={{ color: "primary.dark" }} variant="caption">
              {info}
            </Typography>
          </Stack>
        )}
      </Stack>
    );
  }
);
