export const smallHead = {
  fontSize: 14,
  fontWeight: 600,
  color: "common.black",
};
export const smallDesc = {
  fontSize: 12,
  fontWeight: 400,
  color: "common.black",
};
export const smallDescBold = {
  fontSize: 12,
  fontWeight: 600,
};
export const BSelectChip = {
  border: '1px medium',
  borderColor: 'primary.main',
  color: 'primary.main',
  borderRadius: 2,
  padding: 1,
  fontSize: 14,
};
export const ASelectChip = {
  border: 'none',
  backgroundColor: 'primary.main',
  color: 'common.white',
  borderRadius: 2,
  padding: 1,
  fontSize: 14,
  '&:hover': {
    backgroundColor: 'primary.main',
    color: 'common.white',
  },
};