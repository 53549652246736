import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { URL } from "../utility/urls";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Checkbox,
  Modal,
  InputAdornment,
  Chip,
} from "@mui/material";
import AuthCode from "react-auth-code-input";
import { showToast } from "../utility/toast";
import { isDesktopOrLaptop } from "../utility/const";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import { ASelectChip, BSelectChip } from "../utility/styles";

const AbhaCreate = () => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({
    isAadhaar: false,
    isAadhaarOtp: true,
    isMobile: true,
    isMobileOtp: true,
    isEmail: true,
    isAbhaCreate: true,
  });
  const [timer, setTimer] = useState(0);
  const [errText, setErrTxt] = useState("");
  const [otp, setOtp] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [abhaToken, setAbhaToken] = useState("");
  const [mobile, setMobile] = useState("");
  const [txnId, setTxnId] = useState("");
  const [email, seteMail] = useState("");
  const [abhaText, setAbhaText] = useState("");
  const [isMobileSame, setIsMobileSame] = useState(false);
  const fetchAbhaSession = async () => {
    const response = await fetch(URL?.getAbhaSessionToken, {
      method: "GET",
    });
    const jsondata = await response.json();
    setAbhaToken(jsondata);
  };
  useEffect(() => {
    fetchAbhaSession();
  }, []);
  const [consent, setConsent] = useState(false);
  const PostAadhaar = async () => {
    if (!aadhar || aadhar?.length <= 11) {
      return showToast("error", "Please enter 12-digit aadhaar number");
    }
    if (!consent) {
      return showToast("error", "Please agree terms and conditions");
    }
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(URL?.createAbhaWithAadhaar, {
        method: "POST",
        headers: {
          Authorization: abhaToken,
          "content-type": "Application/json",
        },
        body: JSON.stringify({
          aadhar: aadhar,
        }),
      });
      const jsonData = await response.json();
      if (jsonData?.status === "success") {
        setTxnId(jsonData?.data?.txnId);
        setStatus((prev) => ({
          ...prev,
          isAadhaar: true,
          isAadhaarOtp: false,
        }));
        setTimer(90);
      } else if (jsonData?.status === "error") {
        showToast("error", jsonData?.data?.error?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const [isNew, setIsNew] = useState(false);
  const [data, setData] = useState({});
  const VerifyOtp = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(URL?.verifyAadhaarOtp, {
        method: "POST",
        headers: {
          Authorization: abhaToken,
          "content-type": "Application/json",
        },
        body: JSON.stringify({
          txnId: txnId,
          code: otp,
          mobile: mobile,
        }),
      });
      const jsonData = await response.json();

      if (jsonData?.status === "success") {
        if (jsonData?.data?.isNew === false) {
          setIsNew(true);
          setTimer(0);
          return setData(jsonData?.data);
        }
        if (jsonData?.data?.ABHAProfile?.mobile) {
          setIsMobileSame(true);
          return;
        }
      } else {
        showToast(
          "error",
          "Please enter a valid OTP. Entered OTP is either expired or incorrect"
        );
      }
      setData(jsonData?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const verifyMobile = async () => {
    if (mobile.length <= 9) {
      return showToast("error", "Please enter valid mobile number");
    }
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        URL.sendMobileOtp,
        {
          loginId: mobile,
          txnId,
        },
        {
          headers: {
            Authorization: abhaToken,
          },
        }
      );
      if (response.data.status === "success") {
        setStatus((prev) => ({
          ...prev,
          isMobile: true,
          isMobileOtp: false,
        }));
        setTimer(90);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const [sugs, setSugs] = useState([]);
  const getAbhaSuggestions = async () => {
    try {
      const response = await axios.get(URL.getAbhaSuggestions, {
        headers: {
          Authorization: abhaToken,
          txnId,
        },
      });
      setSugs(response.data?.data?.abhaAddressList);
    } catch (error) { }
  };
  const verifyMobileOtp = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        URL.verifyMobileOtp,
        {
          txnId,
          otp: otp,
        },
        { headers: { Authorization: abhaToken } }
      );
      if (response.data?.data?.authResult !== "Failed") {
        setStatus((prev) => ({
          ...prev,
          isEmail: false,
          isMobileOtp: true,
        }));
        setTimer(0);
        getAbhaSuggestions();
      } else {
        showToast("error", response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const sendEmailVerificationLink = async () => {
    try {
      axios.post(
        URL.sendEmailVerification,
        { email },
        {
          headers: {
            Authorization: abhaToken,
            "X-token": data?.tokens?.token,
          },
        }
      );
      showToast(
        "success",
        `Verification Link sent to these ${email} please check `
      );
      setStatus((prev) => ({
        ...prev,
        isEmail: true,
        isAbhaCreate: false,
      }));
    } catch (error) {
      console.error(error);
    }
  };
  const createAbhaAddress = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        URL.createAbhaAdress,
        {
          txnId,
          abhaAddress: `${abhaText}`,
        },
        { headers: { Authorization: abhaToken } }
      );
      console.log(response.data);

      if (response.data.status === "success") {
        nav("/abhaprofile", { state: { data, token: abhaToken } });
      }
    } catch (error) {
      setErrTxt(error?.response?.data?.data?.error?.message);
    } finally {
      setLoading(false);
    }
  };
  const [restrictOtp, setRestrictOtp] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev > 0) {
          return prev - 1;
        }
        return 0;
      });
      return;
    }, 1000);
    return () => clearInterval(interval);
  }, [!status, restrictOtp]);
  const handleTimer = (timer) => {
    const mins = Math.floor(timer / 60);
    const sec = timer % 60;
    return `${mins?.toString().padStart(2, "0")}: ${sec
      ?.toString()
      .padStart(2, "0")}`;
  };
  return (
    <Box
      p={2}
      boxShadow={"0px 0px 8px #C0DFFC"}
      display={"flex"}
      gap={2}
      flexDirection={"column"}
    >
      <Typography variant="h6">Create ABHA Number Using Aadhaar</Typography>
      {!status?.isAadhaar && (
        <Stack width={1} gap={2}>
          <Stack gap={0.5}>
            <Typography variant="subtitle1">Aadhaar Number</Typography>
            <TextField
              placeholder="0000 0000 0000"
              value={aadhar}
              onChange={(e) => setAadhar(e.target.value)}
            />
            <Stack gap={0.5} display={"flex"} flexDirection={"row"}>
              <InfoIcon fontSize="small" sx={{ color: "primary.dark" }} />
              <Typography variant="caption" color={"primary.dark"}>
                Please ensure that mobile number is linked with Aadhaar as it
                will be required for OTP authentication.
              </Typography>
            </Stack>
          </Stack>
          <Stack gap={0.5}>
            <Typography variant="subtitle1">Terms and Conditions</Typography>
            <Typography
              variant="body2"
              component={"p"}
              sx={{
                height: 160,
                overflow: "auto",
                px: 2,
                scrollbarWidth: "thin",
              }}
            >
              I, hereby declare that I am voluntarily sharing my Aadhaar number
              and demographic information issued by UIDAI, with National Health
              Authority (NHA) for the sole purpose of creation of ABHA number. I
              understand that my ABHA number can be used and shared for purposes
              as may be notified by ABDM from time to time including provision
              of healthcare services. Further, I am aware that my personal
              identifiable information (Name, Address, Age, Date of Birth,
              Gender and Photograph) may be made available to the entities
              working in the National Digital Health Ecosystem (NDHE) which
              inter alia includes stakeholders and entities such as healthcare
              professionals (e.g. doctors), facilities (e.g. hospitals,
              laboratories) and data fiduciaries (e.g. health programmes), which
              are registered with or linked to the Ayushman Bharat Digital
              Mission (ABDM), and various processes there under. I authorize NHA
              to use my Aadhaar number for performing Aadhaar based
              authentication with UIDAI as per the provisions of the Aadhaar
              (Targeted Delivery of Financial and other Subsidies, Benefits and
              Services) Act, 2016 for the aforesaid purpose. I understand that
              UIDAI will share my e-KYC details, or response of “Yes” with NHA
              upon successful authentication. I have been duly informed about
              the option of using other IDs apart from Aadhaar; however, I
              consciously choose to use Aadhaar number for the purpose of
              availing benefits across the NDHE. I am aware that my personal
              identifiable information excluding Aadhaar number / VID number can
              be used and shared for purposes as mentioned above. I reserve the
              right to revoke the given consent at any point of time as per
              provisions of Aadhaar Act and Regulations.
            </Typography>
            <Stack direction={"row"} alignItems={"center"} px={2} gap={0.5}>
              <Checkbox
                checked={consent}
                onChange={(e) => setConsent(e.target.checked)}
                sx={{ m: 0, padding: 0 }}
              />
              <Typography>I agree</Typography>
            </Stack>
          </Stack>

          <Stack sx={{ alignSelf: "flex-end" }}>
            <LoadingButton
              type="submit"
              variant="contained"
              size="medium"
              onClick={PostAadhaar}
              loading={loading}
            >
              Send Otp
            </LoadingButton>
          </Stack>
        </Stack>
      )}

      {!status?.isAadhaarOtp && (
        <Stack width={1} gap={2}>
          <Stack>
            <Typography variant="subtitle1">Enter OTP</Typography>
            <AuthCode
              onChange={(e) => setOtp(e)}
              allowedCharacters="numeric"
              inputClassName="w-10 border mr-1 p-2 text-center rounded border-blue-300 focus:outline-blue-400"
            />
            <Typography
              sx={{
                cursor: "pointer",
              }}
              variant="caption"
              color={"primary.dark"}
              mt={0.5}
              component={"p"}
              onClick={() => {
                if (restrictOtp > 3) {
                  return showToast(
                    "error",
                    "You reached maximum limit, try after sometimes"
                  );
                }
                PostAadhaar();
                setRestrictOtp((prev) => prev + 1);
              }}
            >
              {timer > 0
                ? `Don’t receive OTP ? RESEND in: ${handleTimer(timer)}s`
                : "Resend Otp"}
            </Typography>
          </Stack>
          <Stack gap={0.5}>
            <Typography variant="subtitle1">Mobile Number</Typography>
            <TextField
              placeholder="+91 0000000000"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
            <Stack gap={0.5} display={"flex"} flexDirection={"row"}>
              <InfoIcon fontSize="small" sx={{ color: "primary.dark" }} />
              <Typography variant="caption" color={"primary.dark"}>
                Please ensure that mobile number is linked with Aadhaar as it
                will be required for OTP authentication.
              </Typography>
            </Stack>
          </Stack>
          <LoadingButton
            loading={loading}
            variant="contained"
            size="medium"
            sx={{ alignSelf: "end", mt: 4 }}
            onClick={VerifyOtp}
          >
            verify
          </LoadingButton>
        </Stack>
      )}

      {!status?.isMobile && (
        <Stack gap={4}>
          <Stack>
            <Typography variant="subtitle1">Verify Mobile Number</Typography>
            <TextField
              placeholder="+91 0000000000"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
            <Stack gap={0.5} display={"flex"} flexDirection={"row"}>
              <InfoIcon fontSize="small" sx={{ color: "primary.dark" }} />
              <Typography variant="caption" color={"primary.dark"}>
                Please ensure that mobile number is linked with Aadhaar as it
                will be required for OTP authentication.
              </Typography>
            </Stack>
          </Stack>
          <LoadingButton
            loading={loading}
            variant="contained"
            size="medium"
            sx={{ alignSelf: "end" }}
            onClick={verifyMobile}
          >
            verify
          </LoadingButton>
        </Stack>
      )}
      {!status?.isMobileOtp && (
        <Stack>
          <Stack>
            <Typography variant="subtitle1">Enter OTP</Typography>
            <AuthCode
              onChange={(e) => setOtp(e)}
              allowedCharacters="numeric"
              inputClassName="w-10 border mr-1 p-2 text-center rounded border-blue-300 focus:outline-blue-400"
            />
            <Typography
              variant="caption"
              color={"primary.dark"}
              mt={0.5}
              component={"p"}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                if (restrictOtp > 3) {
                  return showToast(
                    "error",
                    "You reached maximum limit, try after sometimes"
                  );
                }
                verifyMobile();
                setRestrictOtp((prev) => prev + 1);
              }}
            >
              {timer > 0
                ? `Don’t receive OTP ? RESEND in: ${handleTimer(timer)}s`
                : "Resend Otp"}
            </Typography>
          </Stack>
          <LoadingButton
            loading={loading}
            variant="contained"
            size="medium"
            sx={{ alignSelf: "end" }}
            onClick={verifyMobileOtp}
          >
            verify
          </LoadingButton>
        </Stack>
      )}
      {!status?.isEmail && (
        <Stack gap={4}>
          <Stack>
            <Typography variant="subtitle1">Verify Email</Typography>
            <TextField
              placeholder="Enter email address"
              value={email}
              onChange={(e) => seteMail(e.target.value)}
            />
            <Stack gap={0.5} display={"flex"} flexDirection={"row"}>
              <InfoIcon fontSize="small" sx={{ color: "primary.dark" }} />
              <Typography variant="caption" color={"primary.dark"}>
                Verification will be sent to your email. You can verify it from
                your inbox later will be used for all the communication related
                to ABHA.
              </Typography>
            </Stack>
          </Stack>
          <Stack
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={2}
          >
            <Button
              variant="outlined"
              size="medium"
              onClick={() => {
                setStatus((prev) => ({
                  ...prev,
                  isEmail: true,
                  isAbhaCreate: false,
                }));
              }}
            >
              Skip for now
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={sendEmailVerificationLink}
            >
              Send Email
            </Button>
          </Stack>
        </Stack>
      )}

      {!status?.isAbhaCreate && (
        <Stack gap={4}>
          <Stack gap={0.5}>
            <Typography variant="subtitle1">ABHA Address</Typography>
            <TextField
              placeholder="Enter abha address"
              value={abhaText}
              onChange={(e) => setAbhaText(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">@sbx</InputAdornment>
                ),
              }}
            />
            {errText && <Typography color={"red"}>{errText}</Typography>}
            <Typography variant="caption">suggestions:</Typography>
            <Stack direction={"row"} spacing={1} flexWrap={"wrap"} mt={2}>
              {sugs?.map((item, index) => (
                <Chip
                  sx={abhaText === item ? ASelectChip : BSelectChip}
                  key={index}
                  label={item}
                  onClick={() => setAbhaText(item)}
                />
              ))}
            </Stack>
          </Stack>

          <Button
            variant="contained"
            size="medium"
            sx={{ alignSelf: "end" }}
            onClick={createAbhaAddress}
          >
            Submit
          </Button>
        </Stack>
      )}

      {isNew && (
        <ExistingModal
          open={isNew}
          onClose={setIsNew}
          data={data}
          token={abhaToken}
        />
      )}
      {isMobileSame && (
        <MobileModal
          open={isMobileSame}
          onClose={setIsMobileSame}
          setStatus={setStatus}
        />
      )}
    </Box>
  );
};

export default AbhaCreate;

const ExistingModal = ({ open, onClose, data, token }) => {
  const nav = useNavigate();
  const handleClose = () => {
    onClose(!open);
  };
  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography variant="h6" whiteSpace={"nowrap"}>
          This user is already registered with an ABHA
        </Typography>
        <Stack display={"flex"} alignSelf={"flex-end"}>
          <Button
            size="large"
            onClick={() =>
              nav("/abhaprofile", {
                state: {
                  data,
                  token,
                },
              })
            }
          >
            Click here
          </Button>
          {/* <Button variant="outlined" size="small" onClick={handleClose}>
              Update ABHA Address
            </Button> */}
        </Stack>
      </Box>
    </Modal>
  );
};

const MobileModal = ({ open, onClose, setStatus }) => {
  const handleClose = () => {
    onClose(!open);
  };
  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography>
          Mobile number for communication is same as Aadhaar number.No
          Additional information required.
        </Typography>
        <Button
          onClick={() => {
            setStatus((prev) => ({
              ...prev,
              isMobile: true,
              isAadhaarOtp: true,
              isEmail: true,
              isAbhaCreate: false,
            }));
            handleClose();
          }}
        >
          Continue
        </Button>
      </Box>
    </Modal>
  );
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: isDesktopOrLaptop ? "40%" : "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: isDesktopOrLaptop ? 6 : 4,
  height: "auto",
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  gap: 2,
  overflowY: "auto",
};
