import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  reportFindings: {
    text_data: [],
    orgainization_id: {},
    practitioner_id: {},
    encounter: {},
    patient_id: {},
  },
};

const ReportFindingSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    addReportFindings: (state, action) => {
      state.reportFindings = action.payload;
    },
  },
});

export const { addReportFindings } = ReportFindingSlice.actions;
export const ReportFindingsReducer = ReportFindingSlice.reducer;
