import { Box, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isDesktopOrLaptop } from "../utility/const";
import { URL } from "../utility/urls";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isDesktopOrLaptop ? "40%" : "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "90%",
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  gap: 1,
  overflowY: "auto",
};

const FollowupModal = ({ open, onClose }) => {
  const { token } = useSelector((state) => state.login);
  const { organization } = useSelector((state) => state?.user);
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchdata = async () => {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow?.setDate(today.getDate() + 1);
      const formattedDate = tomorrow?.toISOString()?.split("T")[0];
      const response = await fetch(
        URL.followups(organization?.reference, formattedDate),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const jsonData = await response.json();
      if (jsonData?.status === "success") {
        setData(jsonData?.data);
      }
    };
    fetchdata();
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="subtitle1" color={"primary.dark"}>
          Followup Remainder
        </Typography>
        <Stack gap={1}>
          {data?.length > 0 ? (
            data?.map((item) => (
              <Stack
                border={0.5}
                borderColor={"primary.main"}
                borderRadius={1}
                key={item}
              >
                <Typography bgcolor={"primary.light"} variant="subtitle2" p={1}>
                  {item?.followUp?.participant[0]?.actor?.display} - FollowUp :
                  {item?.followUp?.start?.split("T")[0]}
                </Typography>
                <Typography p={1} variant="body2">
                  {" "}
                  {item?.followUp?.participant[0]?.actor?.display} |{" "}
                  {item?.patient_phoneNumber} | Reason:{" "}
                  {item?.followUp?.reasonCode[0]?.text}
                </Typography>
              </Stack>
            ))
          ) : (
            <Typography>No remainders found</Typography>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};
export default FollowupModal;
