import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import aadharImg from "../assets/aadhar.png";
import mobileImg from "../assets/mobile.png";
import abhaImg from "../assets/abha.png";
import { URL } from "../utility/urls";
import axios from "axios";
import AuthCode from "react-auth-code-input";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { showToast } from "../utility/toast";
import { isDesktopOrLaptop } from "../utility/const";

const AbhaVerify = () => {
  const img = [
    {
      url: aadharImg,
      text: "Aadhaar",
    },
    {
      url: mobileImg,
      text: "Mobile",
    },
    {
      url: abhaImg,
      text: "Abha number",
    },
  ];
  const nav = useNavigate();
  const [consent, setConsent] = useState(false);
  const [show, setShow] = useState(false);
  const [abhaToken, setAbhaToken] = useState("");
  const [select, setSelect] = useState("");
  const [value, setValue] = useState("");
  const [otpshow, setOtpShow] = useState(false);
  const [otp, setotp] = useState("");
  const [txnId, settxnId] = useState("");
  const [timer, setTimer] = useState(0);
  const [restricTimer, setRestricTimer] = useState(0);
  const [mobileflow, setmobileflow] = useState(false);
  const [multipleAcc, setmultipleAcc] = useState([]);
  const fetchAbhaSession = async () => {
    const response = await fetch(URL?.getAbhaSessionToken, {
      method: "GET",
    });
    const jsondata = await response.json();
    setAbhaToken(jsondata);
  };
  useEffect(() => {
    fetchAbhaSession();
  }, []);

  const postdata = async (e) => {
    e.preventDefault();
    if (!consent && select === "Aadhaar") {
      return showToast("error", "Please agree terms and conditions");
    }
    try {
      const response = await axios.post(
        URL?.verifyAbhaAddress,
        {
          hint:
            select === "Aadhaar"
              ? "aadhaar"
              : select === "Mobile"
              ? "mobile"
              : select === "Abha number"
              ? "abha-number"
              : "",
          loginId: value,
          otpType:
            select === "Aadhaar"
              ? "aadhaar"
              : select === "Mobile"
              ? "abdm"
              : select === "Abha number"
              ? "abdm"
              : "",
          scope: select === "Aadhaar" ? "aadhaar-verify" : "mobile-verify",
        },
        {
          headers: {
            Authorization: abhaToken,
          },
        }
      );
      if (response?.data?.status === "success") {
        setOtpShow(true);
        settxnId(response?.data?.data?.txnId);
        setTimer(90);
      } else {
        showToast("error", response?.data?.status);
      }
    } catch (error) {
      showToast("error", error.response?.data?.data?.message);
    }
  };
  const verifyOtp = async () => {
    try {
      const response = await axios.post(
        URL?.verifyAbhaAddressOtp,
        {
          otp: otp,
          txnId: txnId,
          scope: select === "Aadhaar" ? "aadhaar-verify" : "mobile-verify",
        },
        {
          headers: {
            Authorization: abhaToken,
          },
        }
      );
      if (response?.data?.status === "success") {
        if (select === "Mobile") {
          setmobileflow(true);
          setmultipleAcc(response?.data?.data);
        } else {
          nav("/abhaprofile", {
            state: {
              token: abhaToken,
              data: response?.data?.data?.token,
              key: "verify",
              option: select,
            },
          });
        }
        setTimer(0);
      }
    } catch (error) {
      showToast("error", error.response?.data?.data?.message);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev > 0) {
          return prev - 1;
        }
        return 0;
      });
      return;
    }, 1000);
    return () => clearInterval(interval);
  }, [otpshow, restricTimer]);
  const handleTimer = (timer) => {
    const mins = Math.floor(timer / 60);
    const sec = timer % 60;
    return `${mins.toString()?.padStart(2, "0")} : ${sec
      .toString()
      ?.padStart(2, "0")}`;
  };
  return (
    <Stack
      gap={4}
      borderRadius={1}
      boxShadow={"0px 0px 4px 0px #C0DFFC"}
      padding={2}
      position={"relative"}
    >
      {!show && (
        <Stack gap={4}>
          <Stack gap={0.5}>
            <Typography variant="h6">Verify ABHA Number</Typography>

            <Typography variant="subtitle2">
              Select any option below for verify abha number
            </Typography>
          </Stack>
          <Stack
            flexDirection={"row"}
            gap={4}
            sx={{
              "@media (max-width:420px)": {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              },
            }}
          >
            {img?.map((item) => (
              <Box
                key={item}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                boxShadow={"0px 0px 4px 0px #0000004A"}
                width={132}
                borderRadius={2}
                sx={{
                  // "&:hover": {
                  //   bgcolor: "primary.light",
                  // },
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelect(item?.text);
                  setShow(true);
                }}
              >
                <Avatar src={item?.url} sx={{ width: "100%", height: 100 }} />
                <Typography variant="subtitle1">{item?.text}</Typography>
              </Box>
            ))}
          </Stack>
        </Stack>
      )}
      {select && (
        <Stack gap={2}>
          <Typography variant="h6">{`Verify Abha Number Using ${select}`}</Typography>
          {!otpshow && (
            <Stack gap={0.5}>
              <Typography variant="subtitle1">
                {select === "Aadhaar"
                  ? "Aadhaar number"
                  : select === "Mobile"
                  ? "Mobile number"
                  : "Abha number"}
              </Typography>
              <TextField
                fullWidth
                placeholder={
                  select === "Aadhaar"
                    ? "0000 0000 0000"
                    : select === "Mobile"
                    ? "+91 0000000000"
                    : "Enter abha number"
                }
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              {select === "Aadhaar" && (
                <Stack
                  gap={0.5}
                  display={"flex"}
                  flexDirection={"row"}
                  mt={0.5}
                >
                  <InfoIcon fontSize="small" sx={{ color: "primary.dark" }} />
                  <Typography variant="caption" color={"primary.dark"}>
                    Please ensure that mobile number is linked with Aadhaar as
                    it will be required for OTP authentication.
                  </Typography>
                </Stack>
              )}

              {select !== "Mobile" && (
                <Stack gap={1}>
                  <Typography variant="subtitle1">
                    Terms and Conditions
                  </Typography>
                  <Stack
                    height={160}
                    overflow={"auto"}
                    sx={{
                      scrollbarWidth: "thin",
                    }}
                    px={2}
                  >
                    <Typography variant="body2">
                      I, hereby declare that I am voluntarily sharing my Aadhaar
                      number and demographic information issued by UIDAI, with
                      National Health Authority (NHA) for the sole purpose of
                      creation of ABHA number. I understand that my ABHA number
                      can be used and shared for purposes as may be notified by
                      ABDM from time to time including provision of healthcare
                      services. Further, I am aware that my personal
                      identifiable information (Name, Address, Age, Date of
                      Birth, Gender and Photograph) may be made available to the
                      entities working in the National Digital Health Ecosystem
                      (NDHE) which inter alia includes stakeholders and entities
                      such as healthcare professionals (e.g. doctors),
                      facilities (e.g. hospitals, laboratories) and data
                      fiduciaries (e.g. health programmes), which are registered
                      with or linked to the Ayushman Bharat Digital Mission
                      (ABDM), and various processes there under. I authorize NHA
                      to use my Aadhaar number for performing Aadhaar based
                      authentication with UIDAI as per the provisions of the
                      Aadhaar (Targeted Delivery of Financial and other
                      Subsidies, Benefits and Services) Act, 2016 for the
                      aforesaid purpose. I understand that UIDAI will share my
                      e-KYC details, or response of “Yes” with NHA upon
                      successful authentication. I have been duly informed about
                      the option of using other IDs apart from Aadhaar; however,
                      I consciously choose to use Aadhaar number for the purpose
                      of availing benefits across the NDHE. I am aware that my
                      personal identifiable information excluding Aadhaar number
                      / VID number can be used and shared for purposes as
                      mentioned above. I reserve the right to revoke the given
                      consent at any point of time as per provisions of Aadhaar
                      Act and Regulations.
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    px={2}
                    gap={0.5}
                  >
                    <Checkbox
                      checked={consent}
                      onChange={(e) => setConsent(e.target.checked)}
                      sx={{ m: 0, padding: 0 }}
                    />
                    <Typography>I agree</Typography>
                  </Stack>
                </Stack>
              )}
            </Stack>
          )}
          {otpshow && (
            <Stack gap={1}>
              <Typography variant="body2">Enter OTP</Typography>
              <AuthCode
                onChange={(e) => setotp(e)}
                allowedCharacters="numeric"
                inputClassName="w-10 border mr-1 p-2 text-center rounded border-blue-300 focus:outline-blue-400"
              />
              <Typography
                variant="caption"
                sx={{
                  cursor: "pointer",
                  color: "primary.dark",
                }}
                component={"p"}
                onClick={(e) => {
                  if (restricTimer > 3) {
                    return showToast(
                      "error",
                      "You reached maximum limit, try after sometimes"
                    );
                  }
                  postdata(e);
                  setRestricTimer((prev) => prev + 1);
                }}
              >
                {timer > 0
                  ? `Reasend otp in ${handleTimer(timer)}s`
                  : "Reasend otp"}
              </Typography>
            </Stack>
          )}
          <Box sx={{ alignSelf: "end" }}>
            <Button
              type="submit"
              variant="contained"
              onClick={(e) => {
                if (otpshow) {
                  verifyOtp();
                } else {
                  postdata(e);
                }
              }}
            >
              {otpshow ? "Submit" : "next"}
            </Button>
          </Box>
        </Stack>
      )}
      {mobileflow && (
        <MultipleAccounts
          open={mobileflow}
          close={() => setmobileflow(false)}
          data={multipleAcc}
          sessionToken={abhaToken}
        />
      )}
    </Stack>
  );
};

export default AbhaVerify;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isDesktopOrLaptop ? "40%" : "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: isDesktopOrLaptop ? 4 : 2,
  height: "auto",
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  gap: 2,
  overflowY: "auto",
};
export const MultipleAccounts = ({ open, close, data, sessionToken }) => {
  const nav = useNavigate();
  const fetchXToken = async (abhanum, txnId, Ttoken, token) => {
    try {
      const response = await axios.post(
        URL?.getXToken,
        {
          abhanumber: abhanum,
          txnId: txnId,
        },
        {
          headers: {
            Authorization: token,
            "T-token": `Bearer ${Ttoken}`,
          },
        }
      );
      console.log("x-token", response?.data);
      if (response?.data?.status === "success") {
        nav("/abhaprofile", {
          state: {
            token: sessionToken,
            data: response?.data?.data?.token,
            key: "verify",
          },
        });
      }
    } catch (error) {
      showToast("error", error?.response?.data?.data?.message);
    }
  };
  return (
    <Modal open={open} onClose={close}>
      <Box sx={style}>
        <Typography variant="h6">Select</Typography>
        {data?.accounts?.map((item) => (
          <Box
            onClick={() => {
              fetchXToken(
                item?.ABHANumber,
                data?.txnId,
                data?.token,
                sessionToken
              );
            }}
            display={"flex"}
            flexDirection={"row"}
            gap={1}
            alignItems={"center"}
            p={1}
            borderRadius={1}
            boxShadow={"0px 0px 4px 0px #C0DFFC"}
            bgcolor={"primary.light"}
            sx={{
              cursor: "pointer",
            }}
          >
            <Avatar src={`data:image/png;base64,${item?.profilePhoto}`} />
            <Stack>
              <Typography variant="subtitle1">{item?.name}</Typography>
              <Typography variant="body2">{item?.ABHANumber}</Typography>
            </Stack>
          </Box>
        ))}
      </Box>
    </Modal>
  );
};
