import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { URL} from "../utility/urls";
import { isDesktopOrLaptop, istab } from "../utility/const";
import { Button, Chip, Stack, Typography } from "@mui/material";
import { SearchField } from "./SearchFiled";
import { updataeComplaints_Vitals } from "../redux/features/dataUpdates";
export const ChiefComplaint = (props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.login);
  let { complaint_update } = useSelector((state) => state.updates);
  const [value, setValue] = useState([]);
  console.log(value);
  const [snomedValue, setSnomedValue] = useState("");
  const [snomedData, setSnomedData] = useState([]);
  const { dr_ref, pt_ref, app_id, org_ref } = props.app_data;
  const complaints_data = props.consultation?.map((item) => ({
    id: item?._id,
    code: item?.chiefComplaint?.code?.text,
  }));
  const fetchCompalint = async () => {
    const response = await fetch(
      URL.snomed(
        snomedValue ? snomedValue : "NA",
        "procedure++situation++finding"
      ),
      {
        method: "GET",
      }
    );
    const data = await response.json();
    setSnomedData(data);
  };
  useEffect(() => {
    fetchCompalint();
  }, [snomedValue]);

  const PostComplaints = async (val) => {
    const response = await fetch(URL.createComplaints, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chiefComplaint: {
          resourceType: "Conditon",
          code: {
            coding: val,
            text: val[0]?.display,
          },
          subject: pt_ref,
          encounter: {
            appointment: {
              reference: app_id,
            },
            recorder: dr_ref,
          },
        },
        type: "ChiefComplaint",
        organization: org_ref,
      }),
    });
    const jsondata = await response.json();
    if (jsondata.status === "success") {
      setValue([]);
      setSnomedValue("");
      dispatch(
        updataeComplaints_Vitals({
          vitals_update: "",
          complaint_update: (complaint_update += "s"),
          reports_update: "",
        })
      );
    }
  };
  const handleComplaint = async (item) => {
    setValue((val) => [...val, item]);
    await PostComplaints([item]);
  };

  const deleteApi = async (id) => {
    const response = await fetch(URL.delChiefComplaint(id), {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    if (jsonData?.status === "success") {
      dispatch(
        updataeComplaints_Vitals({
          vitals_update: "",
          complaint_update: (complaint_update += "s"),
          reports_update: "",
        })
      );
    }
  };
  const onPress = () => {
    props.close();
  };
  return (
    <Modal
      open={props.open}
      onClose={props.close}
      center
      styles={{
        modal: {
          flex: 1,
          width: isDesktopOrLaptop ? "40%" : "80%",
          height: istab ? "70%" : "60%",
          borderRadius: 16,
        },
      }}
    >
      <Stack py={2}>
        <Stack spacing={2}>
          <Typography variant="h6">Chief complaint</Typography>
          <Stack spacing={0.5}>
            {complaints_data?.length > 0 && (
              <Typography
                variant={isDesktopOrLaptop || istab ? "subtitle1" : "subtitle2"}
              >
                Complaints
              </Typography>
            )}
            {complaints_data?.length > 0 && (
              <Stack
                direction={"row"}
                gap={0.5}
                display={"flex"}
                flexWrap={"wrap"}
              >
                {complaints_data?.map((item, index) => (
                  <Chip
                    key={index}
                    label={item?.code}
                    variant="outlined"
                    onDelete={() => {
                      deleteApi(item?.id);
                    }}
                  />
                ))}
              </Stack>
            )}
            <SearchField
              value={snomedValue}
              setValue={setSnomedValue}
              data={snomedData}
              label="Search complaints"
              placeholder="Search complaints"
              onClick={handleComplaint}
            />
          </Stack>
          <Button
            variant="contained"
            onClick={onPress}
            sx={{
              alignSelf: "center",
            }}
          >
            Save{" "}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
