import React from "react";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { addPatientData } from "../redux/features/patientData";
import { capitalizeWord, isDesktopOrLaptop } from "../utility/const";
import { URL } from "../utility/urls";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isDesktopOrLaptop ? "90%" : "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: isDesktopOrLaptop ? 4 : 2,
  height: "90%",
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  gap: 1,
  overflowY: "auto",
};
export const PatientCheckModal = ({ open, onClose, data }) => {
  const handleClose = () => {
    onClose(!open);
  };
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [selected, setSelected] = useState({ id: "", refId: "" });
  const { organization } = useSelector((state) => state?.user);
  const { token } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const addPatientToOrg = async () => {
    setLoading(true);
    try {
      const response = await fetch(URL.addOrgToPatient(selected?.id), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          orgIds: {
            reference: organization?.reference,
            display: organization?.display,
          },
          refIds: {
            reference_id: selected?.refId,
            organization_id: organization?.reference,
          },
        }),
      });
      const jsonData = await response.json();
      if (jsonData?.status === "success") {
        setSelected({ id: "", refId: "" });
        dispatch(addPatientData(jsonData?.data));
        handleClose();
        navigation("/slotbook");
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography>Some patients matched with your details</Typography>
        <Typography>If any of them is your patient, please select</Typography>
        <Box position={"relative"} height={"100%"} width={"100%"}>
          {data?.map((item) => {
            const chkPatient = item?.patient?.generalPractitioner?.some(
              (item) => item?.reference === organization?.reference
            );

            return (
              <PatientChkCard
                Item={item}
                selected={selected}
                setSelected={setSelected}
                organization={organization}
                isExist={chkPatient}
              />
            );
          })}
          <Stack
            mt={2}
            display={"flex"}
            flexDirection={"row"}
            gap={2}
            position={"absolute"}
            bottom={0}
            right={0}
          >
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={addPatientToOrg}
              disabled={loading}
            >
              Save and Continue
            </LoadingButton>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

const PatientChkCard = ({ Item, selected, setSelected, isExist }) => {
  const { prefix, text } = Item?.patient?.name?.[0];
  const dob = Item?.patient?.birthDate;
  const age = dayjs().year() - dayjs(dob).year();
  const gender = Item?.patient?.gender;
  const address = Item?.patient?.address?.[0];
  const image = Item?.patient?.photo?.data;
  const phone = Item?.patient?.telecom?.[0]?.value;

  const uniqueId = () => {
    const timestamp =
      new Date().getTime() * 1000 + Math.floor(Math.random() * 1000);
    return timestamp.toString().slice(-3);
  };
  const refId = () => {
    const Id = `${text?.slice(0, 2).toUpperCase()}`;
    return `${Id}${uniqueId()}${dayjs(new Date()).format("DDMMYYYY")}_${gender
      ?.slice(0, 1)
      ?.toUpperCase()}_${new Date().getTime()?.toString()?.slice(-3)}`;
  };

  return (
    <Box
      p={1}
      sx={{ border: "1px solid black", borderRadius: 4 }}
      display={"flex"}
      gap={2}
      flexWrap={"wrap"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Stack direction={"row"} gap={2}>
        <img
          src={image}
          alt="image"
          style={{ width: 80, height: 80, borderRadius: 40 }}
        />
        <Stack>
          <Typography>
            {prefix?.join("")}. {text}
          </Typography>
          <Typography>
            {age} | {dob}
          </Typography>
          <Typography>{phone}</Typography>
          <Typography>{capitalizeWord(gender)}</Typography>
          <Typography>
            {address?.text ? `${address?.text},` : ""}
            {address?.city}
          </Typography>
        </Stack>
      </Stack>
      {isExist ? (
        <Typography color={"red"}>Already Exist ! Please search</Typography>
      ) : (
        <Button
          onClick={() => {
            if (selected?.id === Item?._id) {
              setSelected({ id: "", refId: "" });
            } else {
              setSelected({ id: Item?._id, refId: refId() });
            }
          }}
          variant={selected?.id === Item?._id ? "contained" : "outlined"}
        >
          Select
        </Button>
      )}
    </Box>
  );
};
