import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";

import { LoadingButton } from "@mui/lab";

import {
  AbhaConsentPurpose,
  AbhaDocumentTypes,
  getAbhaSessionToken,
} from "../../utility/M3 APIS FNS/getConsentStatus";
import { isDesktopOrLaptop, istab } from "../../utility/const";
import { showToast } from "../../utility/toast";
import { Picker } from "../Picker";
import { URL } from "../../utility/urls";
import { addPatientData } from "../../redux/features/patientData";
import { RadioButton } from "../RadioBtn";

const verifyABHAADDRESS = async ({ abhaToken, AbhaAddress }) => {
  try {
    const verifyAbhaAddress = await fetch(URL?.m3VeifyAbhaAddress, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: abhaToken,
      },
      body: JSON.stringify({
        abhaAddress: AbhaAddress,
      }),
    });
    const verifyAbhaAddressJson = await verifyAbhaAddress.json();
    return verifyAbhaAddressJson;
  } catch (error) {
    return;
  }
};

export const NewConsent = ({ handleFunction, doc }) => {
  const { organization_data } = useSelector((state) => state?.organization);
  const { token } = useSelector((state) => state?.login);
  const [loading, setLoading] = useState(false);

  const { selectedDoctor, doctors } = useSelector((state) => state?.user);
  const doctor_name = selectedDoctor?.display;
  console.log(selectedDoctor);

  const { patient_data } = useSelector((state) => state?.patient);
  const AbhaAddress = patient_data?.patient?.identifier?.filter(
    (item) => item?.type?.text === "ABHAADDRESS"
  )[0]?.value;

  const doctor =
    doctors?.filter((item) => item?.reference === doc?.reference)?.[0] ||
    selectedDoctor;

  const docRegNo = doctor?.medicalRegestrationNumber;
  const docIdentifier =
    doctor?.doc_identifier?.filter((item) => item?.type?.text === "hprId")?.[0]
      ?.value || "";

  let docIdentifierData = {};
  const hiuId =
    organization_data?.organization?.identifier?.filter(
      (item) => item?.type?.text === "hfrId" || item?.type?.text === "HFR"
    )?.[0]?.value || "";

  if (docRegNo) {
    docIdentifierData = {
      type: "REGNO",
      value: docRegNo,
      system: "https://www.mciindia.org",
    };
  }
  if (docIdentifier) {
    docIdentifierData = {
      type: "MD",
      value: docIdentifier,
      system: "https://doctor.ndhm.gov.in",
    };
  }

  const [verifyAbhaError, setVerifyAbhaError] = useState("");
  const [fromDate, setFromDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [period, setPeriod] = useState("10 years");
  const [open, setOpen] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [consentPurpose, setConsentPurpose] = useState({});

  const handleTimeIntervals = ({ period, duration = false }) => {
    const today = dayjs();
    let start = dayjs(new Date());
    let end = today;

    switch (period) {
      case "6 months":
        start = duration
          ? today.subtract(6, "months")
          : today.add(6, "months").subtract(1, "day");
        break;
      case "1 year":
        start = duration
          ? today.subtract(1, "year")
          : today.add(1, "year").subtract(1, "day");
        break;
      case "3 years":
        start = duration
          ? today.subtract(3, "years")
          : today.add(3, "years").subtract(1, "day");
        break;
      case "5 years":
        start = duration
          ? today.subtract(5, "years")
          : today.add(5, "years").subtract(1, "day");
        break;
      case "10 years":
        start = duration
          ? today.subtract(10, "years")
          : today.add(10, "years").subtract(1, "day");
        break;
      case "15 years":
        start = duration
          ? today.subtract(15, "years")
          : today.add(15, "years").subtract(1, "day");
        break;
      case "20 years":
        start = duration
          ? today.subtract(20, "years")
          : today.add(20, "years").subtract(1, "day");
        break;
      default:
        start = duration ? today : today.add(1, "year").subtract(1, "day");
    }
    return {
      start,
      end: !duration ? end : end.subtract(1, "day"),
    };
  };

  const handleDates = (period) => {
    const { start, end } = handleTimeIntervals({ period, duration: true });
    setFromDate(start);
    setEndDate(end);
  };

  const handleClick = (val) => {
    setPeriod(val);
    handleDates(val);
  };

  useEffect(() => {
    handleDates("10 years");
  }, []);

  const [expiresIn, setExpiresIn] = useState({
    period: "1 year",
    date: handleTimeIntervals({ period: "1 year", duration: false })?.start,
  });

  const handleNewConsent = async () => {
    try {
      if (new Date(endDate) <= new Date(fromDate)) {
        showToast("error", "To Date should be greater than From Date");
        return;
      }
      if (
        new Date().toISOString().split("T")[0] ===
        endDate.toISOString().split("T")[0]
      ) {
        showToast("error", "To Date should be less than Today's Date");
        return;
      }
      if (expiresIn?.date < new Date()) {
        showToast(
          "error",
          "Consent Expired Date should be greater than Today's Date"
        );
        return;
      }
      if (documentTypes?.length === 0) {
        showToast("error", "Please select at least one document type");
        return;
      }
      if (Object.keys(consentPurpose)?.length === 0) {
        showToast("error", "Please select consent purpose");
        return;
      }
      setLoading(true);
      const abhaToken = await getAbhaSessionToken();
      const verifyAbhaAddressJson = await verifyABHAADDRESS({
        abhaToken,
        AbhaAddress,
      });

      if (
        !(
          verifyAbhaAddressJson?.data?.status &&
          verifyAbhaAddressJson?.data?.status === "ACTIVE"
        )
      ) {
        setVerifyAbhaError(
          "ABHA Address is not active or Invalid Abha Address. Please update your ABHA Address"
        );
        return;
      }

      const newConsent = await fetch(URL?.addNewConsent, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          ABDMAuthorization: abhaToken,
        },
        body: JSON.stringify({
          abhaAddress: AbhaAddress,
          doctorName: doctor_name,
          doctorIdentifier: docIdentifierData,
          hiuId: hiuId,
          fromDate: dayjs(fromDate).toISOString(),
          toDate: `${dayjs(endDate).format("YYYY-MM-DD")}T23:59:59.999Z`,
          doctorProfileId: selectedDoctor?.profile_id,
          hiuName: organization_data?.organization?.name,
          patientName:
            `${patient_data?.patient?.name[0]?.prefix[0]} ${patient_data?.patient?.name[0]?.text}`.trim(),
          expiresIn: dayjs(expiresIn?.date).toISOString(),
          hiTypes: documentTypes,
          purpose: consentPurpose,
        }),
      });
      const data = await newConsent.json();
      console.log(data);

      if (data?.status === "success") {
        showToast("success", data?.message);
        handleFunction();
      } else {
        showToast("error", data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const timeIntervals = [
    "6 months",
    "1 year",
    "3 years",
    "5 years",
    "10 years",
    "15 years",
    "20 years",
  ];

  const handleExpiresIn = (val) => {
    const { start } = handleTimeIntervals({ period: val, duration: false });
    setExpiresIn({ period: val, date: start });
  };
  console.log(hiuId, docIdentifierData);

  return (
    <Stack spacing={2}>
      {!hiuId && (
        <Typography color={"red"}>
          HFR ID is required. Please contact your organization.
        </Typography>
      )}
      {!docIdentifierData?.value && (
        <Typography color={"red"}>
          Doctor Identifier is required. Please contact your {doctor_name}.
        </Typography>
      )}
      <TextField
        value={AbhaAddress}
        label="ABHA Address"
        placeholder="ABHA Address"
        disabled
      />

      <Stack>
        <Typography variant="subtitle1">
          Select Document Type<span style={{ color: "red" }}>*</span>
        </Typography>
        <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
          {AbhaDocumentTypes?.map((item, ind) => (
            <Chip
              key={ind}
              sx={{
                backgroundColor: documentTypes?.some((ele) => ele === item)
                  ? "primary.main"
                  : "",
                color: documentTypes?.some((ele) => ele === item)
                  ? "common.white"
                  : "",
              }}
              variant={
                documentTypes?.some((ele) => ele === item)
                  ? "filled"
                  : "outlined"
              }
              label={item}
              onClick={() => {
                if (documentTypes?.some((ele) => ele === item)) {
                  setDocumentTypes(
                    documentTypes?.filter(
                      (i) => i?.toLowerCase() !== item?.toLowerCase()
                    )
                  );
                } else {
                  setDocumentTypes([...documentTypes, item]);
                }
              }}
            />
          ))}
        </Stack>
      </Stack>
      <Stack>
        <Typography variant="subtitle1">
          Select Consent Purpose<span style={{ color: "red" }}>*</span>
        </Typography>
        <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
          {AbhaConsentPurpose?.map((item, ind) => (
            <Chip
              key={ind}
              sx={{
                backgroundColor:
                  consentPurpose?.code === item?.code ? "primary.main" : "",
                color:
                  consentPurpose?.code === item?.code ? "common.white" : "",
              }}
              variant={
                consentPurpose?.code === item?.code ? "filled" : "outlined"
              }
              label={item?.text}
              onClick={() => {
                if (consentPurpose?.code === item?.code) {
                  setConsentPurpose({});
                } else {
                  setConsentPurpose(item);
                }
              }}
            />
          ))}
        </Stack>
      </Stack>

      <RadioButton
        label="Select Period"
        data={timeIntervals}
        value={period}
        setValue={handleClick}
      />

      <Stack
        display={"flex"}
        flexDirection={isDesktopOrLaptop || istab ? "row" : "column"}
        alignItems={"center"}
        gap={2}
      >
        <Picker value={fromDate} setValue={setFromDate} label="From Date" />
        <Picker
          value={endDate}
          setValue={setEndDate}
          label="To Date"
          disableFuture
        />
      </Stack>
      {verifyAbhaError && (
        <>
          <Typography color={"red"}>{verifyAbhaError}</Typography>
          <Button onClick={() => setOpen(true)}>Update AbhaAddress</Button>
        </>
      )}

      <RadioButton
        label="Select consent expired in"
        data={timeIntervals}
        value={expiresIn?.period}
        setValue={handleExpiresIn}
      />
      <Picker
        value={expiresIn?.date}
        setValue={(val) => {
          setExpiresIn({ ...expiresIn, date: val });
        }}
        label="Expires In"
      />

      <LoadingButton
        sx={{ alignSelf: "center" }}
        variant="contained"
        onClick={handleNewConsent}
        disabled={
          loading || !AbhaAddress || !hiuId || !docIdentifierData?.value
        }
        loading={loading}
      >
        Add Consent
      </LoadingButton>
      {open && (
        <UpdateAbhaAddress
          open
          setOpen={setOpen}
          patient_data={patient_data}
          setError={setVerifyAbhaError}
        />
      )}
    </Stack>
  );
};

export const UpdateAbhaAddress = ({
  open,
  setOpen,
  patient_data,
  setError,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [patientData, setPatientData] = useState(patient_data);
  const AbhaAddress = patientData?.patient?.identifier?.filter(
    (item) => item?.type?.text === "ABHAADDRESS"
  )[0]?.value;

  const handleChangeAbhaAddress = (e) => {
    const { value } = e.target;
    setPatientData({
      ...patientData,
      patient: {
        ...patientData?.patient,
        identifier: [
          ...patientData?.patient?.identifier.filter(
            (item) => item.type.text !== "ABHAADDRESS"
          ),
          {
            system: "https://healthid.ndhm.gov.in",
            type: {
              coding: [
                {
                  system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                  version: "",
                  code: "MR",
                  display: "Medical record number",
                  userSelected: false,
                },
              ],
              text: "ABHAADDRESS",
            },
            value,
          },
        ],
      },
    });
  };
  const { token } = useSelector((state) => state?.login);
  const handleUpdateAbhaAddress = async () => {
    setLoading(true);

    try {
      const abhaToken = await getAbhaSessionToken();
      const verifyAbhaAddressJson = await verifyABHAADDRESS(abhaToken);

      if (
        !(
          verifyAbhaAddressJson?.data?.status &&
          verifyAbhaAddressJson?.data?.status === "ACTIVE"
        )
      ) {
        showToast(
          "error",
          "ABHA Address is not active or Invalid Abha Address. Please update your ABHA Address"
        );
        return;
      }
      const response = await fetch(
        URL?.updatePatientAbhaAddress(patientData?._id),
        {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(
            patientData?.identifier?.map((item) => {
              delete item?.period;
              return { ...item };
            })
          ),
        }
      );
      const data = await response.json();
      if (data?.status === "success") {
        dispatch(addPatientData(patientData));
        setOpen(false);
        setError("");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <TextField
          id="outlined-basic"
          label="ABHA Address"
          variant="outlined"
          value={AbhaAddress}
          onChange={handleChangeAbhaAddress}
        />
        <LoadingButton
          disabled={loading}
          loading={loading}
          variant="contained"
          sx={{ alignSelf: "center" }}
          onClick={handleUpdateAbhaAddress}
        >
          Update
        </LoadingButton>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: isDesktopOrLaptop ? "auto" : "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  // height: `100%`,
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  gap: 1,
  overflowY: "auto",
};
