import {
  Avatar,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CUSTOMCOLORS } from "../styles";
import { isDesktopOrLaptop, isSmallMobile, StoreToken } from "../utility/const";
import logo from "../assets/logo2.png";
import { LoadingButton } from "@mui/lab";
import { Dropdown } from "../components/Dropdown";
import { URL } from "../utility/urls";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const OrganizationSelect = () => {
  const { phoneNumber, token, profile_id, email } = useSelector(
    (state) => state.login
  );
  const nav = useNavigate();
  const [org, setOrg] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState();
  const fetchUserOrg = async () => {
    try {
      const response = await fetch(URL.getUsers(phoneNumber), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const jsonData = await response.json();
      if (jsonData?.status === "success") {
        const organizationReference =
          jsonData?.data[0]?.organization_reference || [];
        const disabledOrganizations =
          jsonData?.data[0]?.disabledOrganizations || [];
        const visibleOrgs = organizationReference.filter(
          (org) => !disabledOrganizations.includes(org.reference)
        );
        setOrg(visibleOrgs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUserOrg();
  }, []);

  const handleSubmit = () => {
    if (selectedOrg) {
      StoreToken("SelectedOrg", selectedOrg);
    }
    nav("/dashboard");
  };

  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100dvh",
        backgroundColor: "#EDF6FF",
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        justifyContent={"center"}
        gap={8}
        sx={{
          background: CUSTOMCOLORS.white,
          borderRadius: 8,
          boxShadow: "0px 0px 8px 0px #0000004A",
        }}
      >
        <Stack
          gap={8}
          width={1}
          p={isDesktopOrLaptop ? 16 : isSmallMobile ? 2 : 8}
        >
          <Avatar
            sx={{
              alignSelf: "center",
              width: 313,
              height: 80,
              borderRadius: 1,
            }}
            src={logo}
          />

          <form onSubmit={handleSubmit}>
            <Stack gap={4} width={1}>
              <Dropdown
                label="Select Organization"
                options={org}
                required
                pr_name="display"
                value={selectedOrg}
                setValue={setSelectedOrg}
              />
              <LoadingButton
                sx={{
                  alignSelf: "center",
                }}
                type="submit"
                variant="contained"
                size="large"
                // loading={!sendOtp ? phoneLoading : otpLoading}
              >
                {"Submit"}
              </LoadingButton>
            </Stack>
          </form>
        </Stack>
      </Box>
    </Stack>
  );
};

export default OrganizationSelect;
