import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CONSTANTS, imgtobase64, isDesktopOrLaptop } from "../utility/const";
import { systems, URL } from "../utility/urls";
import { addPatientData } from "../redux/features/patientData";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ImagePicker } from "./imgPicker";
import { showToast } from "../utility/toast";
import { RadioButton } from "./RadioBtn";
import { Dropdown } from "./Dropdown";
import dayjs from "dayjs";
import { PatientCheckModal } from "./PatientChkModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isDesktopOrLaptop ? "40%" : "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "90%",
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  gap: 1.5,
  overflowY: "auto",
};

export const PatientCreate = ({ open, onClose, data, setFlow }) => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.login);
  const { selectedDoctor } = useSelector((state) => state?.user);
  const { organization } = useSelector((state) => state?.user);

  const org_data = useSelector(
    (state) => state?.organization?.organization_data
  );
  const [selectImage, setSelectImage] = useState({
    data: "",
    type: "",
    name: "",
  });
  const [salut, setSalutation] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [blood, setBlood] = useState("");
  const [spouseGrp, setspouseGrp] = useState("");
  const [relation, setRelation] = useState("");
  const [relName, setRelName] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setpincode] = useState("");
  const [aadhaar, setAadhaar] = useState("");
  const [find, setfind] = useState("");
  const [dob, setDob] = useState("");

  const [customFields, setCustomFields] = useState([]);

  const [customData, setCustomData] = useState({});
  const [spouse_age, setSpouse_Age] = useState("");
  useEffect(() => {
    if (data) {
      const patient_data = data?.patient;
      setSelectImage({
        type: patient_data?.photo?.contentType,
        data: patient_data?.photo?.data,
        name: "",
      });
      setSalutation(patient_data?.name[0]?.prefix[0]);
      setName(patient_data?.name[0]?.text);
      setPhone(patient_data?.telecom[0]?.value);
      let current = new Date().getFullYear();
      const newdata = current - new Date(patient_data?.birthDate).getFullYear();
      setAge(newdata);
      setGender(patient_data?.gender);
      setRelation(patient_data?.contact[0]?.relationship[0]?.coding[0].display);
      setRelName(patient_data?.contact[0]?.name?.text);
      setAddress(patient_data?.address[0]?.city);
      setpincode(patient_data?.address[0]?.postalCode);
      setBlood(data?.blood_group);
      setspouseGrp(data?.spouse_blood);
      setfind(data?.find_us[0]);
      setCustomData(data?.meta_data);
    }
  }, [data]);

  const handleNumericInput = (input) => {
    const numericValue = input.replace(/[^0-9]/g, "");
    setPhone(numericValue);
  };

  const handleUploadImage = async (img) => {
    try {
      const base64Data = await imgtobase64(img);
      setSelectImage({
        data: base64Data?.base64,
        type: base64Data?.type,
        name: base64Data?.name,
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const HandleBlood = (val) => {
    setBlood(val);
  };
  const HandleSpouseGroup = (val) => {
    setspouseGrp(val);
  };
  const HandleFindUs = (val) => {
    setfind(val);
  };
  const [chkPatientModal, setChkPatientModal] = useState(false);
  const [existPatientData, setExistPatientData] = useState([]);

  const HandleAgeCheck = () => {
    if (parseInt(age) > 0) {
      let date = new Date().getFullYear();
      const current = date - parseInt(age);
      setDob(`${current}-${"01"}-${"01"}`);
    } else {
      setDob("");
    }
  };
  const refId = (customdata) => {
    let midStr = "";
    const Id = `${name?.slice(0, 2).toUpperCase()}`;
    const values = Object.values(customdata);

    if (values.length >= 2) {
      midStr = `${values[0][0]}${values[1][0]}`;
    } else if (values.length === 1) {
      midStr = `${values[0][0]}`;
    }
    const ref_id = data?.reference_ids?.filter(
      (item) =>
        item?.organization_id === organization?.reference ||
        !item?.organization_id
    )[0]["reference_id"];
    if (ref_id) {
      return ref_id;
    }
    return `${Id}${midStr?.toUpperCase()}${dayjs(new Date()).format(
      "DDMMYYYY"
    )}_${gender?.slice(0, 1)?.toUpperCase()}_${new Date()
      .getTime()
      ?.toString()
      ?.slice(-3)}`;
  };

  const checkPatient = async () => {
    try {
      const response = await fetch(URL.chekPatientWithMetaData, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dob: dayjs(dob).format("YYYY-MM-DD"),
          name: name,
          phoneNumber: phone,
          gender: gender,
        }),
      });
      const jsonData = await response.json();
      console.log(jsonData);
      if (jsonData?.status === "success") {
        if (jsonData?.data?.length > 0) {
          setExistPatientData(jsonData?.data);
          setChkPatientModal(true);
          return true;
        }
      }
    } catch {
      return false;
    }
    return false;
  };
  const [loading, setLoading] = useState(false);
  const Addpatient = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      // const chkPatient = await checkPatient();
      // if (chkPatient) {
      //   setLoading(false);
      //   return;
      // }
      const response = await fetch(URL.createPatient, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          patient: {
            patient: {
              resourceType: "Patient",
              identifier: [
                {
                  type: {
                    coding: [
                      {
                        system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                        code: "MR",
                        display: "Medical record number",
                      },
                    ],
                  },
                  system: "https://healthid.ndhm.gov.in",
                  value: refId(customData),
                },
              ],
              active: true,
              name: [
                {
                  text: name,
                  prefix: [salut],
                },
              ],
              telecom: [
                {
                  system: "phone",
                  value: phone,
                },
              ],
              gender: gender,
              birthDate: dob,
              address: [
                {
                  use: "home",
                  code: "postal",
                  city: address,
                  postalCode: pincode,
                },
              ],
              photo: {
                contentType: selectImage?.type,
                data: selectImage?.data,
              },
              contact: [
                {
                  relationship: [
                    {
                      coding: [
                        {
                          system: systems.snomed,
                          code:
                            relation === "Father"
                              ? "66839005"
                              : "Husband"
                              ? "127849001"
                              : "",
                          display:
                            relation === "Father"
                              ? "Father"
                              : "Husband"
                              ? "Husband"
                              : "",
                        },
                      ],
                    },
                  ],
                  name: {
                    use: "official",
                    text: relName,
                  },
                },
              ],
              generalPractitioner: [
                {
                  reference: organization?.reference,
                  display: organization?.display,
                },
              ],
              managingOrganization: {
                reference: organization?.reference,
                display: organization?.display,
              },
            },
            reference_ids: [
              {
                reference_id: refId(customData),
                organization_id: organization?.reference,
              },
            ],
            blood_group: blood,
            spouse_blood: spouseGrp,
            spouse_age,
            find_us: [find],
          },
          patientMetadata: {
            meta_data: customData,
          },
        }),
      });
      const jsonData = await response.json();
      if (jsonData?.status === "success") {
        dispatch(addPatientData(jsonData?.data));
        handleFlow();
        // nav("/addPatient");
        onClose();
        showToast("success", "Successfully created");
      } else {
        showToast("error", jsonData?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const Updatepatient = async (e) => {
    e.preventDefault();

    const response = await fetch(URL.updatepatient(data?._id), {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        patient: {
          patient: {
            resourceType: "Patient",
            identifier: [
              {
                type: {
                  coding: [
                    {
                      system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                      code: "MR",
                      display: "Medical record number",
                    },
                  ],
                },
                system: "https://healthid.ndhm.gov.in",
                value: refId(customData),
              },
            ],
            active: true,
            name: [
              {
                text: name,
                prefix: [salut],
              },
            ],
            telecom: [
              {
                system: "phone",
                value: phone,
              },
            ],
            gender: gender,
            birthDate: dob,
            address: [
              {
                use: "home",
                code: "postal",
                city: address,
                postalCode: pincode,
              },
            ],
            photo: {
              contentType: selectImage?.type,
              data: selectImage?.data,
            },
            contact: [
              {
                relationship: [
                  {
                    coding: [
                      {
                        system: systems.snomed,
                        code:
                          relation === "Father"
                            ? "66839005"
                            : "Husband"
                            ? "127849001"
                            : "",
                        display:
                          relation === "Father"
                            ? "Father"
                            : "Husband"
                            ? "Husband"
                            : "",
                      },
                    ],
                  },
                ],
                name: {
                  use: "official",
                  text: relName,
                },
              },
            ],
            generalPractitioner: [
              {
                reference: organization?.reference,
                display: organization?.display,
              },
            ],
            managingOrganization: {
              reference: organization?.reference,
              display: organization?.display,
            },
          },
          reference_ids: [
            {
              reference_id: refId(customData),
              organization_id: organization?.reference,
            },
          ],
          blood_group: blood,
          spouse_blood: spouseGrp,
          spouse_age,
          find_us: [find],
        },
        patientMetadata: {
          meta_data: customData,
        },
      }),
    });
    const jsonData = await response.json();
    if (jsonData?.status === "success") {
      handleFlow();
      // nav("/patients");
      setSelectImage({
        type: "",
        data: "",
        name: "",
      });
      setSalutation("");
      setName("");
      setPhone("");
      setAge("");
      setGender("");
      setRelation("");
      setRelName("");
      setAddress("");
      setpincode("");
      onClose();
      showToast("success", "Successfully updated");
    } else {
      showToast("error", jsonData?.message);
    }
  };

  useEffect(() => {
    HandleAgeCheck();
  }, [age]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          URL.getCustomFields(org_data?.profile_id),
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const jsonData = await response.json();
        if (jsonData?.status === "success") {
          setCustomFields(jsonData?.data || []);
        }
      } catch (error) {}
    };
    fetchData();
  }, []);

  const handleCustomData = (itemName, val) => {
    setCustomData((prev) => ({
      ...prev,
      [itemName]: val,
    }));
  };
  const handleFlow = () => {
    setFlow && setFlow(true);
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (data?._id) {
            Updatepatient(e);
          } else {
            Addpatient(e);
          }
        }}
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            fontSize={18}
            fontWeight={600}
            fontFamily={"Open sans"}
          >
            Add Patient
          </Typography>
          <ImagePicker
            setValue={(e) => handleUploadImage(e.target.files[0])}
            data={selectImage?.data}
          />

          <Stack flexDirection={"row"} gap={2.5}>
            <Stack width={"25%"}>
              <Dropdown
                size="small"
                label="Salutations"
                value={salut}
                setValue={(newvalue) => {
                  if (newvalue?.toLowerCase() === "mr") {
                    setGender("male");
                  } else if (
                    newvalue?.toLowerCase() === "mrs" ||
                    newvalue?.toLowerCase() === "ms"
                  ) {
                    setGender("female");
                  }

                  setSalutation(newvalue);
                }}
                options={CONSTANTS.saluatation}
              />
            </Stack>
            <Stack width={"75%"}>
              <TextField
                required={true}
                label="Name"
                size="small"
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputLabelProps={{
                  style: {
                    fontSize: 14,
                    fontWeight: 500,
                    fontFamily: "Open sans",
                  },
                }}
              />
            </Stack>
          </Stack>
          <TextField
            // required={true}
            size="small"
            label="Phone Number"
            value={phone}
            onChange={(e) => handleNumericInput(e.target.value)}
            InputLabelProps={{
              style: {
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Open sans",
              },
            }}
            inputProps={{ maxLength: 10 }}
          />
          <TextField
            required={true}
            size="small"
            label="Age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            InputLabelProps={{
              style: {
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Open sans",
              },
            }}
          />
          <RadioButton
            required
            label="Gender"
            data={["male", "female", "other"]}
            value={gender}
            setValue={setGender}
          />
          {selectedDoctor?.role === "doctor" && (
            <>
              <Stack gap={0.5}>
                <Typography variant="subtitle1">Blood Group</Typography>
                <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                  {CONSTANTS.blood_Groups?.map((item, index) => (
                    <Button
                      key={index}
                      variant="outlined"
                      sx={{
                        minWidth: "auto",
                        backgroundColor: blood === item ? "#3683CC" : "white",
                        color: blood === item ? "white" : "#4ba5fa",
                        "&:hover": {
                          backgroundColor: "#3683CC",
                          color: "white",
                        },
                      }}
                      size="small"
                      onClick={() => HandleBlood(item)}
                    >
                      {item}
                    </Button>
                  ))}
                </Stack>
              </Stack>
              <Stack gap={0.5}>
                <RadioButton
                  label="Relation Name"
                  data={["Father", "Husband"]}
                  value={relation}
                  setValue={setRelation}
                />
                {relation !== "" && (
                  <>
                    <OutlinedInput
                      size="small"
                      placeholder={
                        relation === "Father"
                          ? "Enter father name"
                          : "Enter husband name"
                      }
                      value={relName}
                      onChange={(e) => setRelName(e.target.value)}
                    />
                    <TextField
                      size="small"
                      label="Age"
                      value={spouse_age}
                      onChange={(e) => setSpouse_Age(e.target.value)}
                      InputLabelProps={{
                        style: {
                          fontSize: 14,
                          fontWeight: 500,
                          fontFamily: "Open sans",
                        },
                      }}
                    />
                  </>
                )}
              </Stack>
              <Stack gap={0.5}>
                <Typography variant="subtitle1">Spouse Blood Group</Typography>
                <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                  {CONSTANTS.blood_Groups?.map((item, index) => (
                    <Button
                      key={index}
                      variant="outlined"
                      sx={{
                        minWidth: "auto",
                        backgroundColor:
                          spouseGrp === item ? "#3683CC" : "white",
                        color: spouseGrp === item ? "white" : "#4ba5fa",
                        "&:hover": {
                          backgroundColor: "#3683CC",
                          color: "white",
                        },
                      }}
                      size="small"
                      onClick={() => HandleSpouseGroup(item)}
                    >
                      {item}
                    </Button>
                  ))}
                </Stack>
              </Stack>
            </>
          )}
          <TextField
            required={true}
            size="small"
            label="Address / Locality"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            InputLabelProps={{
              style: {
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Open sans",
              },
            }}
          />
          <TextField
            size="small"
            label="Pin code"
            value={pincode}
            onChange={(e) => setpincode(e.target.value)}
            InputLabelProps={{
              style: {
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Open sans",
              },
            }}
          />
          <TextField
            size="small"
            label="Aadhaar Number"
            value={aadhaar}
            onChange={(e) => setAadhaar(e.target.value)}
            InputLabelProps={{
              style: {
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Open sans",
              },
            }}
          />
          <Stack gap={0.5}>
            <Typography variant="subtitle1">How did you find us?</Typography>
            <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
              {CONSTANTS.find_us?.map((item, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  sx={{
                    minWidth: "auto",
                    backgroundColor: find === item ? "#3683CC" : "white",
                    color: find === item ? "white" : "#4ba5fa",
                    "&:hover": {
                      backgroundColor: "#3683CC",
                      color: "white",
                    },
                  }}
                  size="small"
                  onClick={() => HandleFindUs(item)}
                >
                  {item}
                </Button>
              ))}
            </Stack>
          </Stack>
          {customFields?.length > 0 &&
            customFields?.map((item, ind) => (
              <TextField
                key={ind}
                label={item?.name}
                placeholder={item?.name}
                type={item?.field_type}
                select={item?.field_type === "dropdown" ? true : false}
                value={customData[item?.name]}
                onChange={(e) => handleCustomData(item?.name, e.target.value)}
              >
                {item?.field_type === "dropdown" &&
                  item?.field_value?.map((val) => (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  ))}
              </TextField>
            ))}
          <Stack alignSelf={"center"} mt={4}>
            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              sx={{ backgroundColor: "#4ba5fa" }}
            >
              SAVE
            </Button>
          </Stack>
        </Box>
        {chkPatientModal && (
          <PatientCheckModal
            open={chkPatientModal}
            onClose={setChkPatientModal}
            data={existPatientData}
          />
        )}
      </form>
    </Modal>
  );
};
