import Spinner from 'react-activity/dist/Spinner';
import 'react-activity/dist/Spinner.css';
import React from 'react';
export const HButton = React.memo(
  ({ loading, type, onClick, label = '', style,}) => {
    return (
      <button
        style={style}
        className='font-open_sans  font-semibold text-xs text-white flex items-center justify-center bg-blue-400 py-2 md:py-[12px] px-[16px] rounded gap-2 cursor-pointer'
        type={type ? type : 'submit'}
        onClick={onClick}
      >
        {loading && (
          <Spinner
            size={12}
            color='#fff'
          />
        )}
        {label?.toUpperCase()}
      </button>
    );
  }
);
