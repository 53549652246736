import { URL } from "../urls";

export const getAbhaSessionToken = async () => {
  try {
    const sessions = await fetch(URL.getAbhaSessionToken, {
      method: "GET",
    });
    const sessiondata = await sessions.json();
    return sessiondata;
  } catch (error) {
    return error;
  }
};

export const AbhaDocumentTypes = [
  "Prescription",
  "DiagnosticReport",
  "DischargeSummary",
  "ImmunizationRecord",
  "HealthDocumentRecord",
  "WellnessRecord",
  "OPConsultation",
];

export const AbhaConsentPurpose = [
  {
    text: "Care Management",
    code: "CAREMGT",
    refUri: "www.abdm.gov.in",
  },
  {
    text: "Break the Glass",
    code: "BTG",
    refUri: "www.abdm.gov.in",
  },
  {
    text: "Public Health",
    code: "PUBHLTH",
    refUri: "www.abdm.gov.in",
  },
  {
    text: "Healthcare Payment",
    code: "HPAYMT",
    refUri: "www.abdm.gov.in",
  },
  {
    text: "Disease Specific Healthcare Research",
    code: "DSRCH",
    refUri: "www.abdm.gov.in",
  },
  {
    text: "Self Requested",
    code: "PATRQT",
    refUri: "www.abdm.gov.in",
  },
];
