import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { imgtobase64, isDesktopOrLaptop, ismobile } from "../utility/const";
import { useSelector, useDispatch } from "react-redux";
import { showToast } from "../utility/toast";
import { addReportFindings } from "../redux/features/consultation/reportFindings";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import { FileShowChip } from "./fileShowChip";
import { AddCircle } from "@mui/icons-material";
import { URL } from "../utility/urls";
import { CUSTOMCOLORS } from "../styles";
import FileViewer from "./FileViewer";
import { IoMdClose } from "react-icons/io";
import { Picker } from "./Picker";
import dayjs from "dayjs";

export const ReportFindings = (props) => {
  const dispatch = useDispatch();
  const { dr_ref, pt_ref, app_id, org_ref } = props.app_data;
  const { reportFindings } = useSelector((state) => state.reports);
  const { token } = useSelector((state) => state.login);
  // let { complaint_update, vitals_update } = useSelector(
  //   (state) => state.updates
  // );
  const [type, setType] = useState("prescribe");
  const [othersType, setOthersType] = useState("");
  const [date, setDate] = useState(dayjs());
  const [uploaddocument, SetUploadDocument] = useState({
    contentType: "",
    data: "",
    title: "",
  });
  const [description, setDescription] = useState("");

  const handleUploadImage = async (img) => {
    try {
      const base64Data = await imgtobase64(img);
      SetUploadDocument({
        contentType: base64Data?.type,
        title: base64Data?.name,
        data: base64Data?.base64,
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handledata = () => {
    const reports_data = JSON.parse(
      JSON.stringify(reportFindings?.text_data ? reportFindings?.text_data : [])
    );

    dispatch(
      addReportFindings({
        text_data: [
          ...reports_data,
          {
            type: othersType ? othersType : type,
            issuedDate: dayjs(date).format("YYYY-DD-MM"),
            description: description,
            file: {
              contentType: uploaddocument?.contentType,
              data: uploaddocument?.data,
              title: uploaddocument?.title,
            },
          },
        ],
        orgainization_id: org_ref,
        practitioner_id: dr_ref,
        encounter: { appointment: { reference: app_id } },
        patient_id: pt_ref,
      })
    );
    setType("");
    setOthersType("");
    setDate(dayjs());
    SetUploadDocument({
      contentType: "",
      data: "",
      title: "",
    });
    setDescription("");
  };
  const Postdata = async (e) => {
    e.preventDefault();
    const response = await fetch(URL.createReportFindings, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reportFindings),
    });
    const jsonData = await response.json();
    if (jsonData?.status === "success") {
      props.close();
      showToast("success", "succesfully done");
    } else {
      showToast("error", "please try again");
    }
  };
  const fetchReportFindings = async () => {
    const response = await fetch(URL.getReportFindings(app_id), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await response.json();
    jsonData?.data
      ? dispatch(addReportFindings(jsonData?.data))
      : dispatch(addReportFindings(reportFindings));
  };
  useEffect(() => {
    fetchReportFindings();
  }, []);
  return (
    <Modal
      open={props.open}
      onClose={props.close}
      center
      styles={{
        modal: {
          flex: 1,
          width: isDesktopOrLaptop ? "60%" : "90%",
          height: "90%",
          borderRadius: 16,
        },
      }}
    >
      <Stack py={2} spacing={1}>
        <Typography variant="h6">Upload Reports</Typography>
        <Stack spacing={0.5}>
          <Reports data={reportFindings} />
        </Stack>
        <Stack spacing={1}>
          <Box>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{ gap: 0 }}
              >
                <FormControlLabel
                  value="prescribe"
                  control={
                    <Radio
                      checked={type === "prescribe"}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    />
                  }
                  label="Prescribe"
                />

                <FormControlLabel
                  value="blood"
                  control={
                    <Radio
                      checked={type === "blood"}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    />
                  }
                  label="Blood"
                />
                <FormControlLabel
                  value="scan"
                  control={
                    <Radio
                      checked={type === "scan"}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    />
                  }
                  label="Scan"
                />
                <FormControlLabel
                  value="other"
                  control={
                    <Radio
                      checked={type === "other"}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    />
                  }
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Stack gap={1}>
            {type === "other" && (
              <TextField
                value={othersType}
                onChange={(e) => {
                  setOthersType(e.target.value);
                }}
                label={"Report Name"}
              />
            )}
            <Picker value={date} setValue={setDate} />
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Box display={"flex"} justifyContent={"space-between"}>
              <Stack flex={1}>
                {uploaddocument?.data ? (
                  <FileShowChip
                    label={uploaddocument?.title}
                    onClick={() => {
                      SetUploadDocument({
                        contentType: "",
                        data: "",
                        title: "",
                      });
                    }}
                  />
                ) : (
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      alignSelf: "flex-start",
                      backgroundColor: "primary.dark",
                    }}
                  >
                    Upload File
                    <input
                      type="file"
                      hidden
                      accept="image/*,.pdf"
                      onChange={(e) => handleUploadImage(e.target.files[0])}
                    />
                  </Button>
                )}
              </Stack>
              <Stack>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    handledata();
                  }}
                >
                  <AddCircle fontSize="large" />
                </IconButton>
              </Stack>
            </Box>
          </Stack>
        </Stack>
        <Button
          sx={{
            alignSelf: "flex-end",
          }}
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            reportFindings?.text_data?.length > 0
              ? Postdata(e)
              : showToast("error", "Please Add Atleast One ");
          }}
        >
          Save
        </Button>
      </Stack>
    </Modal>
  );
};

const Reports = ({ data }) => {
  const dispatch = useDispatch();
  const { reportFindings } = useSelector((state) => state.reports);
  const handledeleteFiles = (ind) => {
    const filter_data = reportFindings?.text_data?.filter(
      (_, index) => ind !== index
    );
    dispatch(addReportFindings({ ...reportFindings, text_data: filter_data }));
  };
  const ShowChip = ({ data, onClick }) => {
    return (
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        padding={2}
        bgcolor={"primary.light"}
      >
        <Stack spacing={1}>
          <Stack
            display={"flex"}
            flexDirection={"row"}
            gap={0.5}
            flexWrap={"wrap"}
          >
            <Typography variant="caption">Type:</Typography>
            <Typography variant="body2" fontWeight={600}>
              {data?.type}
            </Typography>
            <Typography variant="caption">IssuedDate: </Typography>
            <Typography variant="body2" fontWeight={600}>
              {data?.issuedDate}
            </Typography>
            <Typography variant="caption">Description: </Typography>
            <Typography variant="body2" fontWeight={600}>
              {data?.description}
            </Typography>
          </Stack>
          <Typography
            display={"flex"}
            flexDirection={"row"}
            gap={1}
            variant="caption"
            alignItems={"center"}
          >
            File:
            <FileViewer
              base64String={data?.file?.data}
              label={data?.file?.title}
            />
          </Typography>
        </Stack>
        <span
          onClick={(e) => {
            onClick();
          }}
        >
          <IoMdClose color={CUSTOMCOLORS.DeIcon} />
        </span>
      </Stack>
    );
  };
  return (
    <>
      {data?.text_data?.map((item, index) => (
        <ShowChip
          data={item}
          key={index}
          onClick={() => handledeleteFiles(index)}
        />
      ))}
    </>
  );
};
