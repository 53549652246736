import React from 'react';
import { LuCalendarOff } from 'react-icons/lu';

export const IconText = ({ label, icon }) => {
  return (
    <div className='flex flex-col items-center justify-center gap-2'>
      {!icon ? (
        <LuCalendarOff
          color='#4ba5fa'
          size={24}
        />
      ) : (
        icon
      )}
      <p className='font-open_sans font-semibold text-sm'>
        {label ? label : 'No Appointments!'}
      </p>
    </div>
  );
};
