// const baseurl = "http://127.0.0.1:8000/api/v1";
// const baseurl = "http://192.168.101.109:8080/api/v1";
// const baseurl = "http://13.233.158.224/api/v1";
// const snomedUrl = "https://attai.healthattai.com/";

//  development server

// const baseurl = "https://w9sfz3v5mg.ap-south-1.awsapprunner.com/api/v1";

//production server

const baseurl = "https://axcvdbtczi.ap-south-1.awsapprunner.com/api/v1";

export const URL = {
  getNewAccessToken: `${baseurl}/get_new_access_token`,
  fetchApisKeys: `${baseurl}/get_keys`,
  userLogin: `${baseurl}/login`,
  userLogout: `${baseurl}/logout`,
  generateOtp: `${baseurl}/createUserLogin`,
  validateOtp: `${baseurl}/validateOtp`,
  getUsers: (phoneId) => `${baseurl}/userByPhone/${phoneId}`,
  getMetaData: (org_id) => `${baseurl}/get_organization_meta_data/${org_id}`,
  userUpdate: (_id) => `${baseurl}/userUpdateSelf/${_id}`,
  getOrganization: (profile_id) =>
    `${baseurl}/getOrganizationByProfileID/${profile_id}`,
  getAllDoctors: (org_id) => `${baseurl}/getPractitionerData/${org_id}`,
  getPatientOrg: (id, page = 1) =>
    `${baseurl}/oragnization_patients/${id}?page=${page}`,
  getPatientOrgBySearch: (org_id, search, page = 1) =>
    `${baseurl}/oragnization_patients_by_search/${org_id}/${search}?page=${page}`,
  createPatient: `${baseurl}/createPatient`,
  updatepatient: (id) => `${baseurl}/updatePatient/${id}`,
  getRole_IE_Slots: (doc_profile_id) =>
    `${baseurl}/getPractitionerDataRolesForUser/${doc_profile_id}`,
  getBookedSlots: (practitioner_id, date) =>
    `${baseurl}/getBookedSlots/${practitioner_id}/${date}`,
  createAppointment: `${baseurl}/createAppointment`,
  updateAppointment: (app_id) => `${baseurl}/updateAppointment/${app_id}`,
  getNotifyData: (profile_id) => `${baseurl}/getNotifyData/${profile_id}`,
  update_visibility: (id) => `${baseurl}/updateSeenNotifyData/${id}`,
  getAppoitmentById: (id) => `${baseurl}/Appointment/${id}`,
  getAppointments: (practitioner_id, date, page, pat_name, app_type, org_id) =>
    `${baseurl}/getAppointments/${practitioner_id}/${date}/${page}?pat_name=${pat_name}&app_type=${app_type}&org_id=${org_id}`,
  createRecents: `${baseurl}/createRecentlyUsed`,
  updatingRecents: (user_id, type) =>
    `${baseurl}/updateRecntlyUsed/${user_id}/${type}`,
  getRecents: (user_id, type) => `${baseurl}/getRecents/${user_id}/${type}`,
  createComplaints: `${baseurl}/createChiefComplaint`,
  getcomplaints: (id) => `${baseurl}/getChiefComplaint/${id}`,
  delChiefComplaint: (_id) => `${baseurl}/delChiefComplaint/${_id}`,
  createReportFindings: `${baseurl}/createReportFindings`,
  getReportFindings: (id) => `${baseurl}/getReportFindings/${id}`,
  createFees: `${baseurl}/createFees`,
  getFees: (app_id) => `${baseurl}/getFees/${app_id}`,
  createVitals: `${baseurl}/createVitals`,
  getVitalsByAppId: (app_id) => `${baseurl}/getVitalsbyApp_id/${app_id}`,
  updateVitals: (id) => `${baseurl}/updateVitals/${id}`,
  getFcmTokens: (profile_id) => `${baseurl}/getFcmTokens/${profile_id}`,
  postFcmtokem: `${baseurl}/postFcmToken`,
  postNotifyData: `${baseurl}/savingNotifications`,
  sendNotification: "https://fcm.googleapis.com/fcm/send",
  updateStatusAppointment: (app_id) =>
    `${baseurl}/updateAppointmentStatus/${app_id}`,
  getPrescriptionData: (app_id) => `${baseurl}/getPrescriptionData/${app_id}`,
  snomed: (value, option) => {
    return `${baseurl}/getSnomed?term=${value}&semanticTags=${option}`;
  },
  // snomed: (value, option) =>
  // `https://attai.healthattai.com/csnoserv/api/search/search?term=${value}&state=active&semantictag=${option}&acceptability=preferred&returnlimit=80`,
  getAbhaSessionToken: `${baseurl}/getABHASessions`,
  createAbhaWithAadhaar: `${baseurl}/createAbhaWithAADHAAR`,
  verifyAadhaarOtp: `${baseurl}/verifyAadhaarOTP`,
  sendMobileOtp: `${baseurl}/sendMobileOtp`,
  verifyMobileOtp: `${baseurl}/verifyMobileOtp`,
  verifyAbhaAddress: `${baseurl}/verifyAbhaAddress`,
  verifyAbhaAddressOtp: `${baseurl}/verifyAbhaAddressOtp`,
  getAbhaAccount: `${baseurl}/getAbhaAccount`,
  getAbhaCrad: `${baseurl}/getAbhaCard`,
  getXToken: `${baseurl}/getX-Token`,
  sendEmailVerification: `${baseurl}/sendEmailVerificationLink`,
  getAbhaSuggestions: `${baseurl}/getAbhaSuggestions`,
  createAbhaAdress: `${baseurl}/createAbhaAddress`,
  getPatientInit: (abha_id, dob, name) =>
    `${baseurl}/patientInit?abha_id=${abha_id}&dob=${dob}&name=${name}`,
  getFeess: (profile_id) => `${baseurl}/getConsultationFees/${profile_id}`,
  getCustomFields: (org_id) => `${baseurl}/getPatientCustomFields/${org_id}`,
  change_password: `${baseurl}/update_password`,
  followups: (org_id, date) =>
    `${baseurl}/getFollowUpReminders?org_id=${org_id}&date=${date}`,
  exportdata: (org_id, start_date, end_date, prac_id = "") =>
    `${baseurl}/data/exportBasedOrgId/${org_id}/${start_date}/${end_date}?practitioner_id=${prac_id}`,
  exportTimeStamps: `${baseurl}/getExportTimestamps`,

  //m3 apis

  m3VeifyAbhaAddress: `${baseurl}/m3/verify/abhaAddress`,
  addNewConsent: `${baseurl}/m3/consent/request`,
  updatePatientAbhaAddress: (id) => `${baseurl}/updateAbhaAddress/${id}`,
  chekPatientWithMetaData: `${baseurl}/checkPatientDemographs`,
  addOrgToPatient: (id) => `${baseurl}/addOrgToPatient/${id}`,
};

export const systems = {
  snomed: "http://snomed.info/sct",
};
