import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { URL } from "../utility/urls";
import { useNavigate } from "react-router-dom";
import {
  addDoctors,
  addOrganization,
  addSelectedDoctor,
  addUserData,
} from "../redux/features/userData";
import { Dropdown } from "../components/Dropdown";
import { AppointmentCount } from "../components/AppointmentCount";
import { AppointmentCard } from "../components/appointmentcard";
import { IconText } from "../components/icon";
import {
  handleDoctorname,
  isDesktopOrLaptop,
  ismobile,
  isSmallMobile,
  istab,
  RetriveToken,
} from "../utility/const";
import {
  addDoctor_fcmTokens,
  addServerFCMAPI,
} from "../redux/features/fcmTokens";
import { GetFcmTokenDoc } from "../utility/notifications";
import { Loading } from "../components/Loading";
import { Box, Button, Stack } from "@mui/material";
import dayjs from "dayjs";
import { Picker } from "../components/Picker";
import { addOrganizationData } from "../redux/features/organizationData";
import { addWtsKeys } from "../redux/features/wtsKeys";
import ambulance from "../assets/ambulance.gif";

export const DashBoard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selctedOrg = RetriveToken("SelectedOrg");

  const { refreshVar } = useSelector((state) => state?.appointment);
  const { phoneNumber, token, profile_id, email } = useSelector(
    (state) => state.login
  );
  const { organization } = useSelector((state) => state?.user);
  const { organization_data } = useSelector((state) => state?.organization);

  const { doctors, selectedDoctor } = useSelector((state) => state?.user);
  const { fcmtoken } = useSelector((state) => state?.fcms);
  const [Date, setDate] = useState(dayjs());
  const date = Date?.toISOString()?.split("T")[0];
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [countApps, setCountApps] = useState({
    total_apps: 0,
    pending_apps: 0,
    completed_apps: 0,
  });
  const [appointments, setAppointments] = useState([]);
  const fetchUserData = async () => {
    const response = await fetch(URL.getUsers(phoneNumber), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const jsonData = await response.json();
      console.log(jsonData);
      const filterOrg = jsonData?.data?.disabledOrganizations?.filter(
        (item) => item !== selctedOrg?.reference
      );
      console.log(filterOrg);
      dispatch(addUserData(jsonData?.data));
      dispatch(addOrganization(selctedOrg));
      //   const org_response = await fetch(
      //     URL.getOrganization(
      //       jsonData?.data[0]?.organization_reference?.reference
      //     ),
      //     {
      //       method: "GET",
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //       },
      //     }
      //   );
      //   const org_jsonData = await org_response.json();
      //   dispatch(
      //     addOrganizationData({
      //       ...org_jsonData?.data,
      //       organization: { ...org_jsonData?.data?.organization, contact: {} },
      //     })
      //   );
      //   const doctorsData = await fetch(
      //     URL.getAllDoctors(jsonData?.data[0]?.organization_reference?.reference),
      //     {
      //       method: "GET",
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //       },
      //     }
      //   );
      //   if (response.ok) {
      //     const docjson = await doctorsData.json();

      //     const docsData =
      //       docjson?.data?.length > 0 &&
      //       docjson?.data?.map((item) => {
      //         const doc_name = handleDoctorname(
      //           item?.profile?.practitioner?.name
      //         );
      //         const doc_phone = item?.profile?.practitioner?.telecom?.[0]?.value;
      //         const reference = item?.profile?.profile_id;
      //         const type = "practitioner";
      //         const display = doc_name;
      //         const fees = item?.profile?.consultation_fees;
      //         const payment_code = item?.profile?.payment_code;
      //         const role = item?.role?.[0]?.role?.code?.[0]?.coding?.[0]?.code;
      //         const specialty = item?.role
      //           ?.map((item) => item?.role?.specialty)
      //           ?.flat();
      //         return {
      //           type,
      //           reference,
      //           display,
      //           fees,
      //           doc_phone,
      //           payment_code,
      //           role,
      //           specialty,
      //         };
      //       });
      //     dispatch(addDoctors(docsData));
      //     dispatch(addSelectedDoctor(docsData[0]));
      //     const fcmTokens = await GetFcmTokenDoc(docsData[0]?.reference);
      //     const doc_tokens = fcmTokens
      //       ? fcmTokens?.map((item) => item?.fcmtoken)
      //       : [];
      //     dispatch(addDoctor_fcmTokens(doc_tokens));
      //   }
    }
    const ApiKeyresponse = await fetch(URL.fetchApisKeys, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonData = await ApiKeyresponse.json();
    dispatch(addServerFCMAPI(jsonData?.data?.FcmToken));
    dispatch(addWtsKeys(jsonData?.data));
  };
  const fetchData = async () => {
    const org_response = await fetch(
      URL.getOrganization(selctedOrg?.reference),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const org_jsonData = await org_response.json();
    dispatch(
      addOrganizationData({
        ...org_jsonData?.data,
        organization: { ...org_jsonData?.data?.organization, contact: {} },
      })
    );
    const doctorsData = await fetch(URL.getAllDoctors(selctedOrg?.reference), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // if (response.ok) {
    const docjson = await doctorsData.json();
    const docsData =
      docjson?.data?.length > 0 &&
      docjson?.data?.map((item) => {
        const doc_name = handleDoctorname(item?.profile?.practitioner?.name);
        const doc_identifier = item?.profile?.practitioner?.identifier;
        const profile_id = item?.profile?._id;
        const medicalRegestrationNumber =
          item?.profile?.medicalRegestrationNumber;
        const doc_phone = item?.profile?.practitioner?.telecom?.[0]?.value;
        const reference = item?.profile?.profile_id;
        const type = "practitioner";
        const display = doc_name;
        const fees = item?.profile?.consultation_fees;
        const payment_code = item?.profile?.payment_code;
        const role = item?.role?.[0]?.role?.code?.[0]?.coding?.[0]?.code;
        const specialty = item?.role
          ?.map((item) => item?.role?.specialty)
          ?.flat();
        return {
          type,
          reference,
          display,
          fees,
          doc_phone,
          payment_code,
          role,
          specialty,
          doc_identifier,
          profile_id,
          medicalRegestrationNumber,
        };
      });
    console.log(docsData);

    dispatch(addDoctors(docsData));
    dispatch(addSelectedDoctor(docsData[0]));
    const fcmTokens = await GetFcmTokenDoc(docsData[0]?.reference);
    const doc_tokens = fcmTokens
      ? fcmTokens?.map((item) => item?.fcmtoken)
      : [];
    dispatch(addDoctor_fcmTokens(doc_tokens));
    // }
  };

  let load = false;
  useEffect(() => {
    const postFcmToken = async () => {
      await fetch(URL.postFcmtokem, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fcmtoken: fcmtoken,
          user_id: profile_id,
        }),
      });
    };
    if (!load) {
      postFcmToken();
      fetchUserData();
      fetchData();
    }
    load = true;
  }, []);
  const fetchAppointments = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        URL.getAppointments(
          selectedDoctor?.reference,
          date,
          page,
          "",
          "",
          organization?.reference
        ),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const jsonData = await response.json();
      setAppointments(
        jsonData?.data?.apps_data ? jsonData?.data?.apps_data : []
      );

      setCountApps({
        total_apps: jsonData?.data?.total_apps,
        pending_apps: jsonData?.data?.pending_apps,
        completed_apps: jsonData?.data?.completed_apps,
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAppointments();
  }, [date, selectedDoctor, refreshVar, page, organization?.reference]);
  const handleDoctorSelect = async (item) => {
    dispatch(addSelectedDoctor(item));
    const fcmTokens = await GetFcmTokenDoc(item?.reference);
    const doc_tokens = fcmTokens
      ? fcmTokens?.map((item) => item?.fcmtoken)
      : [];
    dispatch(addDoctor_fcmTokens(doc_tokens));
  };

  return (
    <Stack display="flex" flexDirection={"column"} gap={1} zIndex={4} width={1}>
      <Box display={"flex"} flexDirection={"row"} gap={1.5}>
        <Stack width={isDesktopOrLaptop || istab ? "75%" : 1}>
          <Dropdown
            pr_name="display"
            options={doctors}
            value={selectedDoctor}
            setValue={handleDoctorSelect}
            doc={true}
          />
        </Stack>
        <Stack>
          <Picker value={Date} setValue={setDate} />
        </Stack>
      </Box>
      <Box display={"flex"} gap={1.5} alignItems={"center"}>
        <Stack
          display={"flex"}
          direction={"row"}
          gap={2}
          width={ismobile || isSmallMobile ? 1 : "75%"}
        >
          <AppointmentCount
            label={"Total"}
            count={countApps?.total_apps}
            style={{ backgroundColor: "#EAF3FC", color: "#4ba5fa" }}
            color={"#4ba5fa"}
          />
          <AppointmentCount
            label={"Completed"}
            count={countApps?.completed_apps}
            style={{ backgroundColor: "#DEFDD9", color: "#2CBB15" }}
            color="#2CBB15"
          />
          <AppointmentCount
            label={"Pending"}
            count={countApps?.pending_apps}
            style={{ backgroundColor: "#FFF3DB", color: "#FFA800" }}
            color="#FFA800"
          />
        </Stack>
        {(isDesktopOrLaptop || istab) && (
          <Stack>
            <Button
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                navigate("/addPatient");
              }}
              size={isDesktopOrLaptop ? "large" : "medium"}
              color="success"
              sx={{
                "&:hover": {
                  backgroundColor: "#23A330",
                },
              }}
            >
              {selectedDoctor?.role === "doctor"
                ? "Book Appointment"
                : "Search Patient"}
            </Button>
          </Stack>
        )}
      </Box>
      {(ismobile || isSmallMobile) && (
        <Stack>
          <Button
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              navigate("/addPatient");
            }}
            size="small"
            color="success"
            sx={{
              "&:hover": {
                backgroundColor: "#23A330",
              },
              alignSelf: "end",
            }}
          >
            Book Appointment
          </Button>
        </Stack>
      )}
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <>
          {loading ? (
            <Loading />
          ) : appointments?.length > 0 ? (
            appointments?.map((item) => (
              <AppointmentCard key={item} appointment_data={item} />
            ))
          ) : (
            <IconText />
          )}
        </>
      </Box>
      {organization_data?.ambulanceNo && (
        <a
          href={`tel:+${organization_data?.ambulanceNo}`}
          style={{ position: "fixed", bottom: 0, right: 0, padding: 8 }}
        >
          <Box
            src={ambulance}
            component={"img"}
            sx={{
              width: isDesktopOrLaptop || istab ? 56 : 42,
              height: isDesktopOrLaptop || istab ? 56 : 42,
            }}
          />
        </a>
      )}
    </Stack>
  );
};
