import { useSelector, useDispatch } from "react-redux";
import { SearchInput } from "../components/SearchBar";
import { Dropdown } from "../components/Dropdown";
import {
  CONSTANTS,
  capitalizeWord,
  isDesktopOrLaptop,
  isSmallMobile,
  istab,
} from "../utility/const";
import { useEffect, useState } from "react";
import { URL } from "../utility/urls";
import { AppointmentCard } from "../components/appointmentcard";
import { useNavigate } from "react-router-dom";
import { IconText } from "../components/icon";
import { addSelectedDoctor } from "../redux/features/userData";
import { addDoctor_fcmTokens } from "../redux/features/fcmTokens";
import { GetFcmTokenDoc } from "../utility/notifications";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Picker } from "../components/Picker";
import dayjs from "dayjs";
import { CUSTOMCOLORS } from "../styles";
export const Appointments = () => {
  const { refreshVar } = useSelector((state) => state?.appointment);
  const { token } = useSelector((state) => state.login);
  const { doctors, selectedDoctor } = useSelector((state) => state?.user);
  const navigate = useNavigate();
  const [selected, setSelected] = useState(CONSTANTS.appointment_filters[0]);
  const dispatch = useDispatch();
  const [Date, setDate] = useState(dayjs());
  const date = Date?.toISOString()?.split("T")[0];
  const [page, setPage] = useState(1);
  const [appointments, setAppointments] = useState([]);
  const [search, setSearchField] = useState("");
  const [filterAppointment, setFilterAppointment] = useState(appointments);
  const [Loading, setLoading] = useState(false);

  const fetchAppointments = async () => {
    try {
      const app_type = selected?.toLowerCase();
      const response = await fetch(
        URL.getAppointments(
          selectedDoctor?.reference,
          date,
          page,
          search,
          app_type === "completed"
            ? "fulfilled"
            : app_type === "new"
            ? "WALKIN"
            : app_type === "routine"
            ? "ROUTINE"
            : app_type === "followup"
            ? "FOLLOWUP"
            : ""
        ),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const jsonData = await response.json();
      const apps = jsonData?.data?.apps_data ? jsonData?.data?.apps_data : [];
      setAppointments(apps);
      setFilterAppointment(apps);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAppointments();
  }, [date, selectedDoctor, refreshVar, page, selected, search]);
  const handleDoctorSelect = async (item) => {
    dispatch(addSelectedDoctor(item));
    const fcmTokens = await GetFcmTokenDoc(item?.reference);
    const doc_tokens = fcmTokens
      ? fcmTokens?.map((item) => item?.fcmtoken)
      : [];
    dispatch(addDoctor_fcmTokens(doc_tokens));
  };

  return (
    <Stack display={"flex"} flexDirection={"column"} gap={2} width={1}>
      <Stack
        display={"flex"}
        direction={"row"}
        gap={isDesktopOrLaptop || istab ? 2 : 1}
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        <Box width={isDesktopOrLaptop || istab ? "25%" : "100%"}>
          <Dropdown
            value={selectedDoctor}
            setValue={handleDoctorSelect}
            options={doctors}
            pr_name="display"
            doc={true}
          />
        </Box>
        <Box width={isDesktopOrLaptop || istab ? "50%" : "100%"}>
          <SearchInput
            value={search}
            setValue={setSearchField}
            placeholder={"Search by name"}
          />
        </Box>
        <Box width={isDesktopOrLaptop || istab ? "20%" : "100%"}>
          <Picker value={Date} setValue={setDate} />
        </Box>
      </Stack>
      <Stack
        display={"flex"}
        flexDirection={isDesktopOrLaptop ? "row" : "column"}
        gap={2}
        width={isDesktopOrLaptop ? "96%" : "100%"}
        alignItems={isDesktopOrLaptop ? "center" : ""}
      >
        <Box display={"flex"} flexDirection={"row"} flex={1} borderRadius={1}>
          {CONSTANTS.appointment_filters?.map((item, index) => (
            <Box
              key={index}
              width={isSmallMobile ? "25%" : "20%"}
              display={"flex"}
              p={1}
              height={1}
              borderRadius={0.5}
              alignItems={"center"}
              justifyContent={"center"}
              onClick={(e) => {
                e.preventDefault();
                setSelected(item);
              }}
              sx={{
                color:
                  selected === item ? CUSTOMCOLORS.white : CUSTOMCOLORS.black,
                backgroundColor:
                  selected === item ? CUSTOMCOLORS.selection : "primary.light",

                cursor: "pointer",
              }}
            >
              <Typography
                key={index}
                fontWeight={600}
                variant={isDesktopOrLaptop || istab ? "subtitle1" : "subtitle2"}
              >
                {capitalizeWord(item)}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            color="success"
            size={isDesktopOrLaptop ? "large" : "medium"}
            onClick={(e) => {
              e.preventDefault();
              navigate("/addPatient");
            }}
            sx={{
              "&:hover": {
                backgroundColor: "#23A330",
              },
            }}
          >
            {selectedDoctor?.role === "doctor"
              ? "Book Appointment"
              : "Search Patient"}
          </Button>
        </Box>
      </Stack>
      <Stack display={"flex"} flexDirection={"column"} gap={1}>
        {filterAppointment?.length > 0 ? (
          <>
            {filterAppointment?.map((item) => (
              <AppointmentCard key={item?.id} appointment_data={item} />
            ))}
            <Stack position={"relative"}>
              {page > 1 && (
                <Button
                  sx={{
                    alignSelf: "flex-start",
                  }}
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault();
                    return setPage((page) => page - 1);
                  }}
                >
                  prev
                </Button>
              )}
              {appointments?.length === 10 && (
                <Button
                  variant="outlined"
                  sx={{ position: "absolute", right: 0 }}
                  onClick={(e) => {
                    e.preventDefault();
                    return setPage((page) => page + 1);
                  }}
                >
                  next
                </Button>
              )}
            </Stack>
          </>
        ) : (
          <IconText />
        )}
      </Stack>
    </Stack>
  );
};
