import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import createabha from "../assets/createAbha.png";
import verifyabha from "../assets/verifyAbha.png";
import { isDesktopOrLaptop, istab } from "../utility/const";

const Abha = () => {
  const nav = useNavigate();
  const listItems = [
    {
      url: createabha,
      text: "Create ABHA",
    },
    {
      url: verifyabha,
      text: "Verify ABHA",
    },
  ];
  return (
    <Stack p={2}>
      <Box
        alignItems={"center"}
        display={"flex"}
        flexDirection={"column"}
        gap={8}
      >
        <Stack alignItems={"center"} display={"flex"} flexDirection={"column"}>
          <Typography variant="h6">Abha Number</Typography>
          <Typography variant="subtitle2">
            Please choose one of the below option
          </Typography>
        </Stack>
        <Stack display={"flex"} flexDirection={"row"} gap={4}>
          {listItems?.map((item, ind) => (
            <Box
              key={ind}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              boxShadow={"0px 0px 4px 0px #0000004A"}
              // width={250}
              maxHeight={isDesktopOrLaptop || istab ? 200 : 150}
              p={4}
              borderRadius={1}
              borderColor={"primary.main"}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  bgcolor: "primary.light",
                  transition: "ease 0.1s",
                },
              }}
              onClick={() => {
                if (item?.text === "Verify ABHA") {
                  nav("/abhaverify");
                } else {
                  nav("/abhacreate");
                }
              }}
            >
              <Avatar
                src={item?.url}
                sx={{
                  width: isDesktopOrLaptop || istab ? 150 : 100,
                  height: "auto",
                }}
                variant="rounded"
              />
              <Typography variant="subtitle1">{item?.text}</Typography>
            </Box>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};

export default Abha;
