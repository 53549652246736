import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import {
  CONSTANTS,
  isDesktopOrLaptop,
  RemoveToken,
  RetriveToken,
} from "../utility/const";
import { isSmallMobile, ismobile, istab } from "../utility/const";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { URL } from "../utility/urls";
import { useDispatch } from "react-redux";
import { addLoginData } from "../redux/features/logindata";
import { Avatar, Box } from "@mui/material";
import { CUSTOMCOLORS } from "../styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

export const Header = React.memo((props) => {
  const [select, setSelect] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname.replace("/", "");
    const selectedItemName = CONSTANTS.side_nav.find(
      (item) => item.link === currentPath
    )?.name;
    if (selectedItemName) {
      setSelect(selectedItemName);
    }
  }, [select]);

  const refresh_token = RetriveToken("login");
  const userLogout = async (e) => {
    e.preventDefault();
    const response = await fetch(URL.userLogout, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(refresh_token),
    });
    const jsonData = await response.json();
    if (jsonData?.status === "success") {
      dispatch(
        addLoginData({
          token: "",
          phoneNumber: "",
          profile_id: "",
          isAuth: false,
          email: "",
        })
      );
      RemoveToken("login");
      localStorage.clear();
      navigate(jsonData?.data);
    }
  };
  const ischeck = isDesktopOrLaptop || istab;

  return (
    <>
      <Box>
        <Drawer
          sx={{
            paddingTop: "4rem",
            width: istab || ismobile || isSmallMobile ? "40%" : "14vw",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              boxShadow: "0px 0px 8px 0px #0000004A",
              paddingTop: "4rem",
              width: istab ? "30%" : ismobile || isSmallMobile ? "50%" : "14vw",
              gap: "2rem",
              boxSizing: "border-box",
            },
          }}
          variant={props.onClick ? "persistent" : "permanent"}
          anchor="left"
          open={props.onClick}
        >
          <Avatar
            src={logo}
            variant="rounded"
            sx={{
              width: ischeck ? 140 : 100,
              height: ischeck ? 110 : 80,
              alignSelf: "center",
            }}
          ></Avatar>

          <List
            sx={{
              gap: "16px",
              display: "flex",
              flexDirection: "column",
              paddingRight: "8px",
            }}
          >
            {CONSTANTS.side_nav.map((text) => (
              <ListItem
                key={text.name}
                onClick={(e) => {
                  if (text?.name?.toLowerCase() !== "logout") {
                    navigate(text.link);
                  } else {
                    userLogout(e);
                  }
                }}
                sx={{
                  borderTopRightRadius: "24px",
                  WebkitBorderBottomRightRadius: "24px",
                  paddingRight: "8px",
                  backgroundColor:
                    location?.pathname === text?.link
                      ? CUSTOMCOLORS.primary
                      : "",
                  color:
                    location?.pathname === text?.link ? CUSTOMCOLORS.white : "",
                }}
              >
                <ListItemButton
                  sx={{
                    padding: 0,
                    paddingLeft: "1rem",
                    background: "transparent !important",
                    "& .MuiListItemText-primary": {
                      fontSize: "16px",
                      fontWeight: 600,
                      fontFamily: "open sans",
                    },
                  }}
                  onClick={props.close}
                >
                  <ListItemText
                    primary={text.name}
                    sx={{ fontFamily: "Open sans", fontSize: "18px" }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Box>
    </>
  );
});
