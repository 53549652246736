import React from 'react';
import TextField from '@mui/material/TextField';
export const TextInput = (props) => {
  const handleNumericInput = (input) => {
    const numericValue = input.replace(/[^0-9]/g, '');
    props.onChange(numericValue);
  };
  return (
    <TextField
      className={props.className}
      required={props.required ? true : false}
      InputLabelProps={{
        style: { fontSize: 14, fontWeight: 500 },
      }}
      InputProps={{
        style: props.className
          ? {}
          : { width: 100, borderColor: '#4ba5fa', ...props.style },
      }}
      inputProps={{
        maxLength: props.length ? props.length : 250000,
      }}
      size='small'
      id='outlined-basic margin-none'
      label={props.label}
      variant='outlined'
      value={props.value}
      disabled={props.disabled ? props.disabled : false}
      onChange={
        props.numeric
          ? (e) => {
            handleNumericInput(e.target.value);
          }
          : props.onChange
      }
    />
  );
};
