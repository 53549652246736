import {
  Avatar,
  Box,
  Button,
  Modal,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CUSTOMCOLORS } from '../../styles';
import logo from '../../assets/logo.png'
import rx from '../../assets/rx.png'
import foot from '../../assets/footer.png'
import { Text } from '../Text';
import dayjs from 'dayjs';
import { capitalizeWord, formatter, handleDoctorname, PrintPDF } from '../../utility/const';
import { VitalsPrev } from '../ConsultationPreveiewComponents/vitals';
import { AllergyPrev } from '../ConsultationPreveiewComponents/allergy';
import { URL } from '../../utility/urls';

export const PrescriptionPreview = ({ PrescriptionData, patientData, open, onClose, doc_data, roles_data }) => {
  const doctor_name = doc_data?.actor?.display
  console.log(doc_data);
  const { token } = useSelector(
    (state) => state.login
  );
  const { organization_data } = useSelector((state) => state?.organization)

  const organization_address = organization_data?.organization?.address[0];

  const total_fees = PrescriptionData['fees']?.reduce(
    (acc, cur) => (acc += Number(cur?.value)),
    0
  );
  const reasondata = PrescriptionData?.ReasonForVisit;
  const vitals = PrescriptionData?.Vitals;
  const prescribedata = PrescriptionData?.prescribe;
  const allergydata = PrescriptionData?.allergy;
  const symptomdata = PrescriptionData?.symptoms;
  const illnessdata = PrescriptionData?.presentIllness;
  const diagnosisdata = PrescriptionData?.diagnosis;
  const investigationdata = PrescriptionData?.investigation;
  const followup = PrescriptionData?.followup;
  const advicedata = PrescriptionData?.advice;
  const physiData = [];
  PrescriptionData?.physical?.physicalExamination.forEach((item) => {
    if (JSON.stringify(item?.status) !== '{}') {
      physiData.push(item);
    }
  });
  const handleClose = () => {
    onClose(!open);
  };
  const [base64str, setBase64Str] = useState('');
  const [printOpen, setPrintOpen] = useState(false);
  const speciality = (roles_data ?? [])
    .map((item) =>
      (item?.role?.specialty[0]?.coding ?? []).map((val) => val?.display)
    )
    .flat();
  const PrintModal = ({ base64 }) => {
    return (
      <Modal
        open={printOpen}
        onClose={() => {
          setPrintOpen(!open);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modalStyle}>
          <iframe
            title={`${patientData?.name[0]?.text}Prescription.pdf`}
            src={base64}
            width='100%'
            height='100%'
          />
        </Box>
      </Modal>
    );
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <div
          style={{
            position: 'relative',
          }}
        >
          <header
            id='pheader'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottomWidth: 2,
              borderBottomColor: CUSTOMCOLORS.primary,
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <img
                src={
                  organization_data?.org_photo?.data
                    ? organization_data?.org_photo?.data
                    : logo
                }
                style={{
                  objectFit: 'contain',
                  height: '64px',
                  width: '64px',
                }}
              />
              <div>
                <p
                  style={{
                    alignSelf: 'flex-end',
                    color: CUSTOMCOLORS.primary,
                    fontSize: 14,
                    fontWeight: 'bolder',
                  }}
                >
                  {handleDoctorname(doctor_name)}
                </p>
                <p
                  style={{
                    alignSelf: 'flex-end',
                    color: CUSTOMCOLORS.primary,
                    fontSize: 12,
                  }}
                >
                  {[...new Set(speciality)].join(',')}
                </p>
                <p
                  style={{
                    alignSelf: 'flex-end',
                    color: CUSTOMCOLORS.primary,
                    fontSize: 12,
                  }}
                >
                  {doc_data?.profile?.medicalRegestrationNumber}
                </p>
              </div>
            </div>
            <div>
              <b
                style={{
                  alignSelf: 'flex-end',
                  color: CUSTOMCOLORS.primary,
                  fontSize: 14,
                }}
              >
                {organization_data?.organization?.name}
              </b>
              <br />
              <p
                style={{
                  alignSelf: 'flex-end',
                  color: CUSTOMCOLORS.primary,
                  fontSize: 12,
                }}
              >
                {`${organization_address?.text},${organization_address?.city},${organization_address?.state},${organization_address?.country},${organization_address?.postalCode}.`}
              </p>
            </div>
          </header>
          <div
            id='content-id'
          //  style={{ padding: 16, top: 8 }}
          >
            <div>
              <Avatar
                variant='rounded'
                src={rx}
              />
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Text
                  weight={'400'}
                  size={12}
                >{`${patientData?.name[0]?.prefix[0]}. ${patientData?.name[0]?.text} | ${patientData?.gender} | ${patientData?.telecom[0]?.value}`}</Text>
                <Text
                  weight={'400'}
                  size={12}
                >
                  Date & Time:{' '}
                  {dayjs(new Date()).format('DD-MM-YYYY, HH:mm:ss')}
                </Text>
              </Stack>
            </div>

            <Stack
              mt={2}
              spacing={1}
            >
              {PrescriptionData?.ReasonForVisit &&
                Object.keys(PrescriptionData?.ReasonForVisit)?.length > 0 && (
                  <Stack>
                    <Text
                      weight={'700'}
                      color={CUSTOMCOLORS.primary}
                      size={14}
                    >
                      Reason For Visit
                    </Text>
                    <Stack direction={'row'}>
                      {reasondata?.map((item, index) => (
                        <React.Fragment key={index}>
                          <Typography
                            key={index}
                            fontWeight={400}
                            fontSize={12}
                          >
                            {item?.chiefComplaint?.code?.text}
                            {index < reasondata?.length - 1 && ', '}
                          </Typography>
                        </React.Fragment>
                      ))}
                    </Stack>
                  </Stack>
                )}
              {symptomdata && Object.keys(symptomdata)?.length > 0 && (
                <Stack>
                  <Text
                    weight={'700'}
                    color={CUSTOMCOLORS.primary}
                    size={14}
                  >
                    Symptoms
                  </Text>
                  <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    flexWrap={'wrap'}
                  >
                    {symptomdata?.map((item, index) => (
                      <React.Fragment key={index}>
                        <Typography
                          fontWeight={400}
                          fontSize={12}
                        >
                          {item?.symptoms?.code?.text}
                          {index < symptomdata?.length - 1 && ', '}
                        </Typography>
                      </React.Fragment>
                    ))}
                  </Stack>
                </Stack>
              )}
              {illnessdata && Object.keys(illnessdata)?.length > 0 && (
                <Stack>
                  <Text
                    weight={'700'}
                    color={CUSTOMCOLORS.primary}
                    size={14}
                  >
                    History of Present Illness
                  </Text>
                  <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    flexWrap={'wrap'}
                  >
                    {illnessdata?.map((item, index) => (
                      <React.Fragment key={index}>
                        <Typography
                          fontWeight={400}
                          fontSize={12}
                        >
                          {item?.chiefComplaint?.code?.text}
                          {index < illnessdata.length - 1 && ', '}
                        </Typography>
                      </React.Fragment>
                    ))}
                  </Stack>
                </Stack>
              )}
              {vitals?.length > 0 && (
                <VitalsPrev
                  data={vitals}
                  ispdf={true}
                />
              )}
              {allergydata && Object.keys(allergydata)?.length > 0 && (
                <AllergyPrev
                  data={allergydata}
                  color={'primary'}
                />
              )}
              {PrescriptionData?.physicalExamination?.length > 0 && (
                <Stack>
                  <Text
                    size={14}
                    weight={'700'}
                    color={CUSTOMCOLORS.primary}
                  >
                    Physical Examinations
                  </Text>
                  <Stack
                    direction={'row'}
                    spacing={1}
                    flexWrap={'wrap'}
                  >
                    {PrescriptionData?.physicalExamination?.map(
                      (item, index) => {
                        if (item?.status?.text) {
                          return (
                            <Stack
                              key={index}
                              direction={'row'}
                            >
                              <Text size={12}>{item?.label?.text}-</Text>
                              <Text
                                size={12}
                                weight={'600'}
                              >
                                {item?.status?.text}
                              </Text>
                              {''}
                              <Text size={12}>
                                {item?.description
                                  ? `-${item?.description}`
                                  : ''}
                              </Text>
                            </Stack>
                          );
                        }
                      }
                    )}
                  </Stack>
                </Stack>
              )}
              {diagnosisdata && Object.keys(diagnosisdata)?.length > 0 && (
                <Stack>
                  <Typography
                    fontSize={14}
                    fontWeight={'700'}
                    color={CUSTOMCOLORS.primary}
                  >
                    Diagnosis
                  </Typography>
                  <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    flexWrap={'wrap'}
                  >
                    {diagnosisdata?.map((item, index) => (
                      <React.Fragment key={index}>
                        <Typography
                          fontWeight={400}
                          fontSize={12}
                        >
                          {item?.diagnosis?.code?.text}
                          {index < diagnosisdata?.length - 1 && ', '}
                        </Typography>
                      </React.Fragment>
                    ))}
                  </Stack>
                </Stack>
              )}
              {PrescriptionData?.reportsFindings?.length > 0 && (
                <Stack>
                  <Text
                    size={14}
                    weight={'700'}
                    color={CUSTOMCOLORS.primary}
                  >
                    Report Finding
                  </Text>
                  <Stack
                    direction={'row'}
                    spacing={1}
                    flexWrap={'wrap'}
                  >
                    {PrescriptionData?.reportsFindings?.map(
                      (item, _) => (
                        <Text
                          key={_}
                          size={12}
                        >
                          {`${item?.type && `Type: ${capitalizeWord(item?.type)}`
                            } ${item?.issuedDate &&
                            `,IssueDate: ${item?.issuedDate}`
                            } ${item?.description &&
                            `,Description: ${item?.description}`
                            }`}
                        </Text>
                      )
                    )}
                  </Stack>
                </Stack>
              )}
              {prescribedata?.length > 0 && (
                <Stack>
                  <Text
                    weight={'700'}
                    color={CUSTOMCOLORS.primary}
                    size={14}
                  >
                    Prescribe
                  </Text>
                  <Table sx={{ border: 'none' }}>
                    <TableHead>
                      <TableRow>
                        {[
                          'S.No',
                          'Medicine',
                          'Timing',
                          'Frequency',
                          'Duration',
                          'Quantity',
                          'Remarks',
                        ]?.map((value, index) => (
                          <TableCell
                            sx={{
                              color: CUSTOMCOLORS.black,
                              fontWeight: '600',
                              fontSize: 12,
                            }}
                            key={index}
                            size='small'
                          >
                            {capitalizeWord(value)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {prescribedata?.map((item, ind) => (
                      <TableRow key={ind}>
                        <TableCell
                          sx={{
                            fontSize: 10,
                          }}
                          size='small'
                        >
                          {ind + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: 10,
                          }}
                          size='small'
                        >
                          {item?.medication?.medication[0]?.text?.toUpperCase()}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: 10,
                          }}
                          size='small'
                        >
                          {
                            item?.medication?.dosage?.additionalInformation[0]
                              ?.text
                          }
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: 10,
                          }}
                          size='small'
                        >
                          {item?.medication?.dosage?.timing?.code?.text}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: 10,
                          }}
                          size='small'
                        >
                          {item?.duration}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: 10,
                          }}
                          size='small'
                        >
                          {item?.quantity}
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: 10,
                          }}
                          size='small'
                        >
                          {item?.medication?.note[0]?.text
                            ? item?.medication?.note[0]?.text
                            : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Stack>
                // <PrescribePrev data={prescribedata} />
              )}
              {advicedata && Object.keys(advicedata)?.length > 0 && (
                <Stack>
                  <Typography
                    fontSize={14}
                    fontWeight={'700'}
                    color={CUSTOMCOLORS.primary}
                  >
                    Advice
                  </Typography>
                  <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    flexWrap={'wrap'}
                  >
                    {advicedata?.map((item, index) => (
                      <React.Fragment key={index}>
                        <Typography
                          fontWeight={400}
                          fontSize={12}
                        >
                          {item?.type}-{item?.adviceText}
                        </Typography>
                      </React.Fragment>
                    ))}
                  </Stack>
                </Stack>
              )}
              {followup && Object.keys(followup)?.length > 0 && (
                <Stack>
                  <Typography
                    fontSize={14}
                    fontWeight={'700'}
                    color={CUSTOMCOLORS.primary}
                  >
                    Follow Up
                  </Typography>
                  <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    flexWrap={'wrap'}
                  >
                    {followup?.map((item, index) => (
                      <React.Fragment key={index}>
                        <Typography
                          fontWeight={400}
                          fontSize={12}
                        >
                          {dayjs(item?.followUp?.start?.split('T')[0]).format(
                            'DD-MM-YYYY'
                          )}
                        </Typography>
                      </React.Fragment>
                    ))}
                  </Stack>
                </Stack>
              )}
              {investigationdata &&
                Object.keys(investigationdata)?.length > 0 && (
                  <Stack>
                    <Typography
                      fontSize={14}
                      fontWeight={'700'}
                      color={CUSTOMCOLORS.primary}
                    >
                      Prescribe Investigation
                    </Typography>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      flexWrap={'wrap'}
                    >
                      {investigationdata?.map((item, index) => (
                        <Stack
                          key={index}
                          direction={'row'}
                          spacing={0.5}
                        >
                          <Typography
                            fontWeight={400}
                            fontSize={12}
                          >
                            {item?.procedure?.code?.text}{' '}
                          </Typography>

                          <Text
                            weight={'700'}
                            size={12}
                          >
                            Date: {item?.followup_date}
                            {index < investigationdata.length - 1 && ', '}&nbsp;
                          </Text>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                )}
              <div>
                {PrescriptionData?.fees?.length > 0 && (
                  <>
                    <Text
                      weight={'700'}
                      color={CUSTOMCOLORS.primary}
                      size={14}
                    >
                      Service Fees
                    </Text>
                    <Table sx={{ border: 'none' }}>
                      <TableHead>
                        <TableRow>
                          {['S.No', 'service name', 'fee']?.map(
                            (value, index) => (
                              <TableCell
                                sx={{
                                  color: CUSTOMCOLORS.black,
                                  fontWeight: '600',
                                  fontSize: 12,
                                }}
                                key={index}
                                size='small'
                              >
                                {capitalizeWord(value)}
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      {PrescriptionData?.fees?.map((item, ind) => (
                        <TableRow key={ind}>
                          <TableCell
                            sx={{
                              fontSize: 10,
                            }}
                            size='small'
                          >
                            {ind + 1}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: 10,
                            }}
                            size='small'
                          >
                            {item?.service_name}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: 10,
                            }}
                            size='small'
                          >
                            {formatter.format(item?.value)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                    <Typography
                      alignSelf={'flex-end'}
                      fontWeight={'700'}
                    >
                      Total: {formatter.format(total_fees)}
                    </Typography>
                  </>
                )}
              </div>
            </Stack>
          </div>

          <footer
            id='pfooter'
            style={
              {
                // bottom: 12,
                // justifyContent: "flex-end",
                // position: "absolute",
                // bottom: 0,
                // height: "8%",
                // padding: 16,
                // display: "flex",
                // flexDirection: "column",
                // gap: 2,
              }
            }
          >
            <Typography
              fontSize={10}
              textAlign={'center'}
              ml={3}
            >
              This prescription electronically signed by{' '}
              {doctor_name?.startsWith('dr') || doctor_name?.startsWith('dr.')
                ? doctor_name?.toUpperCase()
                : `Dr. ${doctor_name?.toUpperCase()}`}
              ,{[...new Set(speciality)].join(',')},
              {doc_data?.profile?.medicalRegestrationNumber},
              {new Date()?.toString()}
            </Typography>
            <Stack
              alignItems={'flex-start'}
              justifyContent={'center'}
            >
              <p
                style={{
                  fontSize: 10,
                }}
              >
                powered By
              </p>
              <Avatar
                src={foot}
                variant='rounded'
                sx={{
                  height: 28,
                  width: 28,
                  ml: 1,
                  mt: 1,
                }}
              />
            </Stack>
          </footer>
        </div>
        <Box
          width={'100%'}
          position={'sticky'}
          p={1}
          bottom={0}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Button
            size='large'
            variant='contained'
            onClick={async (e) => {
              const str = await PrintPDF('content-id', e, 'pheader', 'pfooter');
              setBase64Str(str);
              setPrintOpen(true);
            }}
          >
            print
          </Button>
          <PrintModal base64={base64str} />
        </Box>
      </Box>
    </Modal>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  height: '100%',
  width: 'auto',
  overflowY: 'auto',
  padding: 1,
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  height: `100%`,
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  overflowY: 'auto',
};
