import { IconButton, InputBase, Paper, Stack } from "@mui/material";
import React from "react";
import { Close } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { CUSTOMCOLORS } from "../styles";

export const SearchInput = React.memo(
  ({
    id,
    label,
    required = false,
    value,
    setValue,
    lblstyle,
    placeholder,
    size,
  }) => {
    return (
      <Stack display={"flex"} flexDirection={"column"} gap={1}>
        {label && (
          <label htmlFor={id} style={lblstyle}>
            {" "}
            {label}
          </label>
        )}
        <Paper
          elevation={1}
          component="form"
          sx={{
            p: 1,
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 0px 4px 0px #0000004A",
          }}
        >
          <InputBase
            sx={{
              ml: 1,
              flex: 1,
              fontFamily: "Open sans",
              color: CUSTOMCOLORS.black,
              "&::placeholder": {
                color: "black",
              },
            }}
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            required={required}
            size={size || "medium"}
          />
          <IconButton
            onClick={() => {
              if (value?.length > 0) {
                setValue("");
              }
            }}
          >
            {value?.length > 0 ? <Close /> : <SearchIcon />}
          </IconButton>
        </Paper>
      </Stack>
    );
  }
);
