import React, { useState } from "react";
import { Box, Typography } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { PasswordComponent } from "./passwordField";
import { showToast } from "../utility/toast";
import { URL } from "../utility/urls";

export const UpdatedPassword = ({ isAccount, setClosed }) => {
  const { phoneNumber, token } = useSelector((state) => state.login);
  console.log(phoneNumber);
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState({
    password: "",
    cnfPassword: "",
  });

  const ChangePassword = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (!oldPassword) {
      return showToast("error", "Please enter old password");
    }
    if (!password?.password || !password?.cnfPassword) {
      return showToast("error", "Please enter password");
    }
    if (password?.password !== password?.cnfPassword) {
      return showToast("error", "Password and confirm password should be same");
    }
    setLoading(true);
    try {
      const response = await fetch(URL.change_password, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          prevPassword: oldPassword || "",
          email_or_phone: phoneNumber,
          password: password?.password,
        }),
      });
      const jsonData = await response.json();
      if (jsonData?.status === "success") {
        if (isAccount) {
          setClosed(false);
          return showToast("success", "Password changed successfully");
        }
      } else {
        showToast("error", jsonData?.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box
      component={"form"}
      display={"flex"}
      gap={2}
      flexDirection={"column"}
      onSubmit={ChangePassword}
    >
      {isAccount && (
        <>
          <Typography component={"h2"} variant={"h6"} alignSelf={"center"}>
            Change Password
          </Typography>
          <PasswordComponent
            value={oldPassword}
            onChange={(e) => setOldPassword(e)}
            placeholder={"Enter old password"}
            label={"Old Password"}
          />
        </>
      )}
      <PasswordComponent
        value={password?.password}
        fullwidth={true}
        onChange={(e) => setPassword({ ...password, password: e })}
        placeholder={"Enter password"}
        label={"Password"}
        info={
          "Password should be at least 8 characters long and should contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character"
        }
      />
      <PasswordComponent
        fullwidth={true}
        value={password?.cnfPassword}
        onChange={(e) => setPassword({ ...password, cnfPassword: e })}
        placeholder={"Enter confirm password"}
        label={"Confirm Password"}
      />
      <LoadingButton
        loading={loading}
        variant="contained"
        type="submit"
        sx={{
          alignSelf: "center",
        }}
        size="large"
      >
        {"Submit"}
      </LoadingButton>
    </Box>
  );
};
