import {
  Box,
  Modal,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { isDesktopOrLaptop } from "../utility/const";

const ExportHistory = ({ open, onClose, data }) => {
  console.log(open);

  const header = ["S.No", "Number of rows", "Date and Time"];
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" color={"primary.dark"}>
          Export History
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              {header.map((value, index) => (
                <TableCell
                  key={index}
                  sx={{ color: "primary.dark", fontSize: 12 }}
                >
                  {value}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {data?.map((item, index) => (
            <TableRow key={index}>
              <TableCell sx={{ color: "primary.dark" }}>{index + 1}</TableCell>
              <TableCell>{item?.rows} rows</TableCell>
              <TableCell
                sx={{
                  color: "primary.dark",
                  fontWeight: 600,
                }}
              >
                {item?.timestamp?.split("T")[0]}{" "}
                {item?.timestamp?.split("T")[1]?.split(".")[0]}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Box>
    </Modal>
  );
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
  padding: 2,
  borderRadius: 2,
  height: "90%",
  width: isDesktopOrLaptop ? "50%" : "90%",
};
export default ExportHistory;
