import { useDispatch, useSelector } from "react-redux";
import {
  capitalizeWord,
  isDesktopOrLaptop,
  ismobile,
  istab,
} from "../utility/const";

import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { Avatar, Button, IconButton, Stack, Typography } from "@mui/material";
import { PatientCreate } from "./PatientCreate";
import { addPatientData } from "../redux/features/patientData";
import {
  addAppointmentData,
  addAppointmentId,
} from "../redux/features/appointmentData";
import { CUSTOMCOLORS } from "../styles";
import { WalkInModal } from "./walkinModal";
import { Edit } from "@mui/icons-material";

export const PatientCard = React.memo(({ data, doc }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const birthYear = data?.patient?.birthDate?.split("-")[0];
  const presentYear = new Date()?.getFullYear();
  const [showWalkin, setshowwalkin] = useState(false);
  const handlewalkinModal = () => setshowwalkin(false);
  const phone = data.patient?.telecom[0]?.value;
  const { organization } = useSelector((state) => state?.user);

  const ref_id = data?.reference_ids?.filter(
    (item) =>
      item?.organization_id === organization?.reference ||
      !item?.organization_id
  )?.[0]?.["reference_id"];
  return (
    <Stack
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      px={2}
      py={1}
      border={"1px solid rgba(75, 165, 250, 0.5)"}
      borderRadius={2}
    >
      <Stack display={"flex"} flexDirection={"row"} gap={1}>
        <Stack
          position={"relative"}
          border={2}
          borderRadius={100}
          p={0.5}
          borderColor={CUSTOMCOLORS.fadeblue}
          width={!isDesktopOrLaptop ? 64 : 72}
          height={!isDesktopOrLaptop ? 64 : 72}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Avatar
            onClick={() => {
              setOpen(!open);
            }}
            src={
              data?.patient?.photo?.data?.startsWith("data")
                ? data?.patient?.photo?.data
                : `data:image/jpeg;base64,${data?.patient?.photo?.data}`
            }
            sx={{
              width: isDesktopOrLaptop || istab ? 60 : 48,
              height: isDesktopOrLaptop || istab ? 60 : 48,
              cursor: "pointer",
            }}
          />
          {doc && (
            <Stack position={"absolute"} bottom={0} right={0}>
              <IconButton
                onClick={() => {
                  setOpen(!open);
                }}
                sx={{ margin: 0, padding: 0 }}
              >
                <Edit color="primary" fontSize="small" />
              </IconButton>
            </Stack>
          )}
        </Stack>
        <Stack>
          <Typography
            variant="body1"
            fontSize={isDesktopOrLaptop || istab ? "auto" : 14}
          >
            {`${data?.patient?.name?.[0]?.prefix?.join(",")} ${capitalizeWord(
              data?.patient?.name?.[0]?.text
            )}`}
          </Typography>
          <Typography
            variant="body2"
            fontSize={isDesktopOrLaptop || istab ? "auto" : 14}
          >
            {parseInt(presentYear) - parseInt(birthYear)} |{" "}
            {data?.patient?.gender ? capitalizeWord(data?.patient?.gender) : ""}{" "}
            | {phone}
          </Typography>
          {data?.complaint && (
            <Typography
              fontSize={isDesktopOrLaptop || istab ? "auto" : 14}
              variant="body2"
            >
              Reason for visit: {data?.complaint}
            </Typography>
          )}
          {ref_id && (
            <Typography variant="body2">Reference ID: {ref_id}</Typography>
          )}
        </Stack>
      </Stack>
      {data?.patient?.complaint ? (
        ""
      ) : (
        <Stack
          display={"flex"}
          flexDirection={
            isDesktopOrLaptop || istab || ismobile ? "row" : "column"
          }
          gap={1}
        >
          {/* {doc && (
            <Button
              size={isDesktopOrLaptop || istab ? "auto" : "small"}
              onClick={() => {
                setOpen(!open);
              }}
              variant="outlined"
            >
              Edit
            </Button>
          )} */}
          <Button variant="outlined" onClick={() => setshowwalkin(true)}>
            Walk In
          </Button>
          <Button
            size={isDesktopOrLaptop || istab ? "auto" : "small"}
            sx={{
              fontSize: isDesktopOrLaptop || istab ? "auto" : 12,
            }}
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              dispatch(addPatientData(data));
              dispatch(addAppointmentData({}));
              dispatch(addAppointmentId(""));
              navigate("/slotbook");
            }}
          >
            {isDesktopOrLaptop || istab ? "Book Appointment" : "Book"}
          </Button>
        </Stack>
      )}
      {open && <PatientCreate open={open} onClose={setOpen} data={data} />}
      {showWalkin && (
        <WalkInModal
          open={showWalkin}
          onClose={handlewalkinModal}
          pat_data={data}
        />
      )}
    </Stack>
  );
});
