import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AppointmentTypes,
  days,
  daysMap,
  getDuration,
  // handleDoctorname,
  isDesktopOrLaptop,
  isSmallMobile,
  istab,
  // sendSlotDetailsToWtsApp,
} from "../utility/const";
import { URL } from "../utility/urls";
import { Box, Button, Chip, Modal, Stack, Typography } from "@mui/material";
import { Dropdown } from "../components/Dropdown";
import { DatePick } from "../components/DatePicker";
import { SearchField } from "../components/SearchFiled";

// import { Text } from "../components/Text";
// import { CUSTOMCOLORS } from "../styles";
import { addPatientData } from "../redux/features/patientData";
import {
  addAppointmentData,
  addAppointmentId,
} from "../redux/features/appointmentData";
import { showToast } from "../utility/toast";
import { addSelectedDoctor } from "../redux/features/userData";
import {
  fetchSugs,
  postSuggestions,
  updateSuggestions,
} from "../utility/suggestion";
import { RadioButton } from "../components/RadioBtn";
import { GetFcmTokenDoc, sendNotification } from "../utility/notifications";
import { addDoctor_fcmTokens } from "../redux/features/fcmTokens";
import dayjs from "dayjs";
import { NewConsent } from "../components/Modals/Consents";
// import FileViewer from "../components/FileViewer";

export const SlotBook = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [roles_data, setRole_Data] = useState([]);
  const { token, profile_id } = useSelector((state) => state.login);
  const { user_data, doctors, organization, selectedDoctor } = useSelector(
    (state) => state?.user
  );
  const { appointment_data, appointment_id } = useSelector(
    (state) => state?.appointment
  );
  const { patient_data } = useSelector((state) => state?.patient);
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const day = days[new Date(date).getDay()];
  const [mode, setMode] = useState("Offline");
  const [appType, setAppType] = useState({});
  const [isPaid, setIsPaid] = useState("Unpaid");
  const [feeMode, setFeeMode] = useState("");
  const [snomedValue, setSnomedValue] = useState("");
  const [complaint, setComplaint] = useState([]);
  const [snomeddata, setSnomedData] = useState([]);
  const [slot, selectedSlot] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [bookedSlot, setBookedSlot] = useState([]);
  const [suggs, setSugs] = useState([]);
  const [sugUpdate, setSugUpdate] = useState("");
  const { doctor_fcmTokens, serverApiFCM } = useSelector(
    (state) => state?.fcms
  );

  // const { wts_id, wts_token } = useSelector((state) => state.wtsapp);

  useEffect(() => {
    const fetchSug = async () => {
      const data = await fetchSugs(profile_id, "bookslot");
      setSugs(data);
    };
    fetchSug();
    return () => {};
  }, [sugUpdate]);

  const postSuggestionsData = async (item) => {
    const postdata = await postSuggestions(item, profile_id, "bookslot");
    if (postdata === "success") {
      setSugUpdate((data) => (data += "u"));
    }
  };
  const updatetSuggestionsData = async (item) => {
    const postdata = await updateSuggestions(item, profile_id, "bookslot");
    if (postdata === "success") {
      setSugUpdate((data) => (data += "u"));
    }
  };
  const fetchBookedSlots = async () => {
    const response = await fetch(
      URL.getBookedSlots(selectedDoctor?.reference, date),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const jsonData = await response.json();
    if (jsonData?.data?.length > 0) {
      setBookedSlot((data) => {
        const bookedSlots = jsonData?.data?.map((item, index) => item?.slot);
        return bookedSlots;
      });
    } else {
      setBookedSlot([]);
    }
    const role_response = await fetch(
      URL.getRole_IE_Slots(selectedDoctor?.reference),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const role_jsonData = await role_response.json();
    role_jsonData?.data ? setRole_Data(role_jsonData?.data) : setRole_Data([]);
  };

  useEffect(() => {
    fetchBookedSlots();
  }, [date, selectedDoctor]);

  useEffect(() => {
    if (JSON.stringify(appointment_data) !== "{}") {
      setComplaint(appointment_data?.appointment?.reasonCode);
      setMode(appointment_data?.mode);
      const appType =
        appointment_data?.appointment?.appointmentType?.coding?.map((item) => ({
          system: item?.system,
          code: item?.code,
          version: item?.version,
          display: item?.display,
        }));
      setAppType(appType[0]);
      setIsPaid(appointment_data?.fees);
      setFeeMode(appointment_data?.paymentMode);
      const app_date = appointment_data?.appointment?.start?.split("T")[0];
      setDate(app_date);
    }
  }, []);
  const fetchCompalint = async () => {
    const response = await fetch(
      URL.snomed(
        snomedValue ? snomedValue : "NA",
        "procedure++situation++finding"
      ),
      {
        method: "GET",
      }
    );
    const data = await response.json();
    setSnomedData(data);
  };
  useEffect(() => {
    if (snomedValue?.length > 2) {
      fetchCompalint();
    }
  }, [snomedValue]);

  const value =
    mode === "Offline"
      ? "in-person"
      : mode === "Telephone"
      ? "telephone"
      : mode === "Video conference"
      ? "videoconference"
      : "chat";

  const filter_roles_based_on_org = roles_data?.filter(
    (item) => item?.role?.organization?.reference === organization?.reference
  );

  const filterRole_data_slots = filter_roles_based_on_org.filter(
    (item) => item?.role?.characteristic[0]?.coding[0]?.code === value
  );
  const consultation_fees =
    filterRole_data_slots?.length > 0
      ? [
          ...new Set(
            filterRole_data_slots?.map((item) =>
              parseInt(item?.consultation_fees || 0)
            )
          ),
        ]?.[0]
      : [];

  function getAvailableTimesToday(data) {
    const todayCode = daysMap[dayjs(date).day()];

    const result = [];
    if (!Array.isArray(data)) {
      return result;
    }
    data?.forEach((item) => {
      const availableTimes = item?.role?.availability?.availableTime;
      availableTimes?.forEach((time) => {
        time?.daysOfWeek?.forEach((day) => {
          if (day?.code === todayCode) {
            result.push({
              duration: item?.duration,
              start: time.availableStartTime,
              end: time.availableEndTime,
              teleLink: item?.teleLink,
            });
          }
        });
      });
    });

    return result;
  }

  const availableTimesToday = getAvailableTimesToday(filterRole_data_slots);
  // const slotsList = filterRole_data_slots?.reduce((acc, slot) => {
  //   const daySet = new Set([day]);
  //   const validTimes = slot?.role?.availability?.availableTime?.filter(
  //     (timeSlot) =>
  //       timeSlot?.daysOfWeek?.some((dayOfWeek) => daySet.has(dayOfWeek?.code))
  //   );
  //   const slotTimings = validTimes.map((timeSlot) => ({
  //     start: `${timeSlot?.availableStartTime?.split(":")[0]}:${
  //       timeSlot?.availableStartTime?.split(":")[1]
  //     }`,
  //     end: `${timeSlot?.availableEndTime?.split(":")[0]}:${
  //       timeSlot?.availableEndTime?.split(":")[1]
  //     }`,
  //   }));
  //   if (validTimes && validTimes.length > 0) {
  //     acc.push(...slotTimings);
  //   }
  //   if (validTimes && validTimes.length > 0 && value === "videoconference") {
  //     return { ...acc[0], duration: slot?.duration, meetLink: slot?.teleLink };
  //   }
  //   if (validTimes && validTimes.length > 0) {
  //     return { ...acc[0], duration: slot?.duration };
  //   }
  //   return acc[0];
  // }, []);

  const getMinute = (time) => {
    let value = time?.split(":");
    let hour = parseInt(value[0]);
    let minute = parseInt(value[1]);
    let totalMin = hour * 60 + minute;
    return totalMin;
  };

  const getTime = (time) => {
    let hour = parseInt((time / 60).toString().split(".")[0]);
    let min = time % 60;
    let totalHour = hour?.toString()?.padStart(2, "0");
    let totalMinutes = min?.toString()?.padStart(2, "0");
    return totalHour + ":" + totalMinutes;
  };
  const getTimeList = (start, end, duration) => {
    let availableTime = [];
    const initialTime = getMinute(start);

    const endTime = getMinute(end);
    const loopLength = (endTime - initialTime) / parseInt(duration);
    for (let i = 0; i < loopLength; i++) {
      const startTime = getTime(initialTime + parseInt(duration) * i);
      const endTime = getTime(initialTime + parseInt(duration) * (i + 1));
      availableTime.push({
        start: `${startTime}`,
        end: `${endTime}`,
      });
    }
    return availableTime;
  };
  const SlotBtn = ({ booked, label, token_id }) => {
    const Today = new Date()?.toISOString()?.split("T")[0];
    const PresentTime = new Date().toString()?.split(" ")[4]?.substring(0, 5);
    const startTime = label?.split("-")[0]?.toString();
    return (
      <>
        {
          !bookedSlot?.includes(label) &&
          ((date === Today && startTime >= PresentTime) || date > Today) ? (
            <Stack position={"relative"}>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  selectedSlot(label);
                  setTokenId(parseInt(token_id));
                }}
                sx={{
                  minWidth: "132px",
                }}
                variant={booked ? "contained" : "outlined"}
              >
                {label}
              </Button>
              {/* <Typography
              variant="body2"
              position={"absolute"}
              top={0}
              left={0}
              sx={{
                background: CUSTOMCOLORS.primary,
                padding: "2px 4px ",
                fontSize: 10,
                borderTopLeftRadius: 6,
                borderBottomRightRadius: 6,
                color: CUSTOMCOLORS.white,
              }}
            >
              {token_id}
            </Typography> */}
            </Stack>
          ) : null
          // <Stack position={"relative"}>
          //   <Button
          //     sx={{
          //       minWidth: "132px",
          //       backgroundColor: bookedSlot?.includes(label)
          //         ? CUSTOMCOLORS.green
          //         : CUSTOMCOLORS.disableSlot,
          //       color: bookedSlot?.includes(label)
          //         ? CUSTOMCOLORS.success
          //         : "#5d5d5d",
          //       border: "none",
          //       outline: "none",
          //     }}
          //     variant={booked ? "contained" : "outlined"}
          //   >
          //     {label}
          //   </Button>
          //   <Typography
          //     variant="body2"
          //     position={"absolute"}
          //     top={0}
          //     left={0}
          //     sx={{
          //       background: bookedSlot?.includes(label)
          //         ? CUSTOMCOLORS.success
          //         : "#5d5d5d",
          //       color: CUSTOMCOLORS.white,
          //       padding: "2px 4px ",
          //       fontSize: 10,
          //       borderTopLeftRadius: 6,
          //       borderBottomRightRadius: 6,
          //       fontWeight: "600",
          //     }}
          //   >
          //     {token_id}
          //   </Typography>
          // </Stack>
        }
      </>
    );
  };
  const handleComplaint = async (item, sug = false) => {
    const check = complaint?.some((val) => val.coding[0].code === item?.code);
    if (check) {
      setSnomedValue("");
      return;
    }
    setComplaint((data) => [...data, { coding: [item], text: item?.display }]);
    setSnomedValue("");
    if (sug) {
      return;
    }
    if (suggs?.length > 0) {
      await updatetSuggestionsData(item);
    } else {
      await postSuggestionsData(item);
    }
  };
  const handleComplaintDelete = (index) => {
    setComplaint((data) => {
      const filterItems = data?.filter((_, ind) => ind !== index);
      return filterItems;
    });
  };
  const user_name = `${user_data[0]?.salutation + " " + user_data[0]?.name}`;
  const AppointmentBooking = async (e, link) => {
    e.preventDefault();
    const specialty = filterRole_data_slots[0]?.role?.specialty[0]?.coding?.map(
      (item) => ({
        system: item?.system,
        code: item?.code,
        display: item?.display,
      })
    );
    if (JSON.stringify(appType) === "{}" || complaint?.length < 1 || !slot) {
      return showToast("error", "please enter mandatory fields");
    }
    try {
      const response = await fetch(URL.createAppointment, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          appointment: {
            resourecType: "Appointment",
            status: "pending",
            specialty: [{ coding: specialty }],
            appointmentType: {
              coding: [appType],
              text: appType?.code,
            },
            reasonCode: complaint,
            priority: parseInt(tokenId),
            supportingInformation: [
              {
                reference: organization?.reference,
                type: "organization",
                display: organization?.display,
              },
            ],
            start: `${date}T${slot?.split("-")[0]}:00Z`,
            end: `${date}T${slot?.split("-")[1]}:00Z`,
            minutesDuration: getDuration(
              slot?.split("-")[0],
              slot?.split("-")[1]
            ),
            participant: [
              {
                actor: selectedDoctor,
                required: "required",
                status: "accepted",
              },
              {
                actor: {
                  reference: patient_data?._id,
                  type: "patient",
                  display: patient_data?.patient?.name?.[0]?.text,
                },
                required: "required",
                status: "accepted",
              },
            ],
          },
          type: "appointment",
          mode: mode,
          fees: isPaid,
          paymentMode: feeMode,
          slot: slot,
          consultation_fees: consultation_fees?.toString(),
        }),
      });
      const jsonData = await response.json();

      if (jsonData?.status === "success") {
        sendNotification(
          serverApiFCM,
          doctor_fcmTokens,
          `Appointment booked successfully for ${patient_data?.patient?.name?.[0]?.text}`,
          {
            user_ref: { reference: profile_id, display: user_data[0]?.name },
            doctor_ref: selectedDoctor,
            org_ref: organization,
            patient_ref: {
              reference: patient_data?._id,
              type: "patient",
              display: patient_data?.patient?.name?.[0]?.text,
            },
            appointment_ref: { reference: jsonData?.data?._id },
          },
          "/consultation",
          "doctor",
          user_name
        );
        showToast("success", "Sucessfully appointment booked");

        // if (value === "videoconference") {
        //   sendSlotDetailsToWtsApp(
        //     wts_id,
        //     wts_token,
        //     patient_data?.patient?.telecom[0]?.value,
        //     `Hi ${
        //       patient_data?.patient?.name?.[0]?.text
        //     }, Your TeleConsultation has been scheduled successfully.Time: *${slot}*,Date: *${dayjs(
        //       date
        //     ).format("DD-MM-YYYY")}*,Google Meet: ${
        //       // slotsList?.meetLink
        //       ""
        //     } Please join the Google Meet session at the scheduled time using the link above with ${
        //       selectedDoctor?.display
        //     }`
        //   );
        //   sendSlotDetailsToWtsApp(
        //     wts_id,
        //     wts_token,
        //     selectedDoctor?.doc_phone,
        //     `Hi *${handleDoctorname(
        //       selectedDoctor?.display
        //     )}*, Your TeleConsultation has been scheduled successfully.Time: *${slot}*, Date: *${dayjs(
        //       date
        //     ).format("DD-MM-YYYY")}*,Google Meet: ${
        //       // slotsList?.meetLink
        //       ""
        //     } Please join the Google Meet session at the scheduled time using the link above with *${
        //       patient_data?.patient?.name?.[0]?.text
        //     }*`
        //   );
        // }
        dispatch(addPatientData({}));
        navigate("/dashboard");
      } else {
        showToast("error", "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      showToast("error", err?.message);
    }
  };
  const RescheduleAppointment = async (e) => {
    e.preventDefault();
    const specialty = filterRole_data_slots[0]?.role?.specialty[0]?.coding?.map(
      (item) => ({
        system: item?.system,
        code: item?.code,
        display: item?.display,
      })
    );
    if (JSON.stringify(appType) === "{}" || complaint?.length === 0 || !slot) {
      return showToast("error", "please enter mandatory fields");
    }
    try {
      const response = await fetch(URL.updateAppointment(appointment_id), {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          appointment: {
            resourecType: "Appointment",
            status: "pending",
            specialty: [{ coding: specialty }],
            appointmentType: {
              coding: [appType],
              text: appType?.code,
            },
            reasonCode: complaint,
            priority: parseInt(tokenId),
            supportingInformation: [
              {
                reference: organization?.reference,
                type: "organization",
                display: organization?.display,
              },
            ],
            start: `${date}T${slot?.split("-")[0]}:00Z`,
            end: `${date}T${slot?.split("-")[1]}:00Z`,
            minutesDuration: getDuration(
              slot?.split("-")[0],
              slot?.split("-")[1]
            ),
            participant: [
              {
                actor: selectedDoctor,
                required: "required",
                status: "accepted",
              },
              {
                actor: {
                  reference: patient_data?._id,
                  type: "patient",
                  display: patient_data?.patient?.name?.[0]?.text,
                },
                required: "required",
                status: "accepted",
              },
            ],
          },
          type: "appointment",
          mode: mode,
          fees: isPaid,
          paymentMode: feeMode,
          slot: slot,
          consultation_fees: consultation_fees?.toString(),
        }),
      });

      const jsonData = await response.json();
      if (jsonData?.status === "success") {
        showToast("success", "Sucessfully rescheduled appointment");
        sendNotification(
          serverApiFCM,
          doctor_fcmTokens,
          `Appointment rescheduled successfully for ${patient_data?.patient?.name?.[0]?.text}`,
          {
            user_ref: { reference: profile_id, display: user_data[0]?.name },
            doctor_ref: selectedDoctor,
            org_ref: organization,
            patient_ref: {
              reference: patient_data?._id,
              type: "patient",
              display: patient_data?.patient?.name?.[0]?.text,
            },
            appointment_ref: { reference: jsonData?.data?._id },
            user_name,
          },
          "/consultation",
          "doctor",
          user_name
        );
        // if (value === "videoconference") {
        //   sendSlotDetailsToWtsApp(
        //     wts_id,
        //     wts_token,
        //     patient_data?.patient?.telecom[0]?.value,
        //     `Hi ${
        //       patient_data?.patient?.name?.[0]?.text
        //     }, Your TeleConsultation has been scheduled successfully.Time: *${slot}*,Date: *${dayjs(
        //       date
        //     ).format("DD-MM-YYYY")}*,Google Meet: ${
        //       // slotsList?.meetLink
        //       ""
        //     } Please join the Google Meet session at the scheduled time using the link above with ${
        //       selectedDoctor?.display
        //     }`
        //   );
        //   sendSlotDetailsToWtsApp(
        //     wts_id,
        //     wts_token,
        //     selectedDoctor?.doc_phone,
        //     `Hi *${handleDoctorname(
        //       selectedDoctor?.display
        //     )}*, Your TeleConsultation has been scheduled successfully.Time: *${slot}*,Date:  *${dayjs(
        //       date
        //     ).format("DD-MM-YYYY")}*,Google Meet: ${
        //       // slotsList?.meetLink
        //       ""
        //     } Please join the Google Meet session at the scheduled time using the link above with *${
        //       patient_data?.patient?.name?.[0]?.text
        //     }*`
        //   );
        // }
        dispatch(addPatientData({}));
        dispatch(addAppointmentData({}));
        dispatch(addAppointmentId(""));
        navigate("/dashboard");
      } else {
        showToast("error", "Something went wrong");
      }
    } catch (err) {
      showToast("error", err?.message);
    }
  };
  const handleDoctorSelect = async (item) => {
    dispatch(addSelectedDoctor(item));
    const fcmTokens = await GetFcmTokenDoc(item?.reference);
    const doc_tokens = fcmTokens
      ? fcmTokens?.map((item) => item?.fcmtoken)
      : [];
    dispatch(addDoctor_fcmTokens(doc_tokens));
  };
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
          position: "relative",
        }}
        onSubmit={
          JSON.stringify(appointment_data !== "{}") && appointment_id
            ? RescheduleAppointment
            : AppointmentBooking
        }
      >
        <Stack
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack>
            <Typography variant="h6">Book Appointment</Typography>

            <Typography
              variant={isSmallMobile ? "caption" : "subtitle1"}
              color={"primary.dark"}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              Patient name:
              <Typography
                color={"common.black"}
                variant={isSmallMobile ? "caption" : "subtitle1"}
              >
                {JSON.stringify(patient_data) !== "{}"
                  ? patient_data?.patient?.name[0]?.prefix?.join(",") +
                    " " +
                    patient_data?.patient?.name[0]?.text
                  : ""}
              </Typography>
            </Typography>
          </Stack>
          {/* {selectedDoctor?.payment_code && (
            <FileViewer
              label={"Pay Via QR"}
              base64String={selectedDoctor?.payment_code?.data}
              book={true}
            />
          )} */}
        </Stack>
        <Stack
          direction={"row"}
          gap={1}
          flexWrap={isDesktopOrLaptop || istab ? "no-wrap" : "wrap"}
        >
          <Stack width={isDesktopOrLaptop || istab ? "75%" : "100%"}>
            <Dropdown
              pr_name="display"
              options={doctors}
              value={selectedDoctor}
              setValue={handleDoctorSelect}
              doc={true}
            />
          </Stack>
          <Stack width={isDesktopOrLaptop || istab ? "25%" : "100%"}>
            <DatePick value={date} setValue={setDate} />
          </Stack>
        </Stack>
        <Stack gap={0.5}>
          {complaint?.length > 0 && (
            <Typography variant="subtitle1">Complaints</Typography>
          )}
          {complaint?.length > 0 && (
            <Stack direction={"row"} spacing={1}>
              {complaint?.map((item, index) => (
                <Chip
                  key={index}
                  label={item?.text}
                  variant="outlined"
                  onDelete={() => {
                    handleComplaintDelete(index);
                  }}
                />
              ))}
            </Stack>
          )}
          <SearchField
            value={snomedValue}
            setValue={setSnomedValue}
            data={snomeddata}
            label="Search complaints"
            placeholder="Search complaints"
            onClick={handleComplaint}
          />

          <Stack direction={"row"} spacing={0.5} mt={0.5} flexWrap={"wrap"}>
            {suggs && (
              <>
                {suggs?.length > 0 &&
                  suggs?.map((item, index) => (
                    <Chip
                      key={index}
                      label={item?.display}
                      onClick={() => {
                        handleComplaint(item, true);
                      }}
                    />
                  ))}
              </>
            )}
          </Stack>
        </Stack>
        <RadioButton
          label="Mode"
          data={["Offline", "Video conference"]}
          value={mode}
          setValue={setMode}
        />
        <RadioButton
          required={true}
          label="Appointment Type"
          data={AppointmentTypes}
          value={appType}
          pr_name="code"
          setValue={setAppType}
        />
        <RadioButton
          label="Fees"
          data={["Paid", "Unpaid"]}
          value={isPaid}
          setValue={setIsPaid}
        />
        {isPaid === "Paid" && (
          <RadioButton
            required={isPaid === "Paid" ? true : false}
            label="Fee Mode"
            data={["UPI", "Cash"]}
            value={feeMode}
            setValue={setFeeMode}
          />
        )}
        <Stack spacing={1}>
          <Typography variant="subtitle1">Available Slots</Typography>
          {roles_data?.length > 0 &&
          filterRole_data_slots?.length > 0 &&
          availableTimesToday?.length > 0 ? (
            <>
              {availableTimesToday?.map((slotsList, index) => (
                <Stack key={index} direction={"row"} flexWrap={"wrap"} gap={1}>
                  {getTimeList(
                    slotsList?.start,
                    slotsList?.end,
                    slotsList?.duration
                  )?.map((item, ind) => {
                    const start = item?.start;
                    const end = item?.end;
                    const token_id = (ind + 1)?.toString()?.padStart(2, "0");
                    const slot_time = `${start}-${end}`;
                    return (
                      <SlotBtn
                        key={ind}
                        token_id={token_id}
                        label={slot_time}
                        booked={slot === slot_time}
                      />
                    );
                  })}
                </Stack>
              ))}
            </>
          ) : (
            <Typography>No Slots Available</Typography>
          )}
          {/* {roles_data?.length > 0 &&
          filterRole_data_slots?.length > 0 &&
          slotsList &&
          getTimeList(slotsList?.start, slotsList?.end, slotsList?.duration)
            ?.length > 0 ? (
            <Stack direction={"row"} flexWrap={"wrap"} gap={1}>
              {getTimeList(
                slotsList?.start,
                slotsList?.end,
                slotsList?.duration
              )?.map((item, ind) => {
                const start = item?.start;
                const end = item?.end;
                const token_id = (ind + 1)?.toString()?.padStart(2, "0");
                const slot_time = `${start}-${end}`;
                return (
                  <SlotBtn
                    key={ind}
                    token_id={token_id}
                    label={slot_time}
                    booked={slot === slot_time}
                  />
                );
              })}
            </Stack>
          ) : (
            <Text>No Slots Available</Text>
          )} */}
        </Stack>
        {/* <Button variant="outlined" onClick={() => setModalOpen(true)}>
          Consent
        </Button> */}
        <Button
          size="large"
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "#4ba5fa",
            alignSelf: "center",
            mt: 2,
          }}
        >
          SAVE
        </Button>
      </form>
      {modalOpen && (
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Box sx={modalStyle}>
            <NewConsent handleFunction={() => setModalOpen(false)} />
          </Box>
        </Modal>
      )}
    </Box>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: isDesktopOrLaptop ? "auto" : "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: `90%`,
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  gap: 1,
  overflow: "scroll",
};
