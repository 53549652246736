import { Stack, Typography } from '@mui/material';
import React from 'react';

export const AllergyPrev = ({ data, color }) => {
  return (
    <Stack>
      <Typography
        variant='subtitle1'
        color={color ? color : 'common.black'}
        fontWeight={color ? '700' : '600'}
      >
        Allergies
      </Typography>
      <Stack
        display={'flex'}
        flexDirection={'row'}
        flexWrap={'wrap'}
      >
        {data?.map((item, index) => (
          <React.Fragment key={index}>
            <Typography
              fontWeight={600}
              fontSize={12}
              ml={0.5}
            >
              {item?.AllergyTolerance?.code?.text}
              {index < data.length - 1 && ', '}
            </Typography>
          </React.Fragment>
        ))}
      </Stack>
    </Stack>
  );
};
