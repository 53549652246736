import axios from "axios";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack, TextField } from "@mui/material";
import { CUSTOMCOLORS } from "../styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import { URL, systems } from "../utility/urls";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToast } from "../utility/toast";
import { addPatientData } from "../redux/features/patientData";
import { LoadingButton } from "@mui/lab";
import { addAppointmentData } from "../redux/features/appointmentData";
// import { RoleCheck } from "../utils/schema";
import { ImagePicker } from "../components/imgPicker";
import { Dropdown } from "../components/Dropdown";
import { AutoCompleteComponent } from "../components/autocomplete";
import { RadioButton } from "../components/RadioBtn";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CONSTANTS, isDesktopOrLaptop, istab } from "../utility/const";

const AbhaProfile = () => {
  const location = useLocation();
  const statedata = location?.state;
  const [card, setCard] = useState("");
  const [data, setData] = useState({});

  const nav = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.login);
  const { organization, selectedDoctor } = useSelector((state) => state?.user);
  const { organization_data } = useSelector((state) => state?.organization);
  const [clientData, setClientData] = useState([]);
  const [schoolData, setschoolData] = useState([]);
  const number = String(Math.floor(Math.random() * 900000) + 100000);

  const [selectImage, setSelectImage] = useState({
    data: "",
    type: "",
    name: "",
  });
  const [salut, setSalutation] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [refid, setRefId] = useState(number);
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [blood, setBlood] = useState("");
  const [spouseGrp, setspouseGrp] = useState("");
  const [relation, setRelation] = useState("");
  const [relName, setRelName] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setpincode] = useState("");
  const [aadhaar, setAadhaar] = useState("");
  const [find, setfind] = useState("");
  const [dob, setDob] = useState("");
  const [Client, setClient] = useState("");
  const [School, setSchool] = useState("");
  const [Class, setClass] = useState("");
  const [Section, setSection] = useState("");
  const [spouse_age, setSpouse_Age] = useState("");
  const findus = [
    "Newspaper",
    "TV",
    "Face Book",
    "Instagram",
    "Social Media",
    "Friends",
    "Doctors",
    "Family",
    "old Patient",
  ];
  const [generalPrac, setgeneralPrac] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleNumericInput = (input) => {
    const numericValue = input.replace(/[^0-9]/g, "");
    setPhone(numericValue);
  };

  const HandleBlood = (val) => {
    setBlood(val);
  };
  const HandleSpouseGroup = (val) => {
    setspouseGrp(val);
  };
  const HandleFindUs = (val) => {
    setfind(val);
  };

  const abhaCard = async () => {
    try {
      const response = await axios.get(URL.getAbhaAccount, {
        headers: {
          Authorization: statedata?.token,
          "X-token": `Bearer ${
            statedata?.key === "verify"
              ? statedata?.data
              : statedata?.data?.tokens?.token
          }`,
        },
      });
      setData(response.data.data);
      const cardresponse = await axios.get(URL.getAbhaCrad, {
        headers: {
          Authorization: statedata?.token,
          "X-token": `Bearer ${
            statedata?.key === "verify"
              ? statedata?.data
              : statedata?.data?.tokens?.token
          }`,
        },
      });
      setCard(cardresponse.data?.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    abhaCard();
  }, []);
  useEffect(() => {
    setSelectImage({
      type: "image/png",
      data: `data:image/png;base64,${data?.kycPhoto}`,
      name: "",
    });
    setName(data?.name);
    setPhone(data?.mobile);
    let date = new Date().getFullYear();
    const current = date - parseInt(data?.yearOfBirth);
    setAge(current);
    setDob(`${data?.yearOfBirth}-${data?.monthOfBirth}-${data?.dayOfBirth}`);
    setGender(
      data?.gender === "M" ? "Male" : data?.gender === "F" ? "Female" : "Others"
    );
    setAddress(data?.address);
    setpincode(data?.pincode);
  }, [data]);

  const patientChk = generalPrac?.some(
    (item) => item?.reference === organization?.reference
  );
  console.log(patientChk);

  const handlenewPractitioner = () => {
    if (patientChk) {
      return generalPrac;
    } else {
      return [
        ...generalPrac,
        {
          reference: organization?.reference,
          display: organization?.display,
        },
      ];
    }
  };
  const [modalOpen, setModalOpen] = useState(false);
  console.log(modalOpen);

  const Addpatient = async (e) => {
    console.log("abiiiiiii");

    e.preventDefault();
    if (patientChk) {
      return setModalOpen(true);
    }
    setLoading(true);
    try {
      const response = await fetch(URL.createPatient, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          patient: {
            patient: {
              resourceType: "Patient",
              identifier: [
                {
                  type: {
                    coding: [
                      {
                        system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                        code: "MR",
                        display: "Medical record number",
                      },
                    ],
                    text: "ABHAID",
                  },
                  system: "https://healthid.ndhm.gov.in",
                  value: data?.ABHANumber,
                },
                {
                  type: {
                    coding: [
                      {
                        system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                        code: "MR",
                        display: "Medical record number",
                      },
                    ],
                    text: "ABHAADDRESS",
                  },
                  system: "https://healthid.ndhm.gov.in",
                  value: data?.preferredAbhaAddress,
                },
              ],
              active: true,
              name: [
                {
                  text: name,
                  prefix: [salut],
                },
              ],
              telecom: [
                {
                  system: "phone",
                  value: phone,
                },
              ],
              gender: gender,
              birthDate: dob,
              address: [
                {
                  use: "Home",
                  code: "Postal",
                  city: address,
                  postalCode: pincode,
                },
              ],
              photo: {
                contentType: selectImage?.type,
                data: selectImage?.data,
              },
              contact: [
                {
                  relationship: [
                    {
                      coding: [
                        {
                          system: systems.snomed,
                          code:
                            relation === "Father"
                              ? "66839005"
                              : "Husband"
                              ? "127849001"
                              : "",
                          display:
                            relation === "Father"
                              ? "Father"
                              : "Husband"
                              ? "Husband"
                              : "",
                        },
                      ],
                    },
                  ],
                  name: {
                    use: "Official",
                    text: relName,
                  },
                },
              ],
              generalPractitioner: handlenewPractitioner(),
              managingOrganization: {
                reference: organization?.reference,
                display: organization?.display,
              },
            },
            reference_ids: [
              { reference_id: refid, organization_id: organization?.reference },
            ],
            blood_group: blood,
            spouse_blood: spouseGrp,
            spouse_age,
            find_us: [find],
          },
          patientMetadata: {
            client_type: Client,
            class_name: Class,
            section: Section,
            school: School,
          },
        }),
      });
      const jsonData = await response.json();
      dispatch(addAppointmentData({}));
      if (jsonData?.status === "success") {
        dispatch(addPatientData(jsonData?.data));
        nav("/addPatient");
        // setPatUp((prev: any) => (prev += "ss"));

        showToast("success", "Successfully created");
      } else {
        showToast("error", jsonData?.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchPatientInit = async () => {
    try {
      const response = await fetch(
        URL?.getPatientInit(data?.ABHANumber, dob, name),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const jsonData = await response?.json();
      setgeneralPrac(jsonData?.data?.patient?.generalPractitioner || []);
    } catch (error) {
      showToast("error", error?.message);
    }
  };
  useEffect(() => {
    if (name && dob && data?.ABHANumber) {
      fetchPatientInit();
    }
  }, [name, dob, data?.ABHANumber, data]);
  return (
    <Stack
      display={"flex"}
      flexDirection={isDesktopOrLaptop ? "row" : "column"}
      gap={isDesktopOrLaptop ? 4 : 2}
    >
      <Stack width={isDesktopOrLaptop ? "40%" : "100%"} height={"100%"}>
        <Stack
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          p={1}
        >
          <Typography variant="h6">ABHA Card</Typography>
          <Typography component={"a"} href={card} download={"Abha.png"}>
            <FileDownloadIcon
              sx={{ color: "primary.dark" }}
              fontSize="medium"
            />
          </Typography>
        </Stack>
        <img
          src={card}
          style={{
            width: isDesktopOrLaptop ? "50vw" : "100%",
            height: isDesktopOrLaptop ? "80vh" : "100%",
            objectFit: "cover",
          }}
        />
      </Stack>
      <Stack
        component={"form"}
        width={isDesktopOrLaptop ? "60%" : "100%"}
        height={"100%"}
        onSubmit={Addpatient}
        boxShadow={"0px 0px 4px 0px #C0DFFC"}
        px={2}
        py={1}
        borderRadius={1}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          fontWeight={600}
          fontFamily={"Open sans"}
        >
          Add Patient
        </Typography>
        <Stack gap={1}>
          <Box display={"flex"}>
            <ImagePicker data={selectImage?.data} />
          </Box>
          {selectedDoctor?.role !== "doctor" && (
            <Box sx={{ minWidth: 120 }}>
              <Dropdown
                label={"Client"}
                value={Client}
                setValue={setClient}
                options={clientData}
              />
            </Box>
          )}
          <Box display={"flex"} gap={isDesktopOrLaptop || istab ? 1 : 2}>
            <Stack width={"25%"}>
              <Dropdown
                // size="small"
                label="Salutations"
                value={salut}
                setValue={setSalutation}
                options={CONSTANTS.saluatation}
              />
            </Stack>

            <Stack width={"75%"}>
              <TextField
                disabled
                required={true}
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputLabelProps={{
                  style: {
                    fontSize: 14,
                    fontWeight: 500,
                    fontFamily: "Open sans",
                  },
                }}
              />
            </Stack>
          </Box>
          <TextField
            disabled
            required={true}
            type="text"
            label="Phone Number"
            value={phone}
            onChange={(e) => handleNumericInput(e.target.value)}
            InputLabelProps={{
              style: {
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Open sans",
              },
            }}
            inputProps={{ maxLength: 10 }}
          />
          <TextField
            disabled
            type="number"
            required={true}
            label="Age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            InputLabelProps={{
              style: {
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Open sans",
              },
            }}
          />
          <RadioButton
            disabled={true}
            variant={"subtitle1"}
            label="Gender"
            data={["Male", "Female", "Other"]}
            value={gender}
            setValue={setGender}
          />
          {selectedDoctor?.role === "doctor" && (
            <>
              <Typography variant={"subtitle1"}>Blood Group</Typography>
              <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                {CONSTANTS?.blood_Groups?.map((item, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    sx={{
                      minWidth: "auto",
                      backgroundColor:
                        blood === item
                          ? CUSTOMCOLORS.selection
                          : CUSTOMCOLORS.white,
                      color:
                        blood === item
                          ? CUSTOMCOLORS.white
                          : CUSTOMCOLORS.primary,
                      "&:hover": {
                        backgroundColor: CUSTOMCOLORS.selection,
                        color: CUSTOMCOLORS.white,
                      },
                    }}
                    onClick={() => HandleBlood(item)}
                  >
                    {item}
                  </Button>
                ))}
              </Stack>
              <RadioButton
                variant={"subtitle1"}
                label="Relation Name"
                data={["Father", "Husband"]}
                value={relation}
                setValue={setRelation}
              />
              {relation !== "" && (
                <>
                  <OutlinedInput
                    placeholder={
                      relation === "Father"
                        ? "Enter father name"
                        : "Enter husband name"
                    }
                    value={relName}
                    onChange={(e) => setRelName(e.target.value)}
                  />
                  <TextField
                    label="Age"
                    value={spouse_age}
                    onChange={(e) => setSpouse_Age(e.target.value)}
                    InputLabelProps={{
                      style: {
                        fontSize: 14,
                        fontWeight: 500,
                        fontFamily: "Open sans",
                      },
                    }}
                  />
                  <Typography variant={"subtitle1"}>
                    Spouse Blood Group
                  </Typography>
                  <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
                    {CONSTANTS?.blood_Groups?.map((item, index) => (
                      <Button
                        key={index}
                        variant="outlined"
                        sx={{
                          minWidth: "auto",
                          backgroundColor:
                            spouseGrp === item
                              ? CUSTOMCOLORS.selection
                              : CUSTOMCOLORS.white,
                          color:
                            spouseGrp === item
                              ? CUSTOMCOLORS.white
                              : CUSTOMCOLORS.primary,
                          "&:hover": {
                            backgroundColor: CUSTOMCOLORS.selection,
                            color: CUSTOMCOLORS.white,
                          },
                        }}
                        onClick={() => HandleSpouseGroup(item)}
                      >
                        {item}
                      </Button>
                    ))}
                  </Stack>
                </>
              )}
            </>
          )}

          {selectedDoctor?.role !== "doctor" && (
            <>
              <TextField
                label="Class"
                value={Class}
                onChange={(e) => setClass(e.target.value)}
                InputLabelProps={{
                  style: {
                    fontSize: 14,
                    fontWeight: 500,
                    fontFamily: "Open sans",
                  },
                }}
              />
              <TextField
                label="Section"
                value={Section}
                onChange={(e) => setSection(e.target.value)}
                InputLabelProps={{
                  style: {
                    fontSize: 14,
                    fontWeight: 500,
                    fontFamily: "Open sans",
                  },
                }}
              />
              <Dropdown
                label={"School"}
                value={School}
                setValue={setSchool}
                options={schoolData}
              />
            </>
          )}

          <TextField
            disabled
            required={true}
            label="Address / Locality"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            InputLabelProps={{
              style: {
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Open sans",
              },
            }}
          />
          <TextField
            disabled
            label="Pin code"
            value={pincode}
            onChange={(e) => setpincode(e.target.value)}
            InputLabelProps={{
              style: {
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Open sans",
              },
            }}
          />
          <TextField
            label="Aadhaar Number"
            value={aadhaar}
            onChange={(e) => setAadhaar(e.target.value)}
            InputLabelProps={{
              style: {
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Open sans",
              },
            }}
          />

          <Typography variant={"subtitle1"}>How did you find us?</Typography>
          <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"}>
            {findus?.map((item, index) => (
              <Button
                key={index}
                variant="outlined"
                sx={{
                  minWidth: "auto",
                  backgroundColor:
                    find === item ? CUSTOMCOLORS.selection : CUSTOMCOLORS.white,
                  color:
                    find === item ? CUSTOMCOLORS.white : CUSTOMCOLORS.primary,
                  "&:hover": {
                    backgroundColor: CUSTOMCOLORS.selection,
                    color: CUSTOMCOLORS.white,
                  },
                }}
                onClick={() => HandleFindUs(item)}
              >
                {item}
              </Button>
            ))}
          </Stack>

          <Stack alignSelf={"center"} mt={4}>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ backgroundColor: CUSTOMCOLORS.primary }}
              loading={loading}
              size="large"
            >
              SAVE
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
      {modalOpen && (
        <SerachModal open={modalOpen} onClose={setModalOpen} name={name} />
      )}
    </Stack>
  );
};

export default AbhaProfile;
const SerachModal = ({ open, onClose, name }) => {
  const nav = useNavigate();
  const handleClose = () => {
    onClose(!open);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        Patient Already Exists!
        <Button
          onClick={() => {
            nav("/addPatient", { state: name });
            handleClose();
          }}
        >
          Click Here
        </Button>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: isDesktopOrLaptop ? "40%" : "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: isDesktopOrLaptop ? 4 : 2,
  height: "auto",
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  gap: 2,
  overflowY: "auto",
};
